import classNames from 'classnames'
import {
  CharacterMetadata,
  CompositeDecorator,
  ContentBlock,
  ContentState,
  convertToRaw,
  Editor,
  EditorState,
  genKey,
  SelectionState,
} from 'draft-js'
import 'draft-js/dist/Draft.css'
import { List, OrderedMap, Repeat } from 'immutable'
import React, { forwardRef, useEffect, useImperativeHandle, useRef } from 'react'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'

import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import { useLyrics } from '../../../../context/LyricsContext'
import useSizes from '../../../../hooks/useSizes'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './LinesInput.module.scss'

type Props = {
  onChange: (a: string) => void
  initialValue: string
  placeholder?: string
}

const STRINGS = {
  placeholder: 'Type in lyrics',
}

const ContentEditable = forwardRef(({ onChange, initialValue, placeholder }: Props, ref: any) => {
  const editorRef = useRef<any>()
  const { currentWord, setCurrentWord, setCurrentWordPosition, setIsFooterHidden } = useGeneratorModals()
  const { playerConfig } = usePlayerConfigState()
  const { suggestionsData, assistantOpen, mode } = useLyrics()
  const appendText = (text: string, offset?: number) => {
    const contentState = editorState.getCurrentContent()
    // Create a new block with the specified text
    const currBlock = contentState.getBlockMap().get(editorState.getSelection().getAnchorKey())
    const newBlock = new ContentBlock({
      key: currBlock?.getKey(),
      type: 'unstyled',
      text: text,
      characterList: List(Repeat(CharacterMetadata.create(), text.length)),
    })
    let newLineBlock = null
    const newBlockMap = OrderedMap().withMutations((map) => {
      // @ts-ignore
      for (const [k, v] of contentState.getBlockMap().set(newBlock.getKey(), newBlock).entries()) {
        map.set(k, v)

        if (newBlock.getKey() === k && !currBlock?.getText()) {
          newLineBlock = new ContentBlock({
            key: genKey(),
            type: 'unstyled',
            text: '',
            characterList: List(),
          })
          map.set(newLineBlock.getKey(), newLineBlock)
        }
      }
    })
    // Update the editor state with the new content
    const newEditorState = EditorState.push(
      editorState,
      // @ts-ignore
      ContentState.createFromBlockArray(Array.from(newBlockMap.values())),
      'insert-fragment',
    )
    const newSelection = new SelectionState({
      anchorKey: (newLineBlock || newBlock).getKey(),
      anchorOffset: offset !== undefined ? offset : (newLineBlock || newBlock).getLength(),
      focusKey: (newLineBlock || newBlock).getKey(),
      focusOffset: offset !== undefined ? offset : (newLineBlock || newBlock).getLength(),
    })
    setEditorState(EditorState.forceSelection(newEditorState, newSelection))
  }
  useImperativeHandle(ref, () => ({
    appendText,
  }))
  useEffect(() => {
    setTimeout(() => {
      const newEditorState = EditorState.moveSelectionToEnd(editorState)
      setEditorState(newEditorState)
    }, 200)
  }, [])
  useEffect(() => {
    const newLyrics = playerConfig.lyrics.lines

    if (editorState.getSelection().getHasFocus()) return

    if (!newLyrics.length) {
      setEditorState(EditorState.createEmpty())
      return
    }

    const newLyricsState = newLyrics.join('\n')
    if (newLyricsState !== editorStateToValue(editorState)) {
      const newState = EditorState.createWithContent(ContentState.createFromText(newLyricsState), getDecorator())

      setEditorState(newState)
    }
  }, [playerConfig.lyrics])

  const getDecorator = () => new CompositeDecorator([])

  function blockStyleFn(block: ContentBlock) {
    const index = Array.from(
      // @ts-ignore
      editorState.getCurrentContent().getBlockMap().values(),
    ).indexOf(block)
    const line = playerConfig.lyrics.lines[index]
    if (block.getType() !== 'unstyled') {
      return ''
    }
    const isActive = editorState.getSelection().getAnchorKey() === block.getKey()
    if (isActive && suggestionsData && assistantOpen && mode === 'ai-lines') {
      return classNames(styles.activeBlock, {
        [styles.withContent]: line?.length > 0,
      })
    }
    return ''
  }

  const [editorState, setEditorStateRaw] = React.useState(() =>
    EditorState.createWithContent(ContentState.createFromText(initialValue), getDecorator()),
  )
  const editorStateToValue = (state: EditorState) => {
    const blocks = convertToRaw(state.getCurrentContent()).blocks
    const value = blocks.map((block) => block.text).join('\n')
    return value
  }
  const updateCurrentWord = () => {
    const { word, wordPosition } = getCurrentWord()

    if (!word || word?.length < 3) {
      if (currentWord.length) setCurrentWord('')
      return
    }

    const selectionState = editorState.getSelection()

    setCurrentWord(word.replaceAll(/[^\w]/gm, ''))
    setCurrentWordPosition({
      line: editorState
        .getCurrentContent()
        .getBlocksAsArray()
        .map((block) => block.getKey())
        .findIndex((key) => key === selectionState.getStartKey()),
      offset: wordPosition,
    })
  }
  const setEditorState = (state: EditorState) => {
    setEditorStateRaw(state)
    onChange(editorStateToValue(state))
  }
  useEffect(() => {
    updateCurrentWord()
  }, [editorState])
  const isKeyboardOpen = useDetectKeyboardOpen()
  const { isMobile } = useSizes()

  useEffect(() => {
    // if (isMobile && isKeyboardOpen) {
    //   window.scrollTo(0, document.body.scrollHeight)
    //   setTimeout(() => {
    //     window.scrollTo(0, document.body.scrollHeight)
    //   }, 50)
    // }
    // @ts-ignore
    if (isMobile && !isKeyboardOpen && document.activeElement?.blur) {
      // @ts-ignore
      document.activeElement?.blur()
    }
  }, [isKeyboardOpen])
  return (
    <Editor
      ref={editorRef}
      onFocus={() => setIsFooterHidden(true)}
      onBlur={() => {
        setTimeout(() => setIsFooterHidden(false), 400)
      }}
      blockStyleFn={blockStyleFn}
      editorState={editorState}
      onChange={setEditorState}
      placeholder={placeholder || STRINGS.placeholder}
      stripPastedStyles
      handleBeforeInput={function handleBeforeInput(chars) {
        const { word } = getCurrentWord()
        if ((word?.length || 0) + chars.length > 25 && !chars.includes(' ')) {
          return 'handled'
        }
        return 'not-handled'
      }}
    />
  )

  function getCurrentWord() {
    const selectionState = editorState.getSelection()
    const contentState = editorState.getCurrentContent()
    const block = contentState.getBlockForKey(selectionState.getStartKey())
    const text = block.getText()
    const end = selectionState.getEndOffset()
    const endOfWord = text
      .substring(end)
      .split('')
      .findIndex((char) => char === ' ')
    const finalEndOfWord = endOfWord === -1 ? undefined : end + endOfWord
    return {
      word: text.substring(0, finalEndOfWord).split(' ').pop(),
      wordPosition: finalEndOfWord || text.length,
    }
  }
})

export default ContentEditable
