import classNames from 'classnames'
import React, { ReactNode, useEffect, useRef, useState } from 'react'
import { Transition } from 'react-transition-group'

import { getRhymesInnerRoute } from '../../../api/lyrics'
import CloseIcon from '../../../assets/icons/close.svg'
import CornerDownRight from '../../../assets/icons/corner-down-right.svg'
import SavedIcon from '../../../assets/icons/file-saved.svg'
// TODO: uncomment once lyrics AI generation will be needed again
// import GenerateLyricsInnerIcon from '../../../assets/icons/generate-lyrics-stars-inner.svg'
// import GenerateLyricsIcon from '../../../assets/icons/generate-lyrics.svg'
import LoadingIcon from '../../../assets/icons/loading.svg'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import { useProjectState } from '../../../context/ProjectStateContext'
import useSizes from '../../../hooks/useSizes'
import IconButton from '../../common/IconButton'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import AssistantWrapper from './AssistantWrapper/AssistantWrapper'
import LinesInput from './LinesInput/LinesInput'
import styles from './LyricsEditor.module.scss'
import AILyricsContent from './LyricsModals/AILyricsModal/AILyricsContent'
import ClearLyricsContent from './LyricsModals/ClearLyricsModal/ClearLyricsContent'

type Props = object

const LyricsEditor: React.FC<Props> = () => {
  const projectStateConfig = useProjectState()
  const { text } = useInternationalization()
  const {
    isClearLyricsOpen,
    isAILyricsOpen,
    currentWord,
    currentWordPosition,
    isFooterHidden,
    setIsClearLyricsOpen,
    setIsAILyricsOpen,
    setCurrentWordPosition,
    setCurrentWord,
  } = useGeneratorModals()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { isMobile } = useSizes()
  const editorRef = useRef<{ appendText: (a: string, offset?: number) => void }>({
    appendText: () => {},
  })

  const isLyricsEmpty = !playerConfig.lyrics.title && !playerConfig.lyrics.lines?.length
  const isLyricsSaved = projectStateConfig.draftSaved || projectStateConfig.projectSaved || isLyricsEmpty

  const isAssistantOpen = isClearLyricsOpen || isAILyricsOpen
  const STRINGS = text.sidebars.lyrics

  const [suggestions, setSuggestions] = useState<string[]>([])
  const [suggestionsLoading, setSuggestionsLoading] = useState(false)

  const [lyricsAssistantHeight, setLyricsAssistantHeight] = useState(740)

  useEffect(() => {
    if (!currentWord.length) {
      setSuggestions([])
      return
    }
    ;(async () => {
      setSuggestionsLoading(true)
      const data = await getRhymesInnerRoute(currentWord)
      setSuggestions(data)
      setSuggestionsLoading(false)
    })()
  }, [currentWord, isFooterHidden])

  useEffect(() => {
    setLyricsAssistantHeight(window.innerHeight - 70 - 70)
  }, [])

  const renderHeader = () => {
    const handleClearAll = () => {
      if (!isLyricsEmpty) setIsClearLyricsOpen(true)
    }

    const saveClasses = classNames(styles.btn, styles.save, { [styles.active]: !isLyricsSaved })
    const clearClasses = classNames(styles.btn, styles.clear, { [styles.active]: !isLyricsEmpty })

    if (isMobile) {
      return (
        <div className={styles.header}>
          <div className={saveClasses}>
            {isLyricsSaved ? (
              <>
                <SavedIcon />
                {STRINGS.header.saved}
              </>
            ) : (
              <>
                <LoadingIcon />
                {STRINGS.header.saving}
              </>
            )}
          </div>
          <div className={clearClasses} onClick={handleClearAll}>
            <CloseIcon />
            {STRINGS.header.clear}
          </div>
        </div>
      )
    }

    return (
      <div className={styles.header}>
        <IconButton
          icon='close'
          onClick={() => {
            if (!isAssistantOpen) playerConfigSetter.setView()
          }}
          className={styles.closeIcon}
        />
        <div className={saveClasses}>{isLyricsSaved ? STRINGS.header.saved : STRINGS.header.saving}</div>
        <div className={clearClasses} onClick={handleClearAll}>
          {STRINGS.header.clear}
        </div>
      </div>
    )
  }

  const renderGenerateButton = (text: string, className: string, onClick: any, children?: ReactNode) => {
    return (
      <div className={className}>
        {/* TODO: uncomment once lyrics AI generation will be needed again */}
        {/* <div className={styles.btn} onClick={onClick}>
          <GenerateLyricsInnerIcon className={styles.stars} />
          {<GenerateLyricsIcon />}
          {text}
        </div> */}
        {children}
      </div>
    )
  }

  const handleUseWord = (suggestion: string) => {
    if (!currentWordPosition) return
    const { line, offset } = currentWordPosition

    const lineToEdit = JSON.parse(JSON.stringify(playerConfig.lyrics)).lines[line] as string

    const newLine = lineToEdit.slice(0, offset) + ' ' + suggestion + lineToEdit.slice(offset)
    const newPosition = offset + suggestion.length + 1

    editorRef.current.appendText(newLine, newPosition)

    setCurrentWord(suggestion)
    setCurrentWordPosition({ line, offset: newPosition })
  }

  const renderWordsRhymes = () => {
    const renderRhymes = () =>
      suggestions.map((suggestion, index) => (
        <div
          key={`suggestion_${index}`}
          className={styles.suggestion}
          onClick={() => handleUseWord(suggestion)}
        >
          {suggestion}
        </div>
      ))

    const renderRhymesSkeletons = () => [1, 1, 1, 1].map((_, index) => <div className={styles.skeleton} key={index} />)

    const renderDesktopMessage = () => (
      <div className={styles.noWordContent}>
        <CornerDownRight />
        <div>{STRINGS.content.rhymesMessage}</div>
      </div>
    )

    return (!currentWord || !isFooterHidden) && !isMobile
      ? renderDesktopMessage()
      : suggestionsLoading
      ? renderRhymesSkeletons()
      : renderRhymes()
  }

  const renderBody = () => {
    return (
      <>
        {renderHeader()}

        <LinesInput editorRef={editorRef} placeholder={STRINGS.content.placeholder} />

        {isLyricsEmpty && !isFooterHidden
          ? renderGenerateButton(STRINGS.content.generateBtn, styles.generateLyrics, () => setIsAILyricsOpen(true))
          : renderGenerateButton(
              isMobile ? STRINGS.content.generateBtnMobile : STRINGS.content.generateBtn,
              classNames(styles.suggestions, { [styles.noWord]: (!currentWord || !isFooterHidden) && !isMobile }),
              () => {
                setIsAILyricsOpen(true)
              },
              renderWordsRhymes(),
            )}
      </>
    )
  }

  const renderAssistants = () => {
    if (isMobile) return null
    return (
      <>
        {
          <AssistantWrapper
            className={styles.assistant}
            openHeightInitial={350}
            assistantOpen={isClearLyricsOpen}
            setAssistantOpen={setIsClearLyricsOpen}
          >
            <ClearLyricsContent onClose={() => setIsClearLyricsOpen(false)} />
          </AssistantWrapper>
        }
        {
          <AssistantWrapper
            className={styles.assistant}
            openHeightInitial={lyricsAssistantHeight}
            assistantOpen={isAILyricsOpen}
            setAssistantOpen={setIsAILyricsOpen}
          >
            <AILyricsContent isOpen={isAILyricsOpen} onClose={() => setIsAILyricsOpen(false)} />
          </AssistantWrapper>
        }
      </>
    )
  }

  if (isMobile) {
    return renderBody()
  }

  return (
    <Transition in={playerConfig.view.lyricsOpen} timeout={200} unmountOnExit>
      {(status) => (
        <div className={styles.container} data-status={status} onKeyDown={(e) => e.stopPropagation()}>
          <div
            className={classNames(styles.content, {
              [styles.assistantOpen]: isAssistantOpen,
            })}
            onClick={() => {
              if (isAssistantOpen) {
                setIsClearLyricsOpen(false)
                setIsAILyricsOpen(false)
              }
            }}
          >
            {renderBody()}
          </div>
          {renderAssistants()}
        </div>
      )}
    </Transition>
  )
}

export default LyricsEditor
