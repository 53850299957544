import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import LoaderIcon from '../../../../assets/icons/loader.svg'
import PlusIcon from '../../../../assets/icons/plus.svg'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import { FRONTEND_LIMIT_TYPES, useLimits } from '../../../../context/LimitsContext'
import { ADDING_TILE_ID } from '../../../../utils/types'
import Tooltip from '../../../common/Tooltip/Tooltip'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './TimelineTile.module.scss'

type Props = {
  getWidth: () => number
  hoverChords: boolean
  generateMode?: boolean
  setHoverChords: (b: boolean) => void
  active?: boolean
  componentRef: any
  onAddHook?: (e: React.MouseEvent) => Promise<void>
  isChords?: boolean
}

const TimelineAdder = ({
  componentRef,
  hoverChords,
  generateMode,
  setHoverChords,
  getWidth,
  onAddHook,
  active,
  isChords = false,
}: Props) => {
  const { text } = useInternationalization()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { isChordsLimited, renderLimitIcon, triggerLimitCallback } = useLimits()

  const [loading, setLoading] = useState(false)

  const animateLayoutChanges = (args: any) => {
    const { isSorting, wasSorting } = args

    if (isSorting || wasSorting) return defaultAnimateLayoutChanges(args)
    return true
  }
  const { setNodeRef, transform, transition } = useSortable({
    id: ADDING_TILE_ID,
    animateLayoutChanges,
  })

  useEffect(() => {
    if (generateMode) return
    setHoverChords(false)
  }, [generateMode])
  useEffect(() => {
    return () => setHoverChords(false)
  }, [])

  const isLimited = isChords && isChordsLimited(playerConfig.currentPart.chords)
  const tooltipId = 'timeline-adder'
  const buttonStyle = {
    maxWidth: getWidth() - 30,
  }

  return (
    <>
      <div
        className={classNames(styles.tile, styles.addTile, {
          [styles.selected]: active,
          [styles.greenBorder]: hoverChords,
        })}
        data-tooltip-id={isChords ? tooltipId : undefined}
        ref={(node) => {
          setNodeRef(node)
          componentRef.current = node
        }}
        style={{ transform: CSS.Transform.toString(transform), transition, width: getWidth() }}
        onClick={async (e) => {
          triggerLimitCallback(isLimited, FRONTEND_LIMIT_TYPES.chords)

          if (loading || isLimited) return

          setLoading(true)
          playerConfigSetter.setActiveChordIds([])
          onAddHook && (await onAddHook(e as React.MouseEvent))
          setLoading(false)
        }}
      >
        <div className={styles.bg}>
          <div style={buttonStyle}>
            {generateMode && loading ? (
              <LoaderIcon className={styles.loader} />
            ) : (
              <PlusIcon className={styles.addIcon} />
            )}
            {renderLimitIcon(isLimited)}
          </div>
        </div>
      </div>

      {isChords && <Tooltip id={tooltipId} text={text.timeline?.addChordTooltip} />}
    </>
  )
}

export default TimelineAdder
