import classNames from 'classnames'
import React, { FC } from 'react'
import { useQuery } from 'react-query'

import { getUserPrefsInnerRoute } from '../../../../api/user-prefs'
import { useHistory } from '../../../../context/HistoryContext'
import useSizes from '../../../../hooks/useSizes'
import CircleButton from '../../../common/CircleButton'
import IconButton from '../../../common/IconButton'
import IconMenuButton from '../../../common/IconMenuButton'
import ChordKeyMenu from '../../NewChordEditor/components/ChordKeyMenu'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import BpmSelect from './BpmSelect'
import MobileSettingsMenu from './MobileSettingsMenu/MobileSettingsMenu'
import styles from './PlayControls.module.scss'

type Props = {
  onPlay: () => void
  onReset: () => void
  onRepeatChange?: () => void
  setTonalityKeyScale: (key: string, scale: string) => void
  setInstrumentSelectOpen: (a: boolean) => void
  instrumentSelectOpen: boolean
}

type MainPlayControlsProps = {
  onPlay: () => void
  onReset: () => void
  onRepeatChange?: () => void
  loading?: boolean
}

export const MainPlayControls: FC<MainPlayControlsProps> = ({ onReset, onPlay, onRepeatChange, loading }) => {
  const { playerConfig } = usePlayerConfigState()

  return (
    <div className={styles.mainControls}>
      <CircleButton className={styles.backButton} size='medium' onClick={onReset} icon='step-backward' />
      <CircleButton
        className={styles.playButton}
        size='large'
        icon={playerConfig.isPlaying || playerConfig.isPlayingBeforeManualScroll ? 'pause' : 'play'}
        onClick={onPlay}
        loading={loading}
      />
      <CircleButton
        className={`${styles.repeatButton} ${playerConfig.isOnRepeat !== 'none' ? styles.active : ''}`}
        size='medium'
        onClick={onRepeatChange}
        icon={playerConfig.isPartLooped ? 'infinite-repeat-bold' : 'repeat'}
      />
    </div>
  )
}

const PlayControls: React.FC<Props> = ({
  instrumentSelectOpen,
  setInstrumentSelectOpen,
  onPlay,
  onReset,
  setTonalityKeyScale,
  onRepeatChange,
}) => {
  const { isUndoAvailable, isRedoAvailable, undo, redo } = useHistory()
  const { isMobile, isTablet } = useSizes()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { data: prefs } = useQuery(['user-prefs'], getUserPrefsInnerRoute)
  const instrument = playerConfig.view.guitarOpen
    ? 'guitar'
    : playerConfig.view.pianoOpen
    ? 'piano'
    : playerConfig.view.tempFavInstrument || prefs?.favouriteInstrument || 'piano'
  const renderInstruments = (mainView: boolean) => {
    const isDisabled = playerConfig.view.mobileTab !== 'chords' && isMobile

    return (
      <>
        {mainView && isMobile && instrument === 'guitar' ? null : (
          <CircleButton
            className={classNames(
              styles.instrumentRollButton,
              styles.piano,
              {
                [styles.active]: playerConfig.view.pianoOpen && !isDisabled,
              },
              { [styles.disabled]: isDisabled },
            )}
            size='medium'
            onClick={() => {
              if (isDisabled) return
              playerConfigSetter.setView({
                pianoOpen: !playerConfig.view.pianoOpen,
                guitarOpen: false,
              })
              setInstrumentSelectOpen(!playerConfig.view.pianoOpen)
            }}
            icon={isTablet ? 'piano-roll-mobile' : 'piano-roll'}
          />
        )}
        {mainView && isMobile && instrument === 'piano' ? null : (
          <CircleButton
            className={classNames(
              styles.instrumentRollButton,
              {
                [styles.active]: playerConfig.view.guitarOpen && !isDisabled,
              },
              { [styles.disabled]: isDisabled },
            )}
            size='medium'
            onClick={() => {
              if (isDisabled) return
              playerConfigSetter.setView({
                pianoOpen: false,
                guitarOpen: !playerConfig.view.guitarOpen,
              })
              setInstrumentSelectOpen(!playerConfig.view.guitarOpen)
            }}
            icon='guitar'
          />
        )}
      </>
    )
  }

  if (instrumentSelectOpen && isMobile) {
    return (
      <div className={styles.instrumentsSelectContainer}>
        <div className={styles.instruments}>{renderInstruments(false)}</div>
        <IconButton icon='close' className={styles.instrumentsClose} onClick={() => setInstrumentSelectOpen(false)} />
      </div>
    )
  }
  return (
    <>
      <div className={styles.container}>
        {isMobile ? (
          <MobileSettingsMenu
            setTonalityKeyScale={setTonalityKeyScale}
            renderMetronomeButton={() => (
              <CircleButton
                className={`${styles.metronomeButton} ${playerConfig.isMetronome ? styles.active : ''}`}
                size='medium'
                onClick={() => playerConfigSetter.setMetronome(!playerConfig.isMetronome)}
                icon='metronome'
              />
            )}
          />
        ) : (
          <div className={styles.controlsContainer}>
            {!isMobile && (
              <CircleButton
                className={`${styles.metronomeButton} ${playerConfig.isMetronome ? styles.active : ''}`}
                size='medium'
                onClick={() => playerConfigSetter.setMetronome(!playerConfig.isMetronome)}
                icon='metronome'
              />
            )}
            <BpmSelect bpm={playerConfig.bpm || 0} setBpm={playerConfigSetter.setBpm} />
            <ChordKeyMenu
              chordKey={playerConfig.prog?.key || ''}
              chordScale={playerConfig.prog?.scale || ''}
              setKeyScale={setTonalityKeyScale}
              footerMode
            />
          </div>
        )}
        {isMobile ? (
          <IconMenuButton
            icon='arrow-revert'
            className={styles.undoButton}
            onClick={() => undo()}
            enabled={isUndoAvailable}
          />
        ) : null}
        <MainPlayControls onPlay={onPlay} onReset={onReset} onRepeatChange={onRepeatChange} />
        {isMobile ? (
          <IconMenuButton
            icon='arrow-revert'
            className={styles.redoButton}
            onClick={() => redo()}
            enabled={isRedoAvailable}
          />
        ) : null}
        <div className={styles.rightControlsContainer}>
          {renderInstruments(true)}
          {/*<CircleButton*/}
          {/*  className={`${styles.instrumentRollButton} ${playerConfig.view.notationOpen ? styles.active : ''}`}*/}
          {/*  size='medium'*/}
          {/*  onClick={() => {*/}
          {/*    playerConfigSetter.setView({*/}
          {/*      ...playerConfig.view,*/}
          {/*      notationOpen: !playerConfig.view.notationOpen,*/}
          {/*      pianoOpen: false,*/}
          {/*      guitarOpen: false,*/}
          {/*      drumsEditorOpen: false,*/}
          {/*    })*/}
          {/*  }}*/}
          {/*  icon='notes'*/}
          {/*/>*/}
        </div>
      </div>
    </>
  )
}

export default PlayControls
