import classNames from 'classnames'
import React, { FC, ReactNode, useRef } from 'react'
import { useSwipeable } from 'react-swipeable'
import { Transition } from 'react-transition-group'

import ArrowIcon from '../../../../assets/icons/simple-arrow.svg'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import styles from './AssistantWrapper.module.scss'

type Props = {
  className?: string
  openHeightInitial?: number
  assistantOpen: boolean
  setAssistantOpen: (v: boolean) => void
  children: ReactNode
}

const AssistantWrapper: FC<Props> = ({ className, openHeightInitial, assistantOpen, setAssistantOpen, children }) => {
  const closedHeight = 0
  const openHeight = openHeightInitial || 500

  const { isTablet } = useSizes()

  const menuRef = useRef()

  useClickOutsideAlerter(menuRef, () => setAssistantOpen(false), clickOutsideHookIds.assistantWrapper)
  const handlers = useSwipeable({
    onSwipedDown: () => {
      setAssistantOpen(false)
    },
    onSwipedUp: () => {
      setAssistantOpen(true)
    },
  })

  return (
    <Transition in={assistantOpen} timeout={200}>
      {(status) => (
        <div
          className={classNames(styles.container, className)}
          data-assistant={true}
          style={{
            height: assistantOpen ? openHeight : closedHeight,
            zIndex: assistantOpen ? 2 : 0,
            opacity: assistantOpen ? 1 : 0,
          }}
        >
          <div
            className={styles.openArrow}
            data-open={assistantOpen}
            onClick={() => {
              setAssistantOpen(!assistantOpen)
            }}
            {...(isTablet ? handlers : {})}
          >
            <svg
              width='122'
              height='19'
              viewBox='0 0 122 19'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              className={styles.bg}
            >
              <path d='M126.236 19.1184V22.1774H-6.82324V19.1186H-6.17774C-1.30653 19.1169 6.74971 17.7153 11.6081 12.331C11.8541 12.0585 12.0959 11.7898 12.3343 11.5249L12.4169 11.4331C15.2313 8.30521 17.6057 5.67398 20.4748 3.81906C23.1211 2.10811 26.1553 1.07535 30.311 0.825104L31.0297 0.781837V0.765625H87.7453C92.8719 0.765625 96.4119 1.79094 99.4285 3.68532C102.393 5.54678 104.813 8.22808 107.694 11.4301L107.78 11.5255C108.018 11.7904 108.26 12.0588 108.505 12.331C113.345 17.6951 121.359 19.1063 126.236 19.1184Z' />
            </svg>
            <ArrowIcon className={styles.arrow} />
          </div>
          <div className={styles.content} data-closed={status === 'exited'}>
            {status === 'entered' ? children : null}
          </div>
        </div>
      )}
    </Transition>
  )
}

export default AssistantWrapper
