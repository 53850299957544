import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import useSizes from '../../../../hooks/useSizes'
import styles from '../Timeline.module.scss'

type Props = {
  playheadDisabled?: boolean
  handleDragPlayhead: (e: any, ref: React.RefObject<HTMLDivElement>, click?: boolean) => void
  mobileScrollPlaying: {
    handleStartManualScroll: () => void
    handleStopManualScroll: () => void
  }
}

const PlayheadZone: React.FC<Props> = ({ mobileScrollPlaying, playheadDisabled, handleDragPlayhead }) => {
  const [isPlayheadDragging, setIsPlayheadDragging] = useState(false)
  const { isMobile } = useSizes()
  const ref = useRef(null)
  const timeoutRef = useRef<number | null>(null)
  const handleMouseMove = (e: MouseEvent) => {
    if (playheadDisabled || !isPlayheadDragging) {
      return
    }
    if (e.buttons !== 1) {
      setIsPlayheadDragging(false)
      return
    }
    handleDragPlayhead(e, ref)
  }
  const handleMouseUp = () => {
    setTimeout(() => setIsPlayheadDragging(false), 100)
    mobileScrollPlaying.handleStopManualScroll()
  }

  useEffect(() => {
    if (isPlayheadDragging) {
      document.addEventListener('mousemove', handleMouseMove)
      document.addEventListener('mouseup', handleMouseUp)
    } else {
      document.removeEventListener('mousemove', handleMouseMove)
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [isPlayheadDragging])

  if (isMobile) {
    return null
  }
  return (
    <div
      ref={ref}
      className={classNames(styles.playheadZone, { [styles.playheadDragging]: isPlayheadDragging })}
      onClick={(e) => {
        clearTimeout(timeoutRef.current as number)
        if (playheadDisabled) {
          return
        }
        handleDragPlayhead(e, ref, true)
      }}
      onMouseDown={(e) => {
        if (e.button === 2) return

        timeoutRef.current = setTimeout(() => {
          setIsPlayheadDragging(true)
          mobileScrollPlaying.handleStartManualScroll()
        }, 100) as unknown as number
      }}
    />
  )
}

export default PlayheadZone
