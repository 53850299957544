import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import animateProperty from '../../../../hooks/animateProperty'
import useWindowDimensions from '../../../../hooks/useWIndowDimentions'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './TimelineScrollbar.module.scss'

interface Props {
  containerRef: React.RefObject<HTMLDivElement>
  timelineRef: React.RefObject<HTMLDivElement>
  scroll: number
  scale: number
  scaling: boolean
  setScroll: (a: number) => void
}

const TimelineScrollbar = ({ setScroll, scaling, scale, timelineRef, scroll, containerRef }: Props) => {
  const [moving, setMoving] = useState<number | null>(null)
  const { width } = useWindowDimensions()
  const { playerConfig } = usePlayerConfigState()
  const [containerWidth, setContainerWidth] = useState(containerRef.current?.clientWidth || 0)
  const [scrollWidth, setScrollWidth] = useState(timelineRef.current!.scrollWidth)
  useEffect(() => {
    setContainerWidth(containerRef.current?.clientWidth || 0)
  }, [width])

  const thumbWidth = useMemo(() => {
    return Math.max(0.1, containerWidth / scrollWidth)
  }, [containerWidth, scrollWidth])

  const requestRef = useRef<number>()
  const animate = () => {
    setScrollWidth(timelineRef.current!.scrollWidth)
    requestRef.current = requestAnimationFrame(animate)
  }

  useEffect(() => {
    setScrollWidth(timelineRef.current!.scrollWidth)
    requestRef.current = requestAnimationFrame(animate)
    return () => {
      if (requestRef.current === undefined) {
        return
      }
      cancelAnimationFrame(requestRef.current)
    }
  }, [])

  const SCROLLBAR_AREA = 1 - thumbWidth

  const handleMove = useCallback(
    (e: MouseEvent) => {
      if (!moving) {
        return
      }
      const newScroll = scroll + (-moving + e.pageX) / (containerWidth / scrollWidth)
      setScroll(newScroll)
    },
    [scroll, scale, scrollWidth, moving, playerConfig.prog],
  )
  useEffect(() => {
    if (!moving) {
      return
    }
    const handleStop = () => setMoving(null)
    document.addEventListener('mouseup', handleStop)
    document.addEventListener('mousemove', handleMove)
    return () => {
      document.removeEventListener('mouseup', handleStop)
      document.removeEventListener('mousemove', handleMove)
    }
  }, [moving])
  const handleChangeScroll = (newValue: number, oldValue: number) => {
    animateProperty(oldValue, newValue, 150, setScroll)
  }
  const handleScrollbarClick = (e: React.MouseEvent) => {
    const scrollArea = containerWidth * SCROLLBAR_AREA
    const ePosition = e.pageX - containerRef.current!.offsetLeft - (scrollArea / 2) * thumbWidth
    const newScroll = (ePosition / scrollArea) * scrollWidth
    handleChangeScroll(newScroll, scroll)
  }
  return (
    <div
      className={styles.container}
      onClick={(e) => {
        handleScrollbarClick(e)
      }}
    >
      {containerWidth >= scrollWidth ? null : (
        <div
          onMouseDown={(e) => {
            e.stopPropagation()
            setMoving(e.pageX)
          }}
          onClick={(e) => e.stopPropagation()}
          className={styles.thumb}
          data-scaling={scaling}
          style={{ left: (scroll * containerWidth) / scrollWidth, width: `${thumbWidth * 100}%` }}
        />
      )}
    </div>
  )
}

export default TimelineScrollbar
