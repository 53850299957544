/* eslint-disable no-case-declarations */
import classNames from 'classnames'
// import dynamic from 'next/dynamic'
import React, { FC, useEffect, useState } from 'react'

import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import LyricsEditor from '../../LyricsEditor/LyricsEditor'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './MobileTabs.module.scss'
import SettingsTab from './Tabs/SettingsTab/SettingsTab'

// const LyricsEditor = dynamic(() => import('../../LyricsEditor/LyricsEditor'))

type Props = object

const MobileTabs: FC<Props> = () => {
  const generatorModalsConfig = useGeneratorModals()
  const { playerConfig } = usePlayerConfigState()

  const [localTab, setLocalTab] = useState('chords')

  useEffect(() => {
    if (playerConfig.view.mobileTab !== 'chords') setLocalTab(playerConfig.view.mobileTab)
  }, [playerConfig.view.mobileTab])

  const renderTab = () => {
    switch (localTab) {
      case 'chords':
        return <></>
      case 'lyrics':
        return <LyricsEditor />
      case 'settings':
        return <SettingsTab />
      default:
        return <></>
    }
  }

  return (
    <>
      <div
        className={classNames(styles.mobileTab, styles[localTab], {
          [styles.active]: playerConfig.view.mobileTab !== 'chords',
          [styles.isFooterHidden]: generatorModalsConfig.isFooterHidden,
        })}
        data-disable-ids-click-outside-hook={[clickOutsideHookIds.footer]}
      >
        {renderTab()}
      </div>
    </>
  )
}

export default MobileTabs
