import React from 'react'

import { useInternationalization } from '../../../../context/InternationalizationContext'
import { getChordByIdFromProg, replaceChordByIdInProg } from '../../../../utils/progUtils'
import { Chord } from '../../../../utils/types'
import Slider from '../../../common/Slider'
import SmallRangeSelector from '../../../common/SmallRangeSelector/SmallRangeSelector'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import ChordVoicings from './ChordVoicings'
import styles from './Voicings.module.scss'

const Voicings = () => {
  const { text } = useInternationalization()
  const {
    playerConfig: { prog, currentPartId, activeChordIds },
    playerConfigSetter: { setProg },
  } = usePlayerConfigState()

  const selectedChord = activeChordIds.length && prog ? getChordByIdFromProg(activeChordIds[0], prog) : undefined
  const STRINGS = text.footer.chordEditor.voicings

  if (!selectedChord || selectedChord.draft) return null
  const { id, settings, octave, name, degree, midi, voicings } = selectedChord

  const handleUpdateChord = (chord: Chord) => {
    if (!prog) return

    const newProg = replaceChordByIdInProg(id, prog, chord, currentPartId)
    setProg(newProg)
  }

  const renderVelocitySetting = () => {
    return (
      <div className={styles.velocityContainer}>
        <div className={styles.velocityTitle}>{STRINGS.Velocity}</div>

        <Slider
          className={styles.velocitySlider}
          size='small'
          vertical
          onAfterChange={(val) => {
            const newChord = {
              ...selectedChord,
              settings: {
                ...settings,
                velocity: Math.max(1, Math.round(+val)),
              },
            }

            handleUpdateChord(newChord)
          }}
          min={0}
          max={127}
          step={1}
          initialValue={settings.velocity}
        />
      </div>
    )
  }
  const renderVoicingHeader = () => {
    return (
      <div className={styles.voicingHeader}>
        <div className={styles.chordOctave}>
          <span className={styles.octaveTitle}>{STRINGS.OctaveVoicings}</span>

          <SmallRangeSelector
            min={-1}
            max={1}
            step={1}
            value={octave || 0}
            onChange={(value) => handleUpdateChord({ ...selectedChord, octave: value })}
          />
        </div>

        <div className={styles.chordDetails}>
          <span className={styles.chordDetailsName}>{name}</span>
          <span className={styles.chordDetailsDegree}>{degree}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      {renderVelocitySetting()}

      <div className={styles.voicingContainer}>
        {renderVoicingHeader()}

        <ChordVoicings key={id} chord={selectedChord} selectedMidi={midi} voicings={voicings} />
      </div>
    </div>
  )
}

export default Voicings
