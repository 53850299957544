import { useEffect, useState } from 'react'

type IValues = {
  error: null | string
  isOffline: boolean
  isOnline: boolean
}

const useIsOnline = (): IValues => {
  const [isOnline, setOnlineStatus] = useState(typeof window !== 'undefined' ? window?.navigator.onLine : true)

  useEffect(() => {
    const toggleOnlineStatus = () => {
      setOnlineStatus(window.navigator.onLine)
    }

    window.addEventListener('online', toggleOnlineStatus)
    window.addEventListener('offline', toggleOnlineStatus)

    return () => {
      window.removeEventListener('online', toggleOnlineStatus)
      window.removeEventListener('offline', toggleOnlineStatus)
    }
  }, [isOnline])

  return {
    error: null,
    isOffline: !isOnline,
    isOnline,
  }
}

export default useIsOnline
