import classNames from 'classnames'
import React, { forwardRef } from 'react'

import ClearLyricsIllustration from '../../../../../assets/icons/clear-lyrics-illustration.svg'
import { useInternationalization } from '../../../../../context/InternationalizationContext'
import { usePlayerConfigState } from '../../../hooks/usePlayerConfigState'
import styles from './ClearLyricsModal.module.scss'

type Props = {
  onClose: () => void
}

export const ClearLyricsContent = forwardRef(({ onClose }: Props, ref: any) => {
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { getText } = useInternationalization()

  const STRINGS = getText('ClearLyricsModal')

  return (
    <div className={styles.wrapper} ref={ref}>
      <div className={styles.title}>{STRINGS.title}</div>

      <ClearLyricsIllustration className={styles.illustration} />

      <div className={styles.btnsContainer}>
        <div
          className={styles.btn}
          onClick={() => {
            onClose()
          }}
        >
          {STRINGS.cancel}
        </div>
        <div
          className={classNames(styles.btn, styles.primary)}
          onClick={() => {
            playerConfigSetter.setLyrics({
              ...playerConfig.lyrics,
              lines: [],
            })
            onClose()
          }}
        >
          {STRINGS.clear}
        </div>
      </div>
    </div>
  )
})

export default ClearLyricsContent
