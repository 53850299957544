import React, { MutableRefObject, useRef } from 'react'

import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import { useLyrics } from '../../../../context/LyricsContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import { getElementFromTree } from '../../../../utils/timelineUtils'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import ContentEditable from './ContentEditable'
import styles from './LinesInput.module.scss'

type Props = {
  editorRef: MutableRefObject<{ appendText: (a: string, offset?: number) => void }>
  placeholder?: string
}

const LinesInput: React.FC<Props> = ({ editorRef, placeholder }) => {
  const { assistantOpen, mode, suggestionsData } = useLyrics()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { isMobile } = useSizes()
  const { setCurrentWord, setCurrentWordPosition, setIsFooterHidden } = useGeneratorModals()

  const ref = useRef<HTMLDivElement>(null)

  useClickOutsideAlerter(
    ref,
    () => {
      setIsFooterHidden(false)
      setCurrentWord('')
      setCurrentWordPosition(null)
    },
    clickOutsideHookIds.linesInput,
    undefined,
    (event) =>
      document.getElementsByClassName('center-modal').length === 0 &&
      !getElementFromTree(event, (e) => !!(e?.getAttribute('data-assistant') || e?.getAttribute('data-select'))),
  )
  
  const updateText = (str: string) => {
    const newLines = str.length ? str.split('\n') : []
    if (playerConfig.lyrics.lines.join(' -- ') === newLines.join(' -- ')) {
      return
    }
    playerConfigSetter.setLyrics({
      ...playerConfig.lyrics,
      lines: newLines,
    })
  }
  return (
    <div
      className={styles.scroll}
      onScroll={() => {
        if (!isMobile) {
          return
        }
        // @ts-ignore
        document.activeElement?.blur()
      }}
    >
      <div
        className={styles.container}
        ref={ref}
        onClick={() => {
          if (suggestionsData && isMobile && assistantOpen && mode === 'ai-lines') {
            // @ts-ignore
            document.activeElement?.blur()
          }
        }}
      >
        <ContentEditable
          onChange={(val) => updateText(val)}
          initialValue={playerConfig.lyrics.lines.join('\n')}
          ref={editorRef}
          placeholder={placeholder}
        />
      </div>
    </div>
  )
}

export default LinesInput
