import { NextPage } from 'next'
import Head from 'next/head'
import React, { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'

import { InteractivePianoRollProvider } from '../components/ai-playground/InteractivePianoRoll/hooks/useInteractivePianoRoll'
import { PianoRollPlayheadProvider } from '../components/ai-playground/InteractivePianoRoll/hooks/usePianoRollPlayhead'
import { PianoRollMode } from '../components/ai-playground/InteractivePianoRoll/types'
import PageLoader from '../components/common/PageLoader/PageLoader'
import SmartLookIdentify from '../components/common/SmartLook/SmartLookIdentify'
import { drumsPianoRollId } from '../components/editor/DrumsEditor/DrumsEditor'
import EditorContainer from '../components/editor/EditorContainer/EditorContainer'
import { melodyPianoRollId } from '../components/editor/MelodyEditor/MelodyEditor'
import { EditMenuProvider } from '../components/editor/hooks/useEditMenu'
import { PlaybackTimePositionProvider } from '../components/editor/hooks/usePlaybackTimePosition'
import { PlayerConfigStateProvider } from '../components/editor/hooks/usePlayerConfigState'
import HotjarScript from '../components/scripts/Hotjar/HotjarScript'
import SentryScript from '../components/scripts/Sentry/SentryScript'
import { CommonModalsProvider } from '../context/CommonModalsContext'
import { CurrentUserProvider } from '../context/CurrentUserContext'
import { ExportProvider } from '../context/ExportContext'
import { GeneratorModalsProvider } from '../context/GeneratorModalsContext'
import { GoToDiffProjectStateProvider } from '../context/GoToDiffProjectContext'
import { HistoryProvider } from '../context/HistoryContext'
import { InstrumentsStateProvider } from '../context/InstrumentsStateContext'
import { useInternationalization } from '../context/InternationalizationContext'
import { LimitsProvider } from '../context/LimitsContext'
import { LyricsProvider } from '../context/LyricsContext'
import { PaddleProvider } from '../context/PaddleContext'
import { ProjectStateProvider } from '../context/ProjectStateContext'
import { SizesProvider } from '../hooks/useSizes'
import { getDistinctIdFromQuery, getPreOnboardingTutorialStep, getUserDetailedFromQuery } from '../utils/nextUtils'
import { FrontendUserDetailed } from '../utils/types'

const Generator: NextPage<{
  user: FrontendUserDetailed
  distinctId: string
  onboardingStep: string
  preOnboardingTutorialStep: string
}> = ({ user, distinctId, onboardingStep, preOnboardingTutorialStep: pOTS }) => {
  const { loaded: textLoaded, getText } = useInternationalization()

  const [pageLoading, setPageLoading] = useState(true)
  const [loaded, setLoaded] = useState(false)
  const [queryClient] = React.useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
          },
        },
      }),
  )
  useEffect(() => {
    document.body.style.overflow = 'hidden'
    setLoaded(true)
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  const STRINGS = getText('headMeta')

  return (
    <>
      <Head>
        <title>{STRINGS.title}</title>
        <meta
          name='viewport'
          content='user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1, width=device-width, height=device-height'
        />
        <meta name='description' content={STRINGS.description} />
        <meta property='og:image' content='https://chordchord.com/static/images/logo_icon.jpg' />
      </Head>
      <QueryClientProvider client={queryClient}>
        <style jsx global>
          {`
            html,
            body {
              background-color: #17171a;
              overscroll-behavior-x: none;
            }
          `}
        </style>
        <CurrentUserProvider user={user}>
          <SmartLookIdentify />
          <PaddleProvider>
            <ProjectStateProvider>
              {/* Melody Piano Roll */}
              <PianoRollPlayheadProvider id={melodyPianoRollId}>
                <InteractivePianoRollProvider id={melodyPianoRollId} width={'100%'} height={'100%'} isMonophonic={true}>
                  {/* Drums Piano Roll */}
                  <PianoRollPlayheadProvider id={drumsPianoRollId}>
                    <InteractivePianoRollProvider
                      id={drumsPianoRollId}
                      isMonophonic={false}
                      pianoRollMode={PianoRollMode.SAMPLER}
                      height={'100%'}
                      width={'100%'}
                      noteColor={'#1DA574'}
                      colorAutocompleteHoveredStroke={'#1DA574'}
                      colorAutocompleteHoveredBackground={
                        'repeating-linear-gradient(-45deg, #305248, #305248 6px, #19302A 6px, #19302A 12px)'
                      }
                      colorHeldKeyStroke={'#1bd190'}
                      colorHeldKeyBackground={'#16684b'}
                      initialMidiRangeMin={0}
                      initialMidiRangeMax={6}
                      initialScrollY={0}
                    >
                      {/* HistoryProvider NEEDS: ProjectStateProvider | InteractivePianoRollProvider */}
                      <HistoryProvider>
                        <SizesProvider>
                          <CommonModalsProvider generatorComponent>
                            <LimitsProvider>
                              <GoToDiffProjectStateProvider>
                                {loaded ? (
                                  <PlayerConfigStateProvider>
                                    <PlaybackTimePositionProvider>
                                      <InstrumentsStateProvider>
                                        <LyricsProvider>
                                          <ExportProvider>
                                            <GeneratorModalsProvider>
                                              <EditMenuProvider>
                                                <EditorContainer
                                                  pageLoading={pageLoading}
                                                  setPageLoading={setPageLoading}
                                                />
                                              </EditMenuProvider>
                                            </GeneratorModalsProvider>
                                          </ExportProvider>
                                        </LyricsProvider>
                                      </InstrumentsStateProvider>
                                    </PlaybackTimePositionProvider>
                                  </PlayerConfigStateProvider>
                                ) : null}
                                <HotjarScript distinctId={distinctId} />
                                <SentryScript distinctId={distinctId} />
                              </GoToDiffProjectStateProvider>
                            </LimitsProvider>
                          </CommonModalsProvider>
                        </SizesProvider>
                      </HistoryProvider>
                    </InteractivePianoRollProvider>
                  </PianoRollPlayheadProvider>
                </InteractivePianoRollProvider>
              </PianoRollPlayheadProvider>
            </ProjectStateProvider>
          </PaddleProvider>
        </CurrentUserProvider>
        <PageLoader loading={pageLoading || !textLoaded} />
      </QueryClientProvider>
    </>
  )
}

Generator.getInitialProps = async ({ query }) => {
  return {
    user: getUserDetailedFromQuery(query),
    distinctId: getDistinctIdFromQuery(query),
    onboardingStep: query.onboardingStep as string,
    preOnboardingTutorialStep: getPreOnboardingTutorialStep(query),
  }
}

export default Generator
