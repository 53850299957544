import { Chord, GuitarChord } from '../utils/types'
import api from './index'

export type customChordConfig = {
  chordType?: string | string[] | null
  tonalityKey?: string | string[] | null
  tonalityScale?: string | string[] | null
  chordKey?: string | string[] | null
}

export const customChordByConfig = ({ chordKey, tonalityKey, tonalityScale, chordType }: customChordConfig) => {
  return api.get(`${process.env.API_URL}/chord/custom`, {
    params: { chordKey, tonalityKey, tonalityScale, chordType },
  })
}

export const customChordByConfigInnerRoute = async ({
  chordKey,
  tonalityKey,
  tonalityScale,
  chordType,
}: customChordConfig) => {
  const data = await api.get('/api/custom-chord', {
    params: { chordKey, tonalityKey, tonalityScale, chordType },
  })
  return data.data
}

export const chordVoicingByLevel = ({ chord }: { chord: Chord }) => {
  return api.get(`${process.env.API_URL}/chord/voicing`, {
    params: { chord },
  })
}

export const chordVoicingByLevelInnerRoute = async ({ chord }: { chord: Chord }) => {
  const data = await api.get('/api/chord-voicing', {
    params: { chord },
  })
  return data.data
}

export const getGuitarChordInnerRoute = async ({
  chordName,
  chordNotes,
}: {
  chordName: string
  chordNotes: number[]
}) => {
  const data = await api.get('/api/guitar-chords', {
    params: { chordName, chordNotes: JSON.stringify(chordNotes) },
  })
  return data.data as GuitarChord[]
}
