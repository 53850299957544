import { PointerSensor } from '@dnd-kit/core'
import { PointerEvent } from 'react'

class TimelinePointer extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as any,
      handler: ({ nativeEvent: event }: PointerEvent<Element>) => {
        if (!(event.currentTarget instanceof HTMLElement)) return false

        const cast = event.target as HTMLElement

        if (!event.isPrimary || event.button !== 0 || (cast && cast.id == 'blocker')) {
          return false
        }

        return true
      },
    },
  ]
}

export default TimelinePointer
