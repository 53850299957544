import classNames from 'classnames'
import { motion } from 'framer-motion'
import dynamic from 'next/dynamic'
import React, { useRef, useState } from 'react'
import { useQuery } from 'react-query'
import { useSwipeable } from 'react-swipeable'

import { transposeProgressionInnerRoute } from '../../../api/progresssions'
import { getUserPrefsInnerRoute } from '../../../api/user-prefs'
import { useCommonModals } from '../../../context/CommonModalsContext'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useProjectState } from '../../../context/ProjectStateContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../hooks/useSizes'
import { getActivePart } from '../../../utils/progUtils'
import { getElementFromTree } from '../../../utils/timelineUtils'
import { Chord } from '../../../utils/types'
import MobileSwipeableModal from '../../common/MobileSwipeableModal/MobileSwipeableModal'
import CommonFooter from '../../layout/CommonFooter/CommonFooter'
import Launchpad from '../Launchpad/Launchpad'
import LayersOfInstruments from '../LayersOfInstruments/LayersOfInstruments'
import ChordEditor from '../NewChordEditor/ChordEditor'
import useMobileScrollPlaying from '../Timeline/hooks/useMobileScrollPlaying/useMobileScrollPlaying'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import styles from './Footer.module.scss'
import MenuControl from './MenuControl/MenuControl'
import PlayControls from './PlayControls/PlayControls'
import VolumeControls from './VolumeControls'
import {
  getChordEditorHeight,
  getChordLayersHeight,
  getDrumsEditorHeight,
  getInstrumentHeight,
  getMelodyEditorHeight,
} from './footer-utils'

const MelodyEditor = dynamic(() => import('../MelodyEditor/MelodyEditor'))
const DrumsEditor = dynamic(() => import('../DrumsEditor/DrumsEditor'))
const PianoRoll = dynamic(() => import('../../common/PianoRoll'))
const GuitarChords = dynamic(() => import('../../common/GuitarChords/GuitarChords'))

type Props = {
  localPosition: number | null
  setLocalPosition: (a: number | null) => void
}

const Footer: React.FC<Props> = ({ localPosition, setLocalPosition }) => {
  const { isMobile, isTablet, isSmallDesktop } = useSizes()
  const {
    playerConfig: {
      prog,
      view,
      player,
      activeChord,
      volume,
      playingChord,
      isPlaying,
      webMidiPlaying,
      currentPartId,
      editTrigger,
    },
    playerConfigSetter,
  } = usePlayerConfigState()
  const mobileScrollPlaying = useMobileScrollPlaying()
  const projectConfigState = useProjectState()
  const generatorModalsConfig = useGeneratorModals()
  const modalsConfig = useCommonModals()

  const ref = useRef<HTMLDivElement>()
  const emptyRef = useRef<HTMLDivElement>()
  const mobileSwipeableModalContentRef = useRef<HTMLDivElement>(null)

  const { data: prefs } = useQuery(['user-prefs'], getUserPrefsInnerRoute)

  const [localVolume, setLocalVolume] = useState(volume)
  const [instrumentsSelectOpen, setInstrumentsSelectOpen] = useState(false)
  const [muted, setMuted] = useState(false)
  const [pianoScrollDisabled, setPianoScrollDisabled] = useState(false)
  const [tabHeightPosition, setTabHeightPosition] = useState(0)

  const instrumentOpen = view.pianoOpen || view.guitarOpen || view.notationOpen

  const handlers = useSwipeable({
    onSwipedDown: (e) => {
      playerConfigSetter.setView()
      setInstrumentsSelectOpen(false)
    },
    onSwipedUp: () => {
      if (!isMobile && (view.drumsEditorOpen || view.melodyEditorOpen)) {
        return
      }
      if (instrumentOpen) {
        return
      }
      playerConfigSetter.setView({
        pianoOpen: prefs?.favouriteInstrument === 'piano',
        guitarOpen: prefs?.favouriteInstrument === 'guitar',
      })
    },
    onSwiped: () => setLocalPosition(null),
    onSwiping: (e) => {
      setLocalPosition(e.deltaY)
    },
    trackMouse: true,
  })

  useClickOutsideAlerter(
    ref,
    () => playerConfigSetter.setView(),
    clickOutsideHookIds.footer,
    'dblclick',
    // need this to prevent closing melody editor when deleting note with double click (as note is deleted and can't be found in tree in main hook)
    (event) => !getElementFromTree(event, (e) => !!e?.id?.includes('note-')),
  )

  const getMenuContolType = () => {
    if (view.melodyEditorOpen) return 'height-change'
    if (view.drumsEditorOpen) return 'height-change'
    if (view.editorOpen) return 'height-change'
    return 'close-open'
  }
  const getOuterMotionProps = (
    menuOpened: boolean,
    initialHeight: number,
    animateHeight: number,
    dataVisible = true,
  ) => ({
    className: styles.activeTabContainer,
    'data-visible': dataVisible,
    initial: {
      height: initialHeight,
      opacity: 1,
    },
    transition: {
      duration: localPosition !== null ? 0.1 : 0.25,
      ease: 'easeOut',
    },
    animate: {
      opacity: !menuOpened && localPosition === null ? 0 : 1,
      height: animateHeight,
    },
  })
  const getInnerMotionProps = (menuOpened: boolean) => ({
    initial: { opacity: 1 },
    transition: {
      duration: localPosition ? 0.01 : 0.25,
      ease: 'easeOut',
    },
    animate: {
      opacity: menuOpened || localPosition ? 1 : 0,
      height: menuOpened ? '100%' : 0,
    },
  })

  const renderInstrument = () => {
    const chordIsPlaying = !!playingChord && isPlaying

    const getInstrumentViewSize = () => {
      if (isMobile) return 'small'
      if (isSmallDesktop) return 'medium'
      return 'large'
    }
    const addOctaveToMidi = (chord: Chord) => {
      return (chord?.midi || []).map((midi) => midi + ((playingChord || activeChord)?.octave || 0) * 12)
    }

    return (
      <div
        className={classNames(styles.instrumentRoll, { [styles.guitarOpen]: view.guitarOpen })}
        style={pianoScrollDisabled ? { overflow: 'hidden' } : {}}
      >
        {view.pianoOpen && (
          <PianoRoll
            fromOctave={0}
            octaves={7}
            size={getInstrumentViewSize()}
            playingMidi={chordIsPlaying && !playingChord.draft ? addOctaveToMidi(playingChord) : []}
            selectedMidi={!chordIsPlaying && activeChord && !activeChord.draft ? addOctaveToMidi(activeChord) : []}
            webMidi={webMidiPlaying}
            setPianoScrollDisabled={setPianoScrollDisabled}
          />
        )}

        {view.guitarOpen && <GuitarChords />}

        {view.notationOpen && <div>NOTATION OF NOTES</div>}
      </div>
    )
  }
  const renderTabs = () => {
    const sizes = { isMobile, isSmallDesktop }

    const menuOpened_chordLayers = !!view.layersOpen
    const initialHeight_chordLayers = getChordLayersHeight(menuOpened_chordLayers, tabHeightPosition, sizes)

    const menuOpened_chordEditor = view.editorOpen
    const initialHeight_chordEditor = getChordEditorHeight(menuOpened_chordEditor, tabHeightPosition, sizes)

    const menuOpened_melodyEditor = view.melodyEditorOpen
    const initialHeight_melodyEditor = getMelodyEditorHeight(menuOpened_melodyEditor, tabHeightPosition, sizes)

    const menuOpened_drumEditor = view.drumsEditorOpen
    const initialHeight_drumEditor = getDrumsEditorHeight(menuOpened_drumEditor, tabHeightPosition, sizes)

    const menuOpened_instrument = instrumentOpen
    const initialHeight_instrument = getInstrumentHeight(view, sizes)

    // global
    const menuOpened =
      menuOpened_chordLayers ||
      menuOpened_chordEditor ||
      menuOpened_melodyEditor ||
      menuOpened_drumEditor ||
      menuOpened_instrument
    const initialHeight =
      (initialHeight_chordLayers ||
        initialHeight_chordEditor ||
        initialHeight_melodyEditor ||
        initialHeight_drumEditor) + initialHeight_instrument
    const animateHeight = initialHeight - (localPosition || 0) - (editTrigger ? 5 : 0)

    return (
      <motion.div {...getOuterMotionProps(menuOpened, initialHeight, animateHeight)}>
        <motion.div {...getInnerMotionProps(menuOpened_chordLayers)}>
          {menuOpened_chordLayers && <LayersOfInstruments />}
        </motion.div>

        <motion.div {...getInnerMotionProps(menuOpened_chordEditor)}>
          {menuOpened_chordEditor && <ChordEditor />}
        </motion.div>

        <motion.div {...getInnerMotionProps(menuOpened_melodyEditor)}>
          {menuOpened_melodyEditor && <MelodyEditor />}
        </motion.div>

        <motion.div {...getInnerMotionProps(menuOpened_drumEditor)}>
          {menuOpened_drumEditor && <DrumsEditor />}
        </motion.div>

        <motion.div
          {...getInnerMotionProps(menuOpened_instrument)}
          style={{ flexShrink: 0 }}
          className={styles.instrumentContainer}
          animate={{
            opacity: menuOpened_instrument ? 1 : 0,
            height: menuOpened_instrument ? initialHeight_instrument : 0,
          }}
        >
          {menuOpened_instrument && renderInstrument()}
        </motion.div>
      </motion.div>
    )
  }
  const renderPlayControls = () => {
    return (
      <PlayControls
        onReset={() => {
          mobileScrollPlaying.interruptMobileScroll()
          playerConfigSetter.playControlsOnReset()
        }}
        instrumentSelectOpen={instrumentsSelectOpen}
        setInstrumentSelectOpen={setInstrumentsSelectOpen}
        onPlay={playerConfigSetter.playControlsOnPlay}
        onRepeatChange={playerConfigSetter.playControlsOnRepeatChange}
        setTonalityKeyScale={async (tonalityKey: string, tonalityScale: string) => {
          if (!prog) return

          player.reset()

          playerConfigSetter.setIsProgLoading(true)
          playerConfigSetter.setIsMelodyLoading(true)

          const newProg = await transposeProgressionInnerRoute({
            prog: prog,
            tonalityKey,
            tonalityScale,
          })

          playerConfigSetter.setProg({ ...newProg, key: tonalityKey, scale: tonalityScale })
          playerConfigSetter.setMelodyPianorollNotes(getActivePart(newProg, currentPartId)?.melody?.notes)
          playerConfigSetter.setIsProgLoading(false)
          playerConfigSetter.setIsMelodyLoading(false)
        }}
      />
    )
  }

  const renderFooterLeft = () => {
    if (isTablet) return null
    return (
      <VolumeControls
        volume={volume}
        onVolumeChange={(v) => {
          playerConfigSetter.setVolume(v)
          setLocalVolume(v)
          if (muted) setMuted(false)
        }}
        muted={muted}
        setMuted={(v) => {
          setMuted(v)
          playerConfigSetter.setVolume(v ? 0 : localVolume)
        }}
        disableOnMute={false}
      />
    )
  }
  const renderFooterRight = () => {
    if (isMobile) {
      return (
        <MobileSwipeableModal
          onClose={() => modalsConfig.setLaunchpadOpen(false)}
          open={modalsConfig.launchpadOpen}
          getHeight={() => mobileSwipeableModalContentRef.current?.clientHeight}
        >
          <div className={styles.launchpadContainer} ref={mobileSwipeableModalContentRef}>
            <Launchpad onClose={() => modalsConfig.setLaunchpadOpen(false)} />
          </div>
        </MobileSwipeableModal>
      )
    }
    if (isTablet) return null
    return <Launchpad />
  }

  return (
    <>
      <CommonFooter
        generatorComponent
        className={classNames(styles.footer, { [styles.hidden]: generatorModalsConfig.isFooterHidden && isMobile })}
      >
        <div
          className={styles.wrapper}
          // @ts-ignore
          ref={projectConfigState.projectEmpty ? emptyRef : ref}
          data-disable-ids-click-outside-hook={[clickOutsideHookIds.timelineWrapper]}
        >
          {isMobile && view.mobileTab !== 'chords' ? null : (
            <>
              <MenuControl
                onClose={() => setInstrumentsSelectOpen(false)}
                setLocalPosition={setLocalPosition}
                heightPosition={tabHeightPosition}
                setHeightPosition={setTabHeightPosition}
                type={getMenuContolType()}
              />
              {renderTabs()}
            </>
          )}

          <div id={'footer-bottom'} className={styles.bottom} {...handlers}>
            {renderFooterLeft()}

            <div className={styles.controls}>{renderPlayControls()}</div>

            {renderFooterRight()}
          </div>
        </div>
      </CommonFooter>
    </>
  )
}

export default Footer
