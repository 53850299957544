import classNames from 'classnames'
import React, { ReactElement } from 'react'

import styles from './SelectSetting.module.scss'

type Props = {
  value: string | ReactElement
  name: string
  onClick?: () => void
  nameProps?: any
  className?: string
}

const SelectSetting: React.FC<Props> = (props) => {
  return (
    <div className={classNames(styles.container, props.className)} onClick={props.onClick}>
      <span className={styles.value}>{props.value}</span>
      <span className={styles.name} {...(props.nameProps || {})}>
        {props.name}
      </span>
    </div>
  )
}

export default SelectSetting
