import classNames from 'classnames'
import React, { useEffect } from 'react'

import { useInternationalization } from '../../../context/InternationalizationContext'
import useIsActiveRoute from '../../../hooks/useIsActiveRoute'
import Routes from '../../../utils/routes'
import Container from '../../common/Container/container'
import Link from '../../common/Link/Link'
import styles from './CommonFooter.module.scss'

type Props = {
  children?: React.ReactNode
  className?: string
  light?: boolean
  generatorComponent?: boolean
  generatorOnboarding?: boolean
}

const CommonFooter: React.FC<Props> = ({ className, light, generatorComponent, generatorOnboarding, children }) => {
  const { addComponentText, getText } = useInternationalization()

  useEffect(() => {
    addComponentText('CommonFooter')
  }, [])

  const STRINGS = getText('CommonFooter')
  const classes = classNames(
    styles.footer,
    className,
    light ? styles.light : '',
    generatorComponent ? styles.generator : '',
    { [styles.generatorOnboarding]: generatorOnboarding },
  )

  const isEducation = useIsActiveRoute(Routes.EDU)
  const isBlog = useIsActiveRoute(Routes.BLOG)
  const isTerms = useIsActiveRoute(Routes.TERMS_OF_SERVICE)
  const isContactUs = useIsActiveRoute(Routes.CONTACT_US)
  const isPrivacy = useIsActiveRoute(Routes.PRIVACY_POLICY)
  const isGenerator = useIsActiveRoute(Routes.GENERATOR)

  const menuData = [
    {
      title: 'Education',
      link: Routes.EDU,
      active: isEducation,
    },
    {
      title: 'Blog',
      link: Routes.BLOG,
      active: isBlog,
    },
    {
      title: 'Generator',
      link: Routes.GENERATOR,
      active: isGenerator,
    },
    {
      title: 'Contact Us',
      link: Routes.CONTACT_US,
      active: isContactUs,
    },
    {
      title: 'Terms of Service',
      link: Routes.TERMS_OF_SERVICE,
      active: isTerms,
    },
    {
      title: 'Privacy Policy',
      link: Routes.PRIVACY_POLICY,
      active: isPrivacy,
    },
  ]
  const copyright = `©${new Date().getFullYear()} ${STRINGS.copyright}`

  return (
    <div className={classes}>
      <Container className={styles.container}>
        {children ? (
          children
        ) : (
          <>
            <p className={styles.copyright}>{copyright}</p>
            <ul className={styles.menu}>
              {menuData.map((item, idx) => (
                <li key={idx}>
                  <Link
                    className={item.active ? styles.activeLink : ''}
                    target={item.link.includes('https') ? '_blank' : ''}
                    rel={item.link.includes('https') ? 'noreferrer' : ''}
                    href={item.link}
                  >
                    {STRINGS[item.title]}
                  </Link>
                </li>
              ))}
            </ul>
          </>
        )}
      </Container>
    </div>
  )
}

export default CommonFooter
