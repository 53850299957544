import { TouchSensor } from '@dnd-kit/core'
import { TouchEvent } from 'react'

class TimelineToucher extends TouchSensor {
  static activators = [
    {
      eventName: 'onTouchStart' as any,
      handler: ({ nativeEvent: event }: TouchEvent<Element>) => {
        if (!(event.currentTarget instanceof HTMLElement)) return false

        const cast = event.target as HTMLElement

        if (!event.touches || event.touches.length > 1 || (cast && cast.id == 'blocker')) {
          return false
        }

        return true
      },
    },
  ]
}

export default TimelineToucher
