import {
  Active,
  closestCenter,
  DndContext,
  DragOverlay,
  KeyboardSensor,
  MeasuringStrategy,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import React, { useMemo, useState } from 'react'

import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import useSizes from '../../../hooks/useSizes'
import { getMaxPartsAvailable, updateProgPartDataById } from '../../../utils/progUtils'
import { Prog } from '../../../utils/types'
import MobileSwipeableModal from '../../common/MobileSwipeableModal/MobileSwipeableModal'
import TimelineToucher from '../Timeline/Sensors/TimelineToucher'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import styles from './PartsMenu.module.scss'
import PartsMenuEditNameModal from './PartsMenuEditNameModal/PartsMenuEditNameModal'
import PartsMenuItem from './PartsMenuItem/PartsMenuItem'
import PartsMenuItemAdd from './PartsMenuItem/PartsMenuItemAdd'

const PartsMenu = ({
  highlightPart,
  open = false,
  onClose,
}: {
  highlightPart: boolean
  open?: boolean
  onClose?: any
}) => {
  const { isMobile } = useSizes()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const generatorModals = useGeneratorModals()

  const [active, setActive] = useState<Active | null>(null)
  const [tabWidth, setTabWidth] = useState(0)

  let items = playerConfig.prog?.parts || []
  const smallPartMode = tabWidth < 120
  const maxPartsAvailable = getMaxPartsAvailable(navigator)
  const addNewEnabled = (playerConfig.prog?.parts.length || 0) < maxPartsAvailable

  const activeItem = useMemo(() => items.find((item) => item.id === active?.id), [active, items])

  const mobileSensors = useSensors(useSensor(TimelineToucher))
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  )

  const renderSortableList = () => (
    <DndContext
      sensors={isMobile ? mobileSensors : sensors}
      onDragStart={({ active }) => {
        if (playerConfig.isPartLooped) {
          return
        }
        setActive(active)
      }}
      collisionDetection={closestCenter}
      onDragEnd={({ active, over }) => {
        if (playerConfig.isPartLooped) {
          return
        }
        if (over && active.id !== over?.id) {
          const activeIndex = items.findIndex(({ id }) => id === active.id)
          const overIndex = items.findIndex(({ id }) => id === over.id)
          if (!playerConfig.prog) {
            return
          }
          playerConfigSetter.setProg({
            ...playerConfig.prog,
            parts: arrayMove(items, activeIndex, overIndex),
          })
          items = arrayMove(items, activeIndex, overIndex)
        }
        setActive(null)
      }}
      onDragCancel={() => {
        if (playerConfig.isPartLooped) {
          return
        }
        setActive(null)
      }}
      measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
      autoScroll={{
        threshold: {
          x: 0,
          y: 0.2,
        },
      }}
    >
      <SortableContext items={items} strategy={isMobile ? verticalListSortingStrategy : horizontalListSortingStrategy}>
        <div className={styles.list} role='application'>
          {items
            .slice(0, maxPartsAvailable)
            .filter(Boolean)
            .map((item, index) => (
              <React.Fragment key={item.id}>
                <PartsMenuItem
                  item={item}
                  setTabWidth={index === 0 ? setTabWidth : undefined}
                  duplicateDisabled={!addNewEnabled}
                  smallMode={smallPartMode}
                  highlight={highlightPart && item.id === playerConfig.currentPartId}
                  dragDisabled={playerConfig.isPartLooped}
                  disabled={playerConfig.isPartLooped && item.id !== playerConfig.currentPartId}
                  active={item.id === playerConfig.currentPartId}
                  onEditName={() => {
                    if (isMobile) onClose()
                    generatorModals.setEditNameModalOpen(item)
                  }}
                  onClick={() => {
                    if (item.id === playerConfig.currentPartId) return
                    playerConfigSetter.setCurrentPartId(item.id)
                  }}
                />
              </React.Fragment>
            ))}

          {addNewEnabled && <PartsMenuItemAdd setTabWidth={setTabWidth} onClose={onClose} />}
        </div>
      </SortableContext>
      <DragOverlay modifiers={[isMobile ? restrictToVerticalAxis : restrictToHorizontalAxis]}>
        {activeItem ? (
          <PartsMenuItem
            dragging
            width={tabWidth}
            item={activeItem}
            active={activeItem.id === playerConfig.currentPartId}
            smallMode={smallPartMode}
          />
        ) : null}
      </DragOverlay>
    </DndContext>
  )
  const renderModal = () => (
    <PartsMenuEditNameModal
      open={!!generatorModals.editNameModalOpen}
      defaultName={generatorModals.editNameModalOpen?.name || ''}
      onClose={() => {
        generatorModals.setEditNameModalOpen(null)
      }}
      onSave={async (name) => {
        playerConfigSetter.setProg(
          updateProgPartDataById(playerConfig.prog as Prog, generatorModals.editNameModalOpen?.id as number, { name }),
        )
        generatorModals.setEditNameModalOpen(null)
      }}
    />
  )

  if (isMobile) {
    return (
      <>
        {renderModal()}
        <MobileSwipeableModal open={open} onClose={onClose} getHeight={() => 1000}>
          <div className={styles.scrollContent} onTouchMove={(e) => e.stopPropagation()} data-block-swipe='true'>
            {renderSortableList()}
          </div>
        </MobileSwipeableModal>
      </>
    )
  }
  return (
    <div className={styles.container}>
      {renderModal()}
      {renderSortableList()}
    </div>
  )
}

export default PartsMenu
