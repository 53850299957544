function animateProperty(from: number, to: number, duration: number, callback: (a: number) => void) {
  const startTime = performance.now()

  function animate(currentTime: number) {
    const elapsedTime = currentTime - startTime
    const progress = Math.min(elapsedTime / duration, 1)
    const easedProgress = ease(progress)
    const currentValue = from + (to - from) * easedProgress

    // Update the property with the current value
    callback(currentValue)

    if (progress < 1) {
      requestAnimationFrame(animate)
    }
  }

  function ease(t: number) {
    // You can use different easing functions here
    // For example, linear easing:
    return t
  }

  requestAnimationFrame(animate)
}

export default animateProperty
