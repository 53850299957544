import React, { useRef } from 'react'

import EditIcon from '../../../../assets/icons/pencil.svg'
import DeleteIcon from '../../../../assets/icons/trash.svg'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import useSizes from '../../../../hooks/useSizes'
import { chordsToPartTimings } from '../../../../utils/audio/audioUtils'
import { getDefaultProgWithChords } from '../../../../utils/types'
import DropdownMenu from '../../../common/DropdownMenu/DropdownMenu'
import IconMenuButton from '../../../common/IconMenuButton'
import { InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import BitsPreview from './BitsPreview/BitsPreview'
import styles from './DrumsPlaceholder.module.scss'

type Props = {
  width?: number
  dragProps: any
  type?: InstrumentType
}

const DrumsPlaceholder = ({ dragProps, width, type = 'DRUMS' }: Props) => {
  const { text } = useInternationalization()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { isMobile } = useSizes()

  const valueRef = useRef<HTMLDivElement>(null)

  const isMelodyMode = type === 'MELODY'
  const isDrumMode = type === 'DRUMS'

  const handleEdit = () => {
    playerConfigSetter.setView({
      drumsEditorOpen: isDrumMode,
      drumsOpen: isDrumMode,
      melodyEditorOpen: isMelodyMode,
      pianoOpen: isMelodyMode && !!isMobile,
    })
  }
  const handleDelete = () => {
    if (isMelodyMode) playerConfigSetter.setMelodyPattern(null)
    if (isDrumMode) playerConfigSetter.setPattern(null)
  }

  const { drumsDuration, melodyDuration } = chordsToPartTimings(
    getDefaultProgWithChords(playerConfig.currentPart.chords),
    undefined,
    width || playerConfig.currentPart?.drums?.length,
    width || playerConfig.currentPart?.melody?.length,
  )

  const duration = isMelodyMode ? melodyDuration : drumsDuration

  return (
    <div className={styles.container}>
      <BitsPreview
        part={playerConfig.currentPart}
        isPlaceholder={false}
        duration={duration}
        melodyPlayer={playerConfig.player.melodyPlayer}
        drumsPlayer={playerConfig.player.drumsPlayer}
        type={type}
      />
      <div className={styles.editDeleteContainer} onDoubleClick={handleEdit}>
        <div />
        <IconMenuButton
          text={text.timeline.drumsTile.edit}
          icon='drums2'
          className={styles.editButton}
          onClick={handleEdit}
        />
      </div>
      <div className={styles.headerMenu} ref={valueRef}>
        <div />
        <div className={styles.headerRight}>
          <DropdownMenu
            className={styles.dropdownMenu}
            options={[
              [
                {
                  name: text.timeline.drumsTile.edit,
                  icon: <EditIcon />,
                  key: 1,
                  onClick: handleEdit,
                },
                {
                  name: text.timeline.drumsTile.delete,
                  icon: <DeleteIcon />,
                  key: 2,
                  onClick: handleDelete,
                },
              ],
            ]}
            renderTrigger={(onClick) => {
              return <IconMenuButton className={styles.menuButton} icon='dots-menu' onClick={onClick} />
            }}
            allowCustomPosition={!isMobile}
          />
          <div
            {...dragProps}
            className={styles.dragButton}
            onPointerDown={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
          >
            <IconMenuButton icon='repeat2' className={styles.dragIcon} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default DrumsPlaceholder
