import Image from 'next/image'
import React, { useEffect, useState } from 'react'

import { COPYRIGHT } from '../../../utils/constants'
import styles from './PageLoader.module.scss'

const STRINGS = {
  footer: COPYRIGHT,
}

const PageLoader = ({ loading }: { loading: boolean }) => {
  const [open, setOpen] = useState(loading)
  useEffect(() => {
    if (!loading) {
      setTimeout(() => setOpen(false), 1000)
    }
  }, [loading])
  return (
    <>
      {open ? (
        <div className={styles.container}>
          <Image src='/static/images/logo.svg' height={24} width={182} priority />
          <div className={styles.loader}>
            <div />
          </div>
          <p className={styles.footer}>{STRINGS.footer}</p>
        </div>
      ) : null}
    </>
  )
}

export default PageLoader
