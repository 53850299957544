import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import useDetectKeyboardOpen from 'use-detect-keyboard-open'

import { transposeProgressionInnerRoute } from '../../../../../../api/progresssions'
import Minus from '../../../../../../assets/icons/minus.svg'
import Plus from '../../../../../../assets/icons/plus.svg'
import { useInternationalization } from '../../../../../../context/InternationalizationContext'
import { useProjectState } from '../../../../../../context/ProjectStateContext'
import useLimitedRequest from '../../../../../../hooks/useLimitedRequest'
import useSizes from '../../../../../../hooks/useSizes'
import { hjEvent } from '../../../../../../utils/hotjar'
import { LimitTypesLabel } from '../../../../../../utils/limits'
import { getActivePart } from '../../../../../../utils/progUtils'
import Popup from '../../../../../common/Popup/Popup'
import ChordKeyMenu from '../../../../NewChordEditor/components/ChordKeyMenu'
import { usePlayerConfigState } from '../../../../hooks/usePlayerConfigState'
import styles from './SettingsTab.module.scss'

const SettingsTab = () => {
  const { text } = useInternationalization()
  const router = useRouter()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const projectStateConfig = useProjectState()
  const isKeyboardOpen = useDetectKeyboardOpen()
  const { isMobile } = useSizes()

  const [name, setName] = useState(playerConfig.prog?.name || '')
  const [isNameSaving, setIsNameSaving] = useState(false)
  const [tempBpm, setTempBpm] = useState(playerConfig.bpm.toString())

  const inputRef = useRef<HTMLInputElement>(null)
  const [projectCreatedPopupOpen, setProjectCreatedPopupOpen] = useState(false)

  const STRINGS = text.mobileTabs.settings

  useEffect(() => {
    // @ts-ignore
    if (isMobile && !isKeyboardOpen && document.activeElement?.blur) {
      // @ts-ignore
      document.activeElement?.blur()
    }
  }, [isKeyboardOpen])

  useEffect(() => {
    setTimeout(() => {
      if (playerConfig.bpm !== +tempBpm) setTempBpm(playerConfig.bpm.toString())
    }, 300)
  }, [playerConfig.bpm])

  useEffect(() => {
    const newName = playerConfig.prog?.name
    if (newName && newName !== name) setName(newName)
  }, [playerConfig.prog?.name])

  const handleProjectSaveLimited = useLimitedRequest(
    async (name: string) => {
      if (!playerConfig.prog) {
        return
      }
      const id = await playerConfigSetter.saveProject(name)
      await router.push(
        {
          pathname: router.pathname,
          query: { id },
        },
        undefined,
        { shallow: true },
      )
      setProjectCreatedPopupOpen(true)
      playerConfigSetter.setProg({
        ...playerConfig.prog,
        name,
      })
    },
    false,
    LimitTypesLabel.projects,
    'project',
    () => {},
  )

  const handleSaveProjectName = async (name: string) => {
    setIsNameSaving(true)
    if (!projectStateConfig.editedProjectId) {
      playerConfig.player.pause()
      hjEvent('event', 'save_project')
      await handleProjectSaveLimited(name)
    } else {
      if (!playerConfig.prog) {
        return
      }
      playerConfigSetter.setProg({
        ...playerConfig.prog,
        name,
      })
    }
    setIsNameSaving(false)
  }

  const setTonalityKeyScale = async (tonalityKey: string, tonalityScale: string) => {
    if (!playerConfig.prog) return

    playerConfig.player.reset()

    playerConfigSetter.setIsProgLoading(true)
    playerConfigSetter.setIsMelodyLoading(true)

    const newProg = await transposeProgressionInnerRoute({
      prog: playerConfig.prog,
      tonalityKey,
      tonalityScale,
    })

    playerConfigSetter.setProg({ ...newProg, key: tonalityKey, scale: tonalityScale })
    playerConfigSetter.setMelodyPianorollNotes(getActivePart(newProg, playerConfig.currentPartId)?.melody?.notes)
    playerConfigSetter.setIsProgLoading(false)
    playerConfigSetter.setIsMelodyLoading(false)
  }

  return (
    <>
      <div>
        <div className={styles.settingsName}>{STRINGS.nameInputTitle}</div>
        <div className={styles.nameSettings}>
          <input
            type='text'
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder={STRINGS.nameInputPlaceholder}
          />
          <div
            className={classNames(styles.save, {
              [styles.disabled]: name === playerConfig.prog?.name || isNameSaving,
            })}
            onClick={() => {
              if (name !== playerConfig.prog?.name && !isNameSaving) handleSaveProjectName(name)
            }}
          >
            {STRINGS.nameInputSubmitBtn}
          </div>
        </div>
      </div>

      <div className={styles.bpmSettings}>
        <div
          className={classNames(styles.btn, { [styles.active]: playerConfig.bpm > 60 })}
          onClick={() => {
            if (playerConfig.bpm === 60) return
            playerConfigSetter.setBpm(playerConfig.bpm - 1)
            setTempBpm((v) => String(+v - 1))
          }}
        >
          <Minus />
        </div>

        <div className={styles.value}>
          <input
            className={styles.input}
            type='number'
            value={tempBpm}
            ref={inputRef}
            inputMode='numeric'
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                inputRef.current?.blur()
              }
            }}
            onChange={(e) => setTempBpm(e.target.value?.slice(0, 3) || '')}
            onBlur={() => {
              const finalValue = Math.max(60, Math.min(240, Math.round(Number(tempBpm))))
              playerConfigSetter.setBpm(finalValue)
              setTempBpm(finalValue.toString())
            }}
          />
          <div className={styles.text}>{STRINGS.tapBpm}</div>
        </div>

        <div
          className={classNames(styles.btn, { [styles.active]: playerConfig.bpm < 240 })}
          onClick={() => {
            if (playerConfig.bpm === 240) return
            playerConfigSetter.setBpm(playerConfig.bpm + 1)
            setTempBpm((v) => String(+v + 1))
          }}
        >
          <Plus />
        </div>
      </div>

      <div>
        <ChordKeyMenu
          chordKey={playerConfig.prog?.key || ''}
          chordScale={playerConfig.prog?.scale || ''}
          setKeyScale={setTonalityKeyScale}
          footerMode
        />
      </div>

      <Popup
        open={projectCreatedPopupOpen}
        onClose={() => setProjectCreatedPopupOpen(false)}
        level='success'
        text='Project was saved'
      />
    </>
  )
}

export default SettingsTab
