import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react'

import HandleIcon from '../../../../assets/icons/handle.svg'
import ArrowIcon from '../../../../assets/icons/simple-arrow.svg'
import { useProjectState } from '../../../../context/ProjectStateContext'
import useElementSize from '../../../../hooks/useElementSize'
import useSizes from '../../../../hooks/useSizes'
import { ProgPart } from '../../../../utils/types'
import Tooltip from '../../../common/Tooltip/Tooltip'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import PartsMenuItemDropdown from '../PartsMenuItemDropdown/PartsMenuItemDropdown'
import styles from './PartsMenuItem.module.scss'
import PartsMenuItemProgress from './PartsMenuItemProgress'

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
})

const STRINGS = {
  partNameTooltip: 'Part Name',
  loopsTooltip: 'Loops',
}

type Props = {
  item: ProgPart
  active?: boolean
  mobileMain?: boolean
  mobileMainOpened?: boolean
  dragging?: boolean
  smallMode?: boolean
  highlight?: boolean
  dragDisabled?: boolean
  disabled?: boolean
  onClick?: () => void
  width?: number
  duplicateDisabled?: boolean
  onEditName?: () => void
  setTabWidth?: (a: number) => void
}

const PartsMenuItem: React.FC<Props> = ({
  item,
  active,
  disabled,
  onClick,
  onEditName,
  setTabWidth,
  mobileMain,
  mobileMainOpened,
  dragDisabled,
  duplicateDisabled,
  dragging,
  highlight,
  smallMode,
  width: staticWidth,
}) => {
  const [menuOpened, setMenuOpened] = useState(false)
  const [dropdownOpened, setDropdownOpened] = useState(false)
  const projectConfig = useProjectState()
  const { isMobile } = useSizes()
  const [setRef, , { rect }] = useElementSize()
  useEffect(() => {
    setTabWidth && setTabWidth((rect?.width || 0) - 3)
  }, [rect?.width])
  const { attributes, isDragging, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id: item?.id || -1,
  })
  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef],
  )
  const { playerConfig } = usePlayerConfigState()
  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
    width: staticWidth,
  }
  const renderMainMenu = () =>
    !isDragging || !isMobile ? (
      <PartsMenuItemDropdown
        item={item}
        onEditName={onEditName}
        dropDownTriggerClassName={styles.dropDownTrigger}
        setMenuOpened={(v) => {
          setMenuOpened(v)
          setDropdownOpened(v)
        }}
        duplicateDisabled={duplicateDisabled}
        menuOpened={dropdownOpened}
      />
    ) : null
  const renderHelpMenu = () =>
    (!isDragging || !isMobile) && !item?.draft ? (
      <PartsMenuItemDropdown
        disabled={disabled}
        item={item}
        isShort
        dropDownTriggerClassName={styles.loopsCounter}
        setMenuOpened={setMenuOpened}
      />
    ) : (
      <div />
    )
  const itemTooltipId = `part-menu-${item?.id}-${smallMode}`
  return (
    // @ts-ignore
    <SortableItemContext.Provider value={context}>
      <div
        ref={setNodeRef}
        style={style}
        className={classNames(styles.wrapper, {
          [styles.disabled]: disabled,
          [styles.dragging]: dragging,
          [styles.mobileMain]: mobileMain,
        })}
        onContextMenu={(e) => {
          e.preventDefault()
          e.stopPropagation()

          setDropdownOpened(true)
        }}
      >
        <div
          ref={setRef}
          className={classNames(styles.container, {
            [styles.active]: active,
            [styles.preview]: isDragging,
            [styles.highlight]: highlight,
            [styles.dragging]: dragging,
            [styles.mobileMain]: mobileMain,
            [styles.mobileMainOpened]: mobileMainOpened,
            [styles.smallMode]: smallMode,
            [styles.menuOpened]: menuOpened,
            [styles.looped]: item?.id === playerConfig.isOnRepeat,
          })}
          onClick={onClick}
        >
          {!isMobile || (mobileMain && !projectConfig.projectEmpty) ? <PartsMenuItemProgress part={item} /> : null}
          <DragHandle disable={mobileMain || dragDisabled} id={smallMode ? itemTooltipId : undefined}>
            <>
              {!smallMode && renderHelpMenu()}
              <div className={styles.content}>
                <span data-tooltip-id={smallMode ? undefined : itemTooltipId}>{item?.name}</span>
                {!dragDisabled && (
                  <div className={styles.dragIcon}>
                    <HandleIcon />
                  </div>
                )}
              </div>
              {!isMobile && (
                <Tooltip id={itemTooltipId} place='top' delayShow={smallMode ? undefined : 1000}>
                  <div className={styles.tooltip}>
                    <p>
                      <span>{STRINGS.partNameTooltip}: </span>
                      {item?.name}
                    </p>
                    <p>
                      <span>{STRINGS.loopsTooltip}: </span>
                      {item?.loops}
                    </p>
                  </div>
                </Tooltip>
              )}
              {!smallMode ? (
                <div className={styles.dropDownWrapper}>
                  {mobileMain ? (
                    <div className={styles.expandIcon} data-open={mobileMainOpened}>
                      <ArrowIcon />
                    </div>
                  ) : (
                    renderMainMenu()
                  )}
                </div>
              ) : (
                <div className={styles.smallModeMenu}>
                  <div className={styles.dropDownWrapper}>{renderMainMenu()}</div>
                </div>
              )}
            </>
          </DragHandle>
        </div>
      </div>
    </SortableItemContext.Provider>
  )
}

function DragHandle({ children, disable, id }: { children: JSX.Element; disable?: boolean; id?: string }) {
  const { attributes, listeners, ref } = useContext(SortableItemContext)
  const { isMobile } = useSizes()
  const dragProps = {
    ...(disable ? {} : attributes),
    ...(disable ? {} : listeners),
    ref: disable ? undefined : ref,
  }
  if (isMobile) {
    return (
      <div className={styles.dragElement} onKeyDown={(e) => e.preventDefault()}>
        <div {...dragProps} className={styles.dragElementMobile} />
        {children}
      </div>
    )
  }
  return (
    <div {...dragProps} className={styles.dragElement} onKeyDown={(e) => e.preventDefault()}>
      {id ? <div data-tooltip-id={id} className={styles.smallModeBg} /> : null}
      {children}
    </div>
  )
}

export default PartsMenuItem
