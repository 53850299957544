import React, { FC, useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useLocalStorage } from 'usehooks-ts'

import {
  getChordEditorAiSuggestionChordsInnerRoute,
  getChordEditorInKeyChordsInnerRoute,
  getChordEditorSearchChordsInnerRoute,
} from '../../../../api/chord-editor'
import { useLimits } from '../../../../context/LimitsContext'
import useDebounce from '../../../../hooks/useDebounce'
import { isChordInKeyScale } from '../../../../utils/chord-editor'
import { Prog } from '../../../../utils/types'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import { chordEditorLocalStorageKeys } from '../data'
import { allChordPaletteOptions } from './ChordPalette'

const inKeyChordsQueryKey = 'in-key-chords'
const searchChordsQueryKey = 'search-chords'
const aiSuggestionChordsQueryKey = 'ai-suggestion-chords'

export type TChordEditorQueryConfig = {
  data: any
  isLoading: boolean
  error: any
}
type Props = {
  selectedChordMenu: string

  inKeyChordsConfig: TChordEditorQueryConfig | null
  setInKeyChordsConfig: (v: TChordEditorQueryConfig) => void

  searchChordsConfig: TChordEditorQueryConfig | null
  setSearchChordsConfig: (v: TChordEditorQueryConfig) => void

  aiSuggestionChordsConfig: TChordEditorQueryConfig | null
  setAiSuggestionChordsConfig: (v: TChordEditorQueryConfig) => void
}

const ChordQuerySetup: FC<Props> = ({
  selectedChordMenu,
  inKeyChordsConfig,
  setInKeyChordsConfig,
  searchChordsConfig,
  setSearchChordsConfig,
  aiSuggestionChordsConfig,
  setAiSuggestionChordsConfig,
}) => {
  const queryClient = useQueryClient()
  const {
    playerConfig: { prog, tonalityKey, addChordMode, activeChord, currentPart, chordGenre, tonalityScale },
  } = usePlayerConfigState()
  const { isNotSubscribed } = useLimits()

  const [inKeyChordKey] = useLocalStorage(chordEditorLocalStorageKeys.inKeyChordKey, '')
  const [inKeyChordScale] = useLocalStorage(chordEditorLocalStorageKeys.inKeyChordScale, '')

  const [searchStr] = useLocalStorage(chordEditorLocalStorageKeys.searchChord, '')
  const debouncedSearchStr = useDebounce(searchStr)

  // FIX
  const open = true

  const suggestionId = addChordMode ? 0 : activeChord?.id || 0
  const suggestionIndex = suggestionId
    ? currentPart.chords.findIndex((c) => c.id === suggestionId)
    : currentPart.chords.flatMap((c) => c.midi).join()
  const queryKeys = [suggestionIndex, suggestionId, addChordMode]

  // IN KEY

  const inKeyChordsQueryKeys = [inKeyChordsQueryKey, inKeyChordKey, inKeyChordScale, tonalityKey, tonalityScale]
  const cachedInKeyChords = queryClient.getQueryData(inKeyChordsQueryKeys)
  const inKeyChords = useQuery(
    inKeyChordsQueryKeys,
    async () => {
      const allChordOptions = allChordPaletteOptions.map((option) => option.key)

      let newOriginalSuggestions = undefined
      let newOtherSuggestions = undefined

      newOriginalSuggestions = await getChordEditorInKeyChordsInnerRoute({
        diatonicChordTypes: allChordOptions,
        tonalityKey,
        tonalityScale,
      })
      if (inKeyChordKey && inKeyChordScale) {
        newOtherSuggestions = await getChordEditorInKeyChordsInnerRoute({
          diatonicChordTypes: allChordOptions,
          tonalityKey: inKeyChordKey,
          tonalityScale: inKeyChordScale.toLowerCase(),
        })
      }

      return {
        [`${tonalityKey}-${tonalityScale}`.toLowerCase()]: newOriginalSuggestions,
        [`${inKeyChordKey}-${inKeyChordScale}`.toLowerCase()]: newOtherSuggestions,
      }
    },
    { enabled: !!prog && open && selectedChordMenu === 'chords-in-key' && !cachedInKeyChords, retry: false },
  )

  useEffect(() => {
    const newInKeyChordsConfig = {
      data: cachedInKeyChords || inKeyChords.data || {},
      isLoading: inKeyChords.isLoading,
      error: inKeyChords.error,
    }

    if (JSON.stringify(inKeyChordsConfig) === JSON.stringify(newInKeyChordsConfig)) return

    setInKeyChordsConfig(newInKeyChordsConfig)
  }, [cachedInKeyChords, inKeyChords])

  // SEARCH

  const searchChordsQueryKeys = [searchChordsQueryKey, debouncedSearchStr, tonalityKey, tonalityScale]
  const cachedSearchChords = queryClient.getQueryData(searchChordsQueryKeys)
  const searchChords = useQuery(
    searchChordsQueryKeys,
    async () => {
      if (!tonalityKey || !tonalityScale) return []

      const newSuggestionsRaw = await getChordEditorSearchChordsInnerRoute({
        query: debouncedSearchStr,
        tonalityKey,
        tonalityScale,
      })
      const newSuggestions = newSuggestionsRaw.map((suggestion: any) => {
        const isBlocked = !suggestion.isTriad && isNotSubscribed
        const isInKey = isChordInKeyScale(suggestion.chord, tonalityKey, tonalityScale)

        return { ...suggestion.chord, isBlocked, isInKey }
      })

      return newSuggestions
    },
    { enabled: !!prog && open && selectedChordMenu === 'search' && !cachedSearchChords, retry: false },
  )

  useEffect(() => {
    const newSearchChordsConfig = {
      data: cachedSearchChords || searchChords.data || [],
      isLoading: searchChords.isLoading,
      error: searchChords.error,
    }
    if (JSON.stringify(searchChordsConfig) === JSON.stringify(newSearchChordsConfig)) return

    setSearchChordsConfig(newSearchChordsConfig)
  }, [cachedSearchChords, searchChords])

  // AI SUGGESTIONS

  const aiSuggestionChordsQueryKeys = [...queryKeys, aiSuggestionChordsQueryKey, tonalityKey, tonalityScale]
  const cachedAiSuggestionChords = queryClient.getQueryData(aiSuggestionChordsQueryKeys)
  const aiSuggestionChords = useQuery(
    aiSuggestionChordsQueryKeys,
    async () => {
      const chords = currentPart.chords
      const chordIndex = chords.findIndex((ch) => ch.id === suggestionId) || 0
      const isLimited = isNotSubscribed

      const newSuggestionsRaw = await getChordEditorAiSuggestionChordsInnerRoute({
        prog: prog as Prog,
        genre: chordGenre.key,
        chordIndex: chordIndex < 0 ? chords.length : chordIndex,
      })
      const newSuggestions = Object.fromEntries(
        Object.entries(newSuggestionsRaw).map(([name, chords]: any) => {
          if (name === 'oftenUsed') {
            return [
              name,
              chords.map((chord: any, index: number) => ({
                ...chord,
                isBlocked: isLimited && index >= 2,
              })),
            ]
          }

          return [name, chords.map((chord: any) => ({ ...chord, isBlocked: isLimited }))]
        }),
      )

      return newSuggestions
    },
    { enabled: !!prog && open && selectedChordMenu === 'ai-suggestions' && !cachedAiSuggestionChords, retry: false },
  )

  useEffect(() => {
    const newAiSuggestionChordsConfig = {
      data: cachedAiSuggestionChords || aiSuggestionChords.data || {},
      isLoading: aiSuggestionChords.isLoading,
      error: aiSuggestionChords.error,
    }
    if (JSON.stringify(aiSuggestionChordsConfig) === JSON.stringify(newAiSuggestionChordsConfig)) return

    setAiSuggestionChordsConfig(newAiSuggestionChordsConfig)
  }, [cachedAiSuggestionChords, aiSuggestionChords])

  //

  return <></>
}

export default ChordQuerySetup
