import classNames from 'classnames'
import React, { useMemo, useRef } from 'react'
import { useQuery } from 'react-query'
import { useSwipeable } from 'react-swipeable'

import { getUserPrefsInnerRoute } from '../../../../api/user-prefs'
import ArrowIcon from '../../../../assets/icons/simple-arrow.svg'
import useSizes from '../../../../hooks/useSizes'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import { getChordEditorHeight, getDrumsEditorHeight, getMelodyEditorHeight } from '../footer-utils'
import styles from './MenuControl.module.scss'

type Props = {
  disabled?: boolean
  type?: 'close-open' | 'height-change'
  onClose?: () => void
  setLocalPosition?: (a: number | null) => void
  heightPosition: number
  setHeightPosition?: (a: number) => void
}

const MenuControl = ({
  disabled,
  type = 'close-open',
  onClose = () => {},
  setLocalPosition = () => {},
  heightPosition,
  setHeightPosition = () => {},
}: Props) => {
  const { isTablet, isMobile, isSmallDesktop } = useSizes()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()

  const initialHeightRef = useRef<number>(0)

  const { data: prefs } = useQuery(['user-prefs'], getUserPrefsInnerRoute)

  const isCloseOpenMode = type === 'close-open' || isTablet
  const isHeightChangeMode = type === 'height-change' && !isTablet

  const isChordEditorMode = playerConfig.view.editorOpen
  const isMelodyEditorMode = playerConfig.view.melodyEditorOpen
  const isDrumsEditorMode = playerConfig.view.drumsEditorOpen

  const instrument = (() => {
    if (playerConfig.view.guitarOpen) return 'guitar'
    if (playerConfig.view.pianoOpen) return 'piano'
    return playerConfig.view.tempFavInstrument || prefs?.favouriteInstrument || 'piano'
  })()
  const instrumentOpen = playerConfig.view.notationOpen || playerConfig.view.guitarOpen || playerConfig.view.pianoOpen
  const menuClosed =
    !instrumentOpen &&
    !playerConfig.view.editorOpen &&
    !playerConfig.view.layersOpen &&
    !playerConfig.view.drumsEditorOpen &&
    !playerConfig.currentPart?.draft &&
    !playerConfig.view.melodyEditorOpen

  const minHeightPosition = useMemo(() => {
    if (isChordEditorMode) return -1 * getChordEditorHeight(true, 0, { isMobile, isSmallDesktop })
    if (isMelodyEditorMode) return -1 * getMelodyEditorHeight(true, 0, { isMobile, isSmallDesktop })
    if (isDrumsEditorMode) return -1 * getDrumsEditorHeight(true, 0, { isMobile, isSmallDesktop })
    return 0
  }, [playerConfig.view, isMobile, isSmallDesktop, isMelodyEditorMode, isDrumsEditorMode])
  const maxHeightPosition = useMemo(() => {
    const topBorderElement = document.getElementById('edit-menu-trigger')?.getBoundingClientRect()
    const bottomBorderElement = document.getElementById('footer-bottom')
    if (!topBorderElement || !bottomBorderElement) return 0

    const topBorder = topBorderElement.top + topBorderElement.height
    const initialHeight = -1 * minHeightPosition
    const bottomBorder = bottomBorderElement.clientHeight

    const windowHeight = window.innerHeight

    return windowHeight - topBorder - initialHeight - bottomBorder - 1
  }, [minHeightPosition, playerConfig.view, isMobile, isSmallDesktop, isMelodyEditorMode, isDrumsEditorMode])
  const maxNotClosePosition = useMemo(() => minHeightPosition / 4, [minHeightPosition])

  const isHeightChangeCloseMode = heightPosition < maxNotClosePosition

  const updateHightPosition = (newPosition: number) => {
    setHeightPosition(Math.max(minHeightPosition, Math.min(maxHeightPosition, newPosition)))
  }

  const tabletHandlers = useSwipeable({
    onSwipeStart: () => {
      if (isHeightChangeMode) {
        document.body.classList.add('ns-resize')
        initialHeightRef.current = heightPosition
      }
    },
    onSwiping: (e) => {
      if (isCloseOpenMode) setLocalPosition(e.deltaY)
      if (isHeightChangeMode) {
        if (initialHeightRef.current === 1) return

        updateHightPosition(initialHeightRef.current - e.deltaY)
      }
    },
    onSwipedDown: (e) => {
      if (isCloseOpenMode) {
        playerConfigSetter.setView()

        if (e.deltaY > 200 || (!playerConfig.view.editorOpen && !playerConfig.view.layersOpen) || !instrumentOpen) {
          onClose()
        }
      }
    },
    onSwipedUp: () => {
      if (isCloseOpenMode) {
        if (!isMobile && (playerConfig.view.drumsEditorOpen || playerConfig.view.melodyEditorOpen)) {
          return
        }
        if (instrumentOpen) {
          return
        }
        playerConfigSetter.setView({
          pianoOpen: instrument === 'piano',
          guitarOpen: instrument === 'guitar',
        })
      }
    },
    onSwiped: () => {
      if (isCloseOpenMode) setLocalPosition(null)
      if (isHeightChangeMode) {
        if (heightPosition < maxNotClosePosition) onClose()
        if (heightPosition < 0) setHeightPosition(0)

        document.body.classList.remove('ns-resize')
        initialHeightRef.current = -1
      }
    },
    trackMouse: true,
  })
  const desktopHandlers = useSwipeable({
    onSwipeStart: () => {
      if (isHeightChangeMode) {
        document.body.classList.add('ns-resize')
        initialHeightRef.current = heightPosition
      }
    },
    onSwiping: (e) => {
      if (isHeightChangeMode) {
        if (initialHeightRef.current === 1) return

        updateHightPosition(initialHeightRef.current - e.deltaY)
      }
    },
    onSwiped: () => {
      if (isHeightChangeMode) {
        if (heightPosition < maxNotClosePosition) playerConfigSetter.setView()
        if (heightPosition < 0) setHeightPosition(0)

        document.body.classList.remove('ns-resize')
        initialHeightRef.current = -1
      }
    },
    trackMouse: true,
  })

  if (isTablet) {
    return (
      <div className={styles.mobileControl} {...(disabled ? {} : tabletHandlers)}>
        <div>{!disabled && <div />}</div>
      </div>
    )
  }

  if (disabled) return null

  return (
    <>
      {!menuClosed ? (
        <svg
          width='100'
          height='19'
          className={styles.closeBtn}
          onClick={() => playerConfigSetter.setView()}
          viewBox='0 0 100 19'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <g clipPath='url(#clip0_2097_205981)'>
            <g clipPath='url(#clip1_2097_205981)'>
              <path
                d='M97.9412 19.6346L98 22H-35V19H-34.3548C-29.4857 18.9983 -21.4331 17.6238 -16.5768 12.343C-16.3309 12.0757 -16.0892 11.8121 -15.8509 11.5523L-15.7684 11.4624C-12.9552 8.3946 -15.8775 5.76155 -13.0097 3.94231C-10.3646 2.26426 -19.6625 1.30376 -15.5086 1.05833L2.83621 1.0159V1H59.5268C64.6511 1 68.1895 2.0056 71.2048 3.86354C74.1676 5.68921 76.5865 8.31895 79.4664 11.4594L79.5523 11.553C79.7905 11.8128 80.032 12.076 80.2775 12.343C85.1155 17.6039 93.0668 19.6228 97.9412 19.6346Z'
                fill='#1D1E21'
                stroke='#333438'
                strokeWidth='1.2'
              />
            </g>
            <path d='M39.25 6.75L28 18' stroke='#8B8C8E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
            <path d='M28 6.75L39.25 18' stroke='#8B8C8E' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
          </g>
          <defs>
            <clipPath id='clip0_2097_205981'>
              <rect width='100' height='19' fill='white' />
            </clipPath>
            <clipPath id='clip1_2097_205981'>
              <rect width='133' height='19' fill='white' transform='translate(-35)' />
            </clipPath>
          </defs>
        </svg>
      ) : null}

      <div
        className={classNames(styles.openArrow, { [styles.heightChangeMode]: isHeightChangeMode })}
        data-open={menuClosed}
        onClick={() => {
          if (menuClosed) {
            playerConfigSetter.setView({ editorOpen: true })
            return
          }
          if (isCloseOpenMode) playerConfigSetter.setView()
        }}
        {...desktopHandlers}
      >
        <svg
          width='122'
          height='19'
          viewBox='0 0 122 19'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className={styles.bg}
        >
          <path d='M126.236 19.1184V22.1774H-6.82324V19.1186H-6.17774C-1.30653 19.1169 6.74971 17.7153 11.6081 12.331C11.8541 12.0585 12.0959 11.7898 12.3343 11.5249L12.4169 11.4331C15.2313 8.30521 17.6057 5.67398 20.4748 3.81906C23.1211 2.10811 26.1553 1.07535 30.311 0.825104L31.0297 0.781837V0.765625H87.7453C92.8719 0.765625 96.4119 1.79094 99.4285 3.68532C102.393 5.54678 104.813 8.22808 107.694 11.4301L107.78 11.5255C108.018 11.7904 108.26 12.0588 108.505 12.331C113.345 17.6951 121.359 19.1063 126.236 19.1184Z' />
        </svg>

        {(isCloseOpenMode || isHeightChangeCloseMode) && <ArrowIcon className={styles.arrow} />}
        {isHeightChangeMode && !isHeightChangeCloseMode && <div className={styles.handle} />}
      </div>
    </>
  )
}

export default MenuControl
