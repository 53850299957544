import classNames from 'classnames'
import deepEqual from 'deep-equal'
import React, { useEffect, useRef, useState } from 'react'

import { chordVoicingByLevelInnerRoute } from '../../../../api/chords'
import SaveIcon from '../../../../assets/icons/arrow-pricing.svg'
import DiceIcon from '../../../../assets/icons/dice.svg'
import EditIcon from '../../../../assets/icons/pencil.svg'
import TrashIcon from '../../../../assets/icons/trash.svg'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import useLimitedRequest from '../../../../hooks/useLimitedRequest'
import { LimitTypesLabel } from '../../../../utils/limits'
import { Chord } from '../../../../utils/types'
import { useEditMenu } from '../../hooks/useEditMenu'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './ChordVoicings.module.scss'

type Props = {
  voicings: Array<{ midi: number[]; name: string }>
  selectedMidi: number[]
  chord: Chord
}

const ChordVoicings: React.FC<Props> = ({ selectedMidi, voicings, chord }) => {
  const { text } = useInternationalization()
  const { editingVoicing, setEditingVoicing } = useEditMenu()
  const {
    playerConfig: { view, currentPartId, isPlaying },
    playerConfigSetter: { handleSaveChordVoicings, handleSaveChordMidi, setView },
  } = usePlayerConfigState()

  const [loading, setLoading] = useState(false)

  const editingVoicingRef = useRef<any>(null)

  const STRINGS = text.footer.chordEditor.voicings
  editingVoicingRef.current = editingVoicing

  const getVoicingWithOctave = (voicing: any, delta: 1 | -1) => {
    if (!voicing) return null
    return { ...voicing, midi: voicing.midi.map((m: any) => m + (chord.octave || 0) * delta * 12) }
  }
  const handleLoadNewRandomVoicing = async (chord: Chord) => {
    if (loading) return
    const voicing = await chordVoicingByLevelInnerRoute({ chord })
    handleSaveChordVoicings([...voicings, voicing], voicing.midi)
  }
  const handleSaveEditedVoicing = () => {
    const newVoicing = getVoicingWithOctave(editingVoicingRef.current, -1)
    if (!newVoicing) return

    delete newVoicing.defaultMidi

    handleSaveChordVoicings(
      voicings.map((item) => {
        if (item.name === newVoicing.name) return newVoicing
        return item
      }),
      newVoicing.midi,
    )
    setEditingVoicing(null)
  }

  const limitedRequest = useLimitedRequest(handleLoadNewRandomVoicing, false, LimitTypesLabel.voicings, 'voicing')

  useEffect(() => {
    if (voicings.length && voicings.every((v) => !deepEqual(v.midi, selectedMidi))) {
      handleSaveChordMidi(voicings[0].midi)
    }
  }, [voicings])
  useEffect(() => {
    handleSaveEditedVoicing()
    return () => handleSaveEditedVoicing()
  }, [
    // play start
    isPlaying,
    // chord octave change
    chord.octave,
    // on part change
    currentPartId,
    // on chord change
    chord.id,
    // on chord editor closing
    view.editorOpen,
    // if new voicing was created and then instantly edited
    chord.voicings.length,
  ])

  return (
    <div className={styles.container}>
      {voicings.map((v) => {
        const isActive = deepEqual(v.midi, selectedMidi)
        const isDefault = v.name === 'Default'
        const isEditing = editingVoicing?.name === v.name
        const isDisabled = editingVoicing && !isEditing

        return (
          <div
            key={v.name}
            className={classNames(styles.tileContainer, { [styles.disabled]: isDisabled })}
            onClick={() => {
              if (isDisabled) return
              handleSaveChordMidi(v.midi)
            }}
            data-active={isActive}
            data-default={isDefault}
          >
            {voicings.length > 1 && !isDefault && (
              <div
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isDisabled) return

                  if (isEditing) {
                    handleSaveEditedVoicing()
                  } else {
                    setView({ editorOpen: true, chordEditMode: 'voicings', pianoOpen: true })
                    handleSaveChordMidi(v.midi)
                    setEditingVoicing({ ...getVoicingWithOctave(v, 1), defaultMidi: voicings[0].midi })
                  }
                }}
              >
                {isEditing ? <SaveIcon /> : <EditIcon />}
              </div>
            )}
            <div className={styles.tile}>{v.name}</div>
            {voicings.length > 1 && !isDefault && (
              <div
                className={styles.btn}
                onClick={(e) => {
                  e.stopPropagation()
                  if (isDisabled) return

                  handleSaveChordVoicings(voicings.filter((item) => item.name !== v.name))
                  setEditingVoicing(null)
                }}
              >
                <TrashIcon />
              </div>
            )}
          </div>
        )
      })}

      {voicings.length < 8 && (
        <button
          className={classNames(styles.addNewTile, { [styles.disabled]: editingVoicing })}
          data-loading={loading}
          onClick={async () => {
            if (editingVoicing) return

            setLoading(true)
            await limitedRequest(chord)
            setLoading(false)
          }}
        >
          <DiceIcon />
          <span>{STRINGS.add}</span>
        </button>
      )}
      
      {new Array(Math.max(0, 7 - voicings.length)).fill(0).map((_, index) => (
        <div className={styles.emptyTile} key={index} />
      ))}
    </div>
  )
}

export default ChordVoicings
