import React, { useRef } from 'react'

import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../hooks/useSizes'
import { TMenuOption, useEditMenu } from '../../editor/hooks/useEditMenu'
import styles from './EditMenu.module.scss'

const EditMenuMobile = () => {
  const { isTablet } = useSizes()
  const { mobileMenuShown, activeMenuOptions, handleHideMenu } = useEditMenu()

  const menuRef = useRef<HTMLDivElement>(null)

  useClickOutsideAlerter(menuRef, handleHideMenu, clickOutsideHookIds.editMenu)

  const renderOption = (option: TMenuOption) => {
    const { icon, action } = option

    return (
      <div className={styles.option} onClick={() => action()}>
        {icon}
      </div>
    )
  }

  if (!isTablet || !mobileMenuShown) return null

  return (
    <div ref={menuRef} className={styles.mobileContainer}>
      {activeMenuOptions.map((option) => renderOption(option))}
    </div>
  )
}

export default EditMenuMobile
