import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { InstrumentType } from '../../LayersOfInstruments/LayersOfInstruments'
import DrumsPlaceholder from '../../TimelineWrapper/DrumsPlaceholder/DrumsPlaceholder'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './TimelineTile.module.scss'

type Props = {
  getWidth: () => number | string
  formatWidth: (a: number) => number
  minWidth: number
  getSnappPosition: (width: number, increase: number) => number
  type?: InstrumentType
}

const TimelineDrum = ({ minWidth, formatWidth, getWidth, getSnappPosition, type = 'DRUMS' }: Props) => {
  const {
    playerConfig: { isProgLoading, isMelodyLoading, isDrumsPatternLoading, isGenerateMelody, isGenerateDrums },
    playerConfigSetter: { setMelodyWidth, setDrumWidth },
  } = usePlayerConfigState()

  const resizerRef = useRef(null)

  const [mousePos, setMousePos] = useState<null | number>(null)
  const [touchPos, setTouchPos] = useState<null | number>(null)
  const [manual, setManual] = useState<null | number>(null)

  const isMelodyMode = type === 'MELODY'
  const isDrumMode = type === 'DRUMS'

  const isSmallView = +getWidth() < 100
  const width = manual ? manual + +getWidth() : getWidth()
  const isLoading = isMelodyMode ? isMelodyLoading : isDrumsPatternLoading

  useEffect(() => {
    if (!resizerRef.current) return

    const wrap: HTMLDivElement = resizerRef.current
    wrap.addEventListener('touchstart', startResizeTouch)

    return () => {
      wrap.removeEventListener('touchstart', startResizeTouch)
    }
  })
  useEffect(() => {
    if (touchPos) {
      document.body.addEventListener('touchmove', resizeTouch)
    }

    return () => {
      document.body.removeEventListener('touchmove', resizeTouch)
    }
  }, [touchPos])
  useEffect(() => {
    if (manual) document.body.addEventListener('touchend', endTouch, { once: true })

    return () => document.body.removeEventListener('touchend', endTouch)
  }, [manual])

  useEffect(() => {
    if (mousePos) {
      document.body.addEventListener('mouseup', endMouse, { once: true })
      document.body.addEventListener('mousemove', resizeMouse)
    }

    return () => {
      document.body.removeEventListener('mouseup', endMouse)
      document.body.removeEventListener('mousemove', resizeMouse)
    }
  }, [mousePos])

  const setWidth = (width: number) => {
    const newWidth = Math.max(1, formatWidth(width))

    if (isMelodyMode) setMelodyWidth(newWidth)
    if (isDrumMode) setDrumWidth(newWidth)
  }

  const startResizeMouse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setMousePos(e.screenX)
  }
  const resizeMouse = (e: MouseEvent) => {
    if (!mousePos) return

    const increase = Math.round(e.screenX - mousePos)
    const snappIncrease = getSnappPosition(+getWidth(), increase)

    if (snappIncrease + +getWidth() < minWidth) {
      setManual(minWidth - +getWidth())
    } else {
      setManual(snappIncrease)
    }
  }
  const endMouse = (e: MouseEvent) => {
    if (!mousePos) return

    const increase = Math.round(e.screenX - mousePos)
    const snappIncrease = getSnappPosition(+getWidth(), increase)

    setWidth(+getWidth() + snappIncrease)

    setMousePos(null)
    setManual(null)
  }

  const startResizeTouch = (e: TouchEvent) => {
    if (e.touches.length != 1) return

    e.preventDefault()

    setTouchPos(e.touches[0].clientX)
  }
  const resizeTouch = (e: TouchEvent) => {
    if (!touchPos || e.touches.length !== 1) return

    const increase = Math.round(e.touches[0].clientX - touchPos)
    const snappIncrease = getSnappPosition(+getWidth(), increase)

    if (snappIncrease + +getWidth() < minWidth) {
      setManual(minWidth - +getWidth())
    } else {
      setManual(snappIncrease)
    }
  }
  const endTouch = () => {
    document.body.removeEventListener('touchmove', resizeTouch)
    document.body.removeEventListener('touchend', endTouch)

    setWidth(manual! + +getWidth())

    setManual(null)
    setTouchPos(null)
  }

  return (
    <div
      className={classNames(styles.tile, styles.roundedLeft, styles.roundedRight, styles.drum, {
        [styles.small]: isSmallView,
        // [styles.hoveredMelody]: hoverChords && isGenerateMelody,
        [styles.loading]: isProgLoading && (isMelodyMode ? isGenerateMelody : isGenerateDrums),
      })}
      data-loading={isLoading}
      style={{ width: typeof width === 'string' ? width : Math.max(minWidth, width) }}
    >
      <div
        className={styles.container}
        style={{
          overflow: 'visible',
        }}
      >
        <DrumsPlaceholder
          width={typeof width === 'string' ? undefined : formatWidth(width)}
          dragProps={{
            ref: resizerRef,
            onMouseDown: startResizeMouse,
          }}
          type={type}
        />
      </div>
    </div>
  )
}

export default TimelineDrum
