import classNames from 'classnames'
import React, { useEffect, useImperativeHandle, useRef } from 'react'

import useSizes from '../../../../hooks/useSizes'
import useWindowDimensions from '../../../../hooks/useWIndowDimentions'
import { TILE_HEIGHT, TILE_HEIGHT_MOBILE, TILE_HEIGHT_SMALL } from '../../../../utils/constants'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from '../Timeline.module.scss'

interface Props {
  containerRef: React.RefObject<HTMLDivElement>
  chromeRef: React.RefObject<HTMLDivElement>
  pixelsPerBeat: number
  isRenderOnboarding: boolean
  setIncrement: (a: number) => void
  scale?: number
  timelineRef: React.RefObject<HTMLDivElement>
}

const numberMarkupSchema: { [key: number]: string } = {
  0: '',
  4: '.2',
  8: '.3',
  12: '.4',
}

const TimelineGrid = React.forwardRef(
  ({ containerRef, chromeRef, pixelsPerBeat, isRenderOnboarding, setIncrement, scale, timelineRef }: Props, ref) => {
    const { isMobile, isSmallDesktop } = useSizes()
    const gridPrimary = '#2E2F30'
    const gridSecondary = '#7F7F7F'

    const breakpointForQuaterLines = 150
    const breakpointForQuaterNumber = 250
    const breakpointForSixteensLines = 250

    const gridRef = useRef<HTMLCanvasElement>(null)
    const requestRef = useRef<number>()

    const { playerConfig } = usePlayerConfigState()
    const { width: windowWidth } = useWindowDimensions()

    useImperativeHandle(ref, () => ({
      // we dont need to trigger redrawing on mobile as we do it every frame
      performRedrawFrame: isMobile ? () => {} : performRedrawFrame,
    }))

    const animate = () => {
      performRedrawFrame({ scroll: timelineRef.current!.scrollLeft, scale: scale as number })
      requestRef.current = requestAnimationFrame(animate)
    }
    useEffect(() => {
      if (!isMobile) {
        return
      }
      requestRef.current = requestAnimationFrame(animate)
      return () => {
        if (requestRef.current === undefined) {
          return
        }
        cancelAnimationFrame(requestRef.current)
      }
    }, [scale, windowWidth, playerConfig.prog, playerConfig.currentPartId])

    return (
      <div className={styles.gridContainer}>
        <canvas ref={gridRef} className={classNames(styles.grid, { [styles.draft]: isRenderOnboarding })} />
      </div>
    )

    function performRedrawFrame({ scroll, scale }: { scroll: number; scale: number }) {
      const canvas: HTMLCanvasElement = gridRef.current!
      if (!canvas) {
        return
      }
      const context = canvas.getContext('2d')!

      hidpi(canvas)

      drawGrid(context, { scroll, scale })
    }

    function drawGrid(context: CanvasRenderingContext2D, { scroll, scale }: { scroll: number; scale: number }) {
      if (!containerRef.current) return

      const { width, height } = context.canvas.getBoundingClientRect()
      const { left } = containerRef.current.getBoundingClientRect()

      const tileHeight = (() => {
        if (isMobile) return TILE_HEIGHT_MOBILE + 1
        if (isSmallDesktop) return TILE_HEIGHT_SMALL + 1
        return TILE_HEIGHT + 1
      })()

      const mobileOffset = (windowWidth || 0) / 2 - left + -2
      const desktopOffset = 8
      const offSet = -scroll + (isMobile ? mobileOffset : desktopOffset)

      const actualPixels = pixelsPerBeat * scale
      const smallVertivalLineHeightOffset = tileHeight * 0.3
      const bigVertivalLineHeightOffset = tileHeight * 0.2

      const topOffset = 4
      const topLineY = 40 + topOffset
      const topTextY = 30
      const _beatMeasureHeight = 10
      const _barMeasureHeight = 4

      // Render functions
      const renderRect = (x: number, y: number, w: number, h: number) => {
        context.fillRect(x, y, w, h)
      }
      const renderText = (text: string, x: number, y: number) => {
        context.fillText(text, x, y)
      }
      const renderHorizontalLine = (row: number) => {
        context.fillStyle = gridPrimary

        const x = -3
        const y = tileHeight * row - topOffset * +!row + topLineY
        const w = width + 3
        const h = 1

        renderRect(x, y, w, h)
      }
      const renderSmallVerticalLine = (i: number, j: number, row: number) => {
        context.fillStyle = gridPrimary

        const x = offSet + i * actualPixels + (j / 16) * actualPixels
        const y = tileHeight * row + topLineY + smallVertivalLineHeightOffset
        const w = 1
        const h = tileHeight - 2 * smallVertivalLineHeightOffset

        renderRect(x, y, w, h)
      }
      const renderBigVerticalLine = (i: number, row: number) => {
        context.fillStyle = gridPrimary

        const x = offSet + i * actualPixels
        const y = tileHeight * row + topLineY + bigVertivalLineHeightOffset
        const w = 1
        const h = tileHeight - 2 * bigVertivalLineHeightOffset

        renderRect(x, y, w, h)
      }
      const renderNumberMarkup = (i: number, j = 0) => {
        context.fillStyle = gridSecondary

        const numberOffset = j ? 0 : i ? 10 : 6
        const x = offSet + i * actualPixels + (j / 16) * actualPixels
        const lineHeiht = j ? _barMeasureHeight : _beatMeasureHeight

        // line
        const lineX = x
        const lineY = topLineY - topOffset - lineHeiht
        const lineW = 1
        const lineH = lineHeiht

        renderRect(lineX, lineY, lineW, lineH)

        // number
        const text = i + 1 + numberMarkupSchema[j]
        const text_x = x + numberOffset
        const text_y = topTextY

        if (!text) return
        if (!j || actualPixels > breakpointForQuaterNumber) renderText(text.toString(), text_x, text_y)
      }

      // Canvas render
      context.clearRect(0, 0, width, height)

      context.textAlign = 'center'
      context.font = 'bold 12px Quicksand'

      // horizontal lines
      renderHorizontalLine(0)
      renderHorizontalLine(1)
      renderHorizontalLine(2)
      renderHorizontalLine(3)

      let incros = 1

      // vertical lines
      for (let i = 0; i < (chromeRef.current?.getBoundingClientRect()?.width || 0) / actualPixels; i++) {
        for (let j = 0; j < 16; j++) {
          const isBar = j === 0
          const isQuater = !isBar && j % 4 === 0
          const isSixteen = !isBar && !isQuater

          const showQuater = actualPixels > breakpointForQuaterLines
          const showSixteen = actualPixels > breakpointForSixteensLines

          let divisor = 0

          if (isBar) {
            divisor = 1

            renderNumberMarkup(i)

            renderBigVerticalLine(i, 0)
            renderBigVerticalLine(i, 1)
            renderBigVerticalLine(i, 2)
          }

          if (isQuater && showQuater) {
            divisor = 4

            renderNumberMarkup(i, j)

            renderSmallVerticalLine(i, j, 0)
            renderSmallVerticalLine(i, j, 1)
            renderSmallVerticalLine(i, j, 2)
          }

          if (isSixteen && showSixteen) {
            divisor = 16

            renderNumberMarkup(i, j)

            renderSmallVerticalLine(i, j, 0)
            renderSmallVerticalLine(i, j, 1)
            renderSmallVerticalLine(i, j, 2)
          }

          incros = Math.max(incros, divisor)
        }
      }

      setIncrement(incros)
    }
  },
)

function hidpi(canvas: HTMLCanvasElement) {
  const { width, height } = canvas.getBoundingClientRect()

  if (canvas.width !== width || canvas.height !== height) {
    const { devicePixelRatio: ratio = 1 } = window
    const context = canvas.getContext('2d')!
    canvas.width = width * ratio
    canvas.height = height * ratio
    context.scale(ratio, ratio)
    return true
  }

  return false
}

export default TimelineGrid
