import classNames from 'classnames'
import React, { useEffect, useMemo } from 'react'

// import AISuggestionWhite from '../../../../assets/icons/ai-suggestion-white.svg'
import CloneIcon from '../../../../assets/icons/clone.svg'
import PlusIcon from '../../../../assets/icons/plus.svg'
import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import { FRONTEND_LIMIT_TYPES, useLimits } from '../../../../context/LimitsContext'
import { useProjectState } from '../../../../context/ProjectStateContext'
import useElementSize from '../../../../hooks/useElementSize'
import useSizes from '../../../../hooks/useSizes'
import { copyObj } from '../../../../utils/stringUtils'
import { trackMixpanelEvent_addNewSongPart } from '../../../../utils/tracking'
import { Prog } from '../../../../utils/types'
import DropdownMenu from '../../../common/DropdownMenu/DropdownMenu'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './PartsMenuItem.module.scss'

type AddProps = {
  setTabWidth?: (a: number) => void
  mobileMain?: boolean
  onClose?: any
}

const STRINGS = {
  duplicateCurrentPart: 'Duplicate',
  generatePart: 'Generate part',
  addEmptyPart: 'Add Empty',
}

export const PartsMenuItemAdd: React.FC<AddProps> = ({ setTabWidth, mobileMain, onClose = () => {} }) => {
  const { isPartsLimited, renderLimitIcon, triggerLimitCallback } = useLimits()
  const [setRef, , { rect }] = useElementSize()
  const { isMobile } = useSizes()
  const {
    playerConfig: { prog, currentPart, player, isPartLoading },
    playerConfigSetter,
  } = usePlayerConfigState()
  const { addPartToProg, duplicatePartToProg, initiateProgFromData } = useProjectState()
  const generatorModals = useGeneratorModals()

  const isLimited = isPartsLimited(prog?.parts || [])
  const duplicateName = useMemo(() => {
    const partNameLengthLimit = 17
    let partName = currentPart.name

    if (partName.length > partNameLengthLimit) {
      partName = partName.slice(0, partNameLengthLimit)
      partName += '...'
    }

    return `${STRINGS.duplicateCurrentPart} (${partName})`
  }, [currentPart])

  useEffect(() => {
    setTabWidth && setTabWidth((rect?.width || 0) - 3)
  }, [rect?.width])

  const handleManagePart = (func: () => Prog | null) => {
    player.pause()

    generatorModals.portalsConfig.setRandomInstrument(false)
    generatorModals.portalsConfig.setRandomDrumInstrument(false)

    const newProg = func()
    if (!newProg) return

    playerConfigSetter.setProg(newProg, true)
    playerConfigSetter.setView()
  }

  const handleDuplicateCurrentPart = async () => {
    if (!prog || isLimited) return

    handleManagePart(() => {
      const newProg = duplicatePartToProg(prog, currentPart.id, prog.parts.length)
      return newProg
    })

    await trackMixpanelEvent_addNewSongPart('duplicate')
  }
  const handleGeneratePart = async () => {
    if (!prog || isLimited) return

    await trackMixpanelEvent_addNewSongPart('AI variation')
  }
  const handleAddEmptyPart = async () => {
    if (!prog || isLimited) return

    handleManagePart(() => {
      const newProg = addPartToProg(prog as Prog, '')

      const {
        chordLayers,
        melodyLayers,
        drumLayers,
        chordsVolume,
        chordsMuted,
        drumsMuted,
        drumsVolume,
        generatorSettings,
      } = newProg.parts[newProg.parts.length - 2]
      const newPart = newProg.parts[newProg.parts.length - 1]

      newProg.parts[newProg.parts.length - 1] = {
        ...newPart,
        chordLayers: copyObj(chordLayers),
        melodyLayers: copyObj(melodyLayers),
        drumLayers: copyObj(drumLayers),
        chordsVolume,
        chordsMuted,
        drumsMuted,
        drumsVolume,
        generatorSettings: copyObj(generatorSettings),
      }

      return initiateProgFromData(newProg, [], newProg.scale, newProg.key, newPart.id, null)
    })

    await trackMixpanelEvent_addNewSongPart('empty')
  }

  const renderBody = () => (
    <div onClick={(e) => e.stopPropagation()} className={styles.addPartDropdownContainer}>
      <DropdownMenu
        className={styles.addPartDropdownMenu}
        options={[
          [
            {
              name: duplicateName,
              icon: <CloneIcon />,
              key: 1,
              onClick: handleDuplicateCurrentPart,
              className: styles.addPartDropdownItem,
            },
            // {
            //   name: STRINGS.generatePart,
            //   icon: <AISuggestionWhite />,
            //   key: 2,
            //   onClick: handleGeneratePart,
            //   className: styles.addPartDropdownItem,
            // },
            {
              name: STRINGS.addEmptyPart,
              icon: <PlusIcon />,
              key: 3,
              onClick: handleAddEmptyPart,
              className: styles.addPartDropdownItem,
            },
          ],
        ]}
        renderTrigger={(onClick) => {
          return (
            <div
              ref={setRef}
              className={classNames(
                styles.container,
                styles.addButton,
                {
                  [styles.mobileMain]: mobileMain,
                },
                { [styles.spawnAnimation]: currentPart === undefined },
              )}
              onClick={(e) => {
                e.stopPropagation()

                triggerLimitCallback(isLimited, FRONTEND_LIMIT_TYPES.parts)

                if (isPartLoading) return

                if (isLimited) {
                  onClose()
                } else {
                  onClick()
                }
              }}
            >
              +{renderLimitIcon(isLimited, styles.limitIcon)}
            </div>
          )
        }}
      />
    </div>
  )
  if (isMobile) {
    return (
      <div
        className={classNames(styles.addButtonContainer, {
          [styles.mobileMain]: mobileMain,
        })}
      >
        {renderBody()}
      </div>
    )
  }
  return renderBody()
}

export default PartsMenuItemAdd
