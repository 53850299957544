import { LyricsCreativity, LyricsMood } from '../utils/types'
import api from './index'

export const getSongPartSuggestionInnerRoute = async (
  lines: string[],
  suggestions: string[],
  creativity: LyricsCreativity,
  songPart: string,
  mood?: LyricsMood,
): Promise<{ suggestion?: string[]; error?: any }> => {
  try {
    const data = await api.get('/api/lyrics/ai-song-part', {
      params: { lines: [...lines].slice(-12), mood, creativity, songPart, suggestions },
    })
    return { suggestion: data.data?.content, error: data.data?.error }
  } catch (e) {
    return { error: 403 }
  }
}

export const getRhymesInnerRoute = async (word: string) => {
  const data = await api.get('/api/lyrics/word-rhymes', {
    params: { word },
  })
  return data.data
}
