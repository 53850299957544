export const chordKeyPalette: { [key: string]: { [key: string]: string[] } } = {
  major: {
    false: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'],
    true: ['C', 'C#', 'D', 'D#', 'E', 'F', 'Gb', 'G', 'G#', 'A', 'A#', 'B'],
  },
  minor: {
    false: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B'],
    true: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'A#', 'B'],
  },
}
export const chordScalePalette = ['major', 'minor']

export const chordEditorLocalStorageKeys = {
  selectedTab: 'chord-editor-selected-tab',

  inKeyChordKey: 'chord-editor-inKey-chord-key',
  inKeyChordScale: 'chord-editor-inKey-chord-scale',
  inKeyChordOptions: 'chord-editor-inKey-chord-options',
  inKeyChordScaleType: 'chord-editor-inKey-chord-scale-type',
  inKeyChordScaleTypeExtended: 'chord-editor-inKey-chord-scale-type-extended',

  searchChord: 'chord-editor-search-chord',
  searchTooltipShowUsage: 'chord-editor-tooltip-show-date',
  searchTooltipShowDate: 'chord-editor-tooltip-show-date',
}
