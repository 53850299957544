import classNames from 'classnames'
import Image from 'next/image'
import React, { forwardRef, useMemo, useState } from 'react'

import AIRegenerate from '../../../../../assets/icons/ai-regenerate.svg'
import InstrumentSettingsIcon from '../../../../../assets/icons/genres.svg'
import PortalButton from '../../../../../assets/icons/settings3.svg'
import { useGeneratorModals } from '../../../../../context/GeneratorModalsContext'
import { useInstrumentsState } from '../../../../../context/InstrumentsStateContext'
import useIsOnline from '../../../../../hooks/useIsOnline'
import useLimitedRequest from '../../../../../hooks/useLimitedRequest'
import { LimitTypesLabel } from '../../../../../utils/limits'
import { Prog } from '../../../../../utils/types'
import GenerateButton from '../../../../common/GenerateButton/GenerateButton'
import GenreDropdown from '../../../DrumsEditor/GenreDropdown'
import { INSTRUMENT_TYPES, InstrumentType } from '../../../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../../../hooks/usePlayerConfigState'
import styles from './GenerateTrackButton.module.scss'

type Props = {
  type: InstrumentType
  mobileMode?: boolean
}

const GenerateTrackButton = forwardRef(({ type, mobileMode = false }: Props, ref: any) => {
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { navigator, resetLayers, handleChangeGenre } = useInstrumentsState()
  const generatorModalsConfig = useGeneratorModals()
  const { isOnline } = useIsOnline()

  const [loading, setLoading] = useState<InstrumentType | null>(null)

  const { currentPart } = playerConfig
  const { randomDrumInstrument, randomMelodyInstrument, randomInstrument, tempo, customTempo, mode } =
    generatorModalsConfig.portalsConfig
  const genre = useMemo(() => {
    switch (type) {
      case 'CHORDS':
      case 'MELODY':
        return currentPart.generatorSettings.chordGenreKey
      case 'DRUMS':
        return currentPart.generatorSettings.drumGenreKey
      default:
        return ''
    }
  }, [currentPart, type])

  const handleGenerateChords = async () => {
    let prog = playerConfig.prog

    if (randomInstrument && isOnline) {
      prog = resetLayers(navigator.randomInstrument(genre, false), INSTRUMENT_TYPES.CHORDS)
    }

    await playerConfigSetter.handleGenerateChords(
      prog as Prog,
      genre,
      tempo === 'Current',
      tempo === 'Custom' ? customTempo : tempo || '',
      mode,
    )
  }
  const handleGenerateMelody = async () => {
    let prog = playerConfig.prog

    if (randomMelodyInstrument && isOnline) {
      prog = resetLayers(navigator.randomMelody(genre, false), INSTRUMENT_TYPES.MELODY)
    }

    await playerConfigSetter.handleGenerateMelody(prog)
  }
  const handleGenerateDrums = async () => {
    let prog = playerConfig.prog

    if (randomDrumInstrument && isOnline) {
      prog = resetLayers(navigator.randomDrums(genre, false), INSTRUMENT_TYPES.DRUMS)
    }

    await playerConfigSetter.handleGenerateDrums(
      prog as Prog,
      genre,
      tempo === 'Current',
      tempo === 'Custom' ? customTempo : tempo || '',
    )
  }

  const limitedRequestChords = useLimitedRequest(handleGenerateChords, false, LimitTypesLabel.generations, 'chords')
  const limitedRequestMelody = useLimitedRequest(handleGenerateMelody, false, LimitTypesLabel.generations, 'melody')
  const limitedRequestDrums = useLimitedRequest(handleGenerateDrums, false, LimitTypesLabel.generations, 'drums')

  const handleOpenPortal = (e: any) => {
    e.stopPropagation()

    generatorModalsConfig.setIsChordsSettingsOpen((v: boolean) => (type === INSTRUMENT_TYPES.CHORDS ? !v : false))
    generatorModalsConfig.setIsMelodySettingsOpen((v: boolean) => (type === INSTRUMENT_TYPES.MELODY ? !v : false))
    generatorModalsConfig.setIsDrumsSettingsOpen((v: boolean) => (type === INSTRUMENT_TYPES.DRUMS ? !v : false))
  }
  const handleGenerate = async () => {
    if (playerConfig.isProgLoading || playerConfig.isMelodyLoading || playerConfig.isDrumsPatternLoading) return

    setLoading(type)

    try {
      switch (type) {
        case 'CHORDS': {
          playerConfigSetter.setIsProgLoading(true)
          playerConfig.player.reset()
          await limitedRequestChords()
          playerConfigSetter.setIsProgLoading(false)

          break
        }
        case 'MELODY': {
          playerConfigSetter.setIsMelodyLoading(true)
          await limitedRequestMelody()
          playerConfigSetter.setIsMelodyLoading(false)
          break
        }
        case 'DRUMS': {
          playerConfigSetter.setIsDrumsPatternLoading(true)
          await limitedRequestDrums()
          playerConfigSetter.setIsDrumsPatternLoading(false)
          break
        }
        default:
          break
      }
    } catch (e) {
      playerConfigSetter.setIsProgLoading(false)
      playerConfigSetter.setIsMelodyLoading(false)
      playerConfigSetter.setIsDrumsPatternLoading(false)
    }

    setLoading(null)
  }

  if (mobileMode)
    return (
      <div className={styles.regenerateMobileContainer}>
        <GenerateButton
          onClick={handleGenerate}
          className={classNames(styles.generateButton, { [styles.loading]: loading === type })}
          iconClassName={styles.generateButtonIcon}
          dataLoading={loading === type}
          disabled={loading === type}
          icon='ai-regenerate'
          text='GENERATE'
          color={type === 'DRUMS' ? 'green' : 'blue'}
          size='big'
          animate
        />
      </div>
    )

  const VALUES: any = {
    CHORDS: {
      image: playerConfig.chordGenre.imageUrl,
      handleChangeGenreTrigger: (genreKey: string, replaceInstrument: boolean) => {
        handleChangeGenre(genreKey, undefined, replaceInstrument)
      },
      replaceText: 'Replace Instruments',
    },
    MELODY: null,
    DRUMS: {
      image: playerConfig.drumGenre.imageUrl,
      handleChangeGenreTrigger: (genreKey: string, replaceInstrument: boolean) => {
        handleChangeGenre(undefined, genreKey, replaceInstrument)
      },
      replaceText: 'Replace Drum Sounds',
    },
  }

  const renderGenreTrigerBtn = () => {
    if (!VALUES[type]) return null

    return (
      <div className={styles.instrumentImage} onClick={(e) => e.stopPropagation()}>
        <GenreDropdown
          type={type}
          handleChangeGenre={VALUES[type].handleChangeGenreTrigger}
          replaceText={VALUES[type].replaceText}
          triggerClassName={styles.styleDropdownTrigger}
          openClassName={styles.styleDropdownTriggerOpen}
          className={styles.styleDropdown}
          dropdownClassName={styles.styleDropdownBody}
          yOffset={-100}
          triggerContent={
            <>
              <Image
                src={VALUES[type].image}
                layout='fill'
                alt='Image'
                objectFit='cover'
                priority
                sizes='(max-width: 576px) 42px, 128px'
              />
              <div className={classNames(styles.portalButton)}>
                <InstrumentSettingsIcon />
              </div>
            </>
          }
        />
      </div>
    )
  }
  const renderPortalBtn = () => {
    return (
      <div className={classNames(styles.portalButton)} onClick={handleOpenPortal}>
        <PortalButton />
      </div>
    )
  }
  const renderGenerateBtn = () => {
    return <AIRegenerate className={styles.portalButtonContainerImage} />
  }

  const renderContent = () => {
    return (
      <>
        {renderGenreTrigerBtn()}

        <div className={styles.btn}>
          {renderPortalBtn()}
          {renderGenerateBtn()}
        </div>
      </>
    )
  }

  return (
    <>
      <div
        className={classNames(
          styles.portalButtonContainer,
          { [styles.chords]: type === 'CHORDS' },
          { [styles.melody]: type === 'MELODY' },
          { [styles.drums]: type === 'DRUMS' },
          { [styles.loading]: loading === type },
        )}
        onClick={handleGenerate}
        ref={ref}
      >
        <div className={styles.portalButtonContainerInner}>{renderContent()}</div>
      </div>
    </>
  )
})

export default GenerateTrackButton
