import { MidiJSON } from '@tonejs/midi'
import classNames from 'classnames'
import React from 'react'

import useSizes from '../../../../../hooks/useSizes'
import { drumsToMidiWithTempo, getBarsFromSixteens } from '../../../../../utils/audio/midiUtils'
import { melodyToTempo } from '../../../../../utils/melodyUtils'
import { ProgPart } from '../../../../../utils/types'
import { InstrumentType } from '../../../LayersOfInstruments/LayersOfInstruments'
import DrumsPlayer, { Bit } from '../../../hooks/player/DrumsPlayer'
import MelodyPlayer from '../../../hooks/player/MelodyPlayer'
import styles from './BitsPreview.module.scss'

type Props = {
  part: ProgPart
  isPlaceholder: boolean
  duration: string
  darkenDefault?: boolean
  getIsActiveBit?: (a: number) => boolean
  melodyPlayer: MelodyPlayer | null
  drumsPlayer: DrumsPlayer | null
  className?: string
  type?: InstrumentType
}

const BitsPreview: React.FC<Props> = ({
  part,
  getIsActiveBit,
  isPlaceholder,
  darkenDefault,
  duration,
  melodyPlayer,
  drumsPlayer,
  className,
  type = 'DRUMS',
}) => {
  const { isMobile } = useSizes()

  const isMelodyMode = type === 'MELODY'

  let midi: MidiJSON | null = null
  let tracks: { notes: Bit[]; name: string }[] = []

  switch (type) {
    case 'MELODY': {
      const { allNotes: melodyNotes } = melodyPlayer?.convertPattern(melodyToTempo(part.melody), duration) || {
        allNotes: [],
      }

      const allMidi = melodyNotes.map((note) => note.midiNote)
      const minMidiValue = Math.min(...allMidi)
      const maxMidiValue = Math.max(...allMidi)
      const rangeMidiValue = maxMidiValue - minMidiValue

      tracks = Array.from({ length: rangeMidiValue + 1 }, (_, i) => maxMidiValue - i).map((midiNote) => ({
        notes: melodyNotes.filter((note) => midiNote === note.midiNote),
        name: midiNote.toString(),
      }))

      break
    }
    case 'DRUMS': {
      midi = drumsToMidiWithTempo(part.drums)
      const { allNotes: drumsNotes } = drumsPlayer?.convertPattern({ midi, name: '' }, duration) || { allNotes: [] }

      tracks = midi.tracks.map((t) => ({
        notes: drumsNotes.filter((note) => t.name === note.track),
        name: t.name,
      }))

      break
    }
  }

  const durationBars = Math.max(isMobile ? 2 : 1, getBarsFromSixteens(duration))

  const isInstrumentActive = (type: string) => {
    if (isMelodyMode) return true
    return (
      part.drums?.groups
        .map((group) => group.percTypes)
        .flat()
        .find((i) => i.type === type)?.active || false
    )
  }

  return (
    <div className={classNames(styles.container, className)}>
      {tracks.map((track, index) => (
        <div key={index} className={styles.track}>
          {track.notes.map((note, index) => {
            const indAbsolute = getBarsFromSixteens(note.time) * 4
            const left = (getBarsFromSixteens(note.time) / durationBars) * 100
            const width = (getBarsFromSixteens(note.duration) / durationBars) * 100

            return (
              <span
                className={styles[type]}
                key={note.time + index}
                data-darken={darkenDefault}
                data-active={getIsActiveBit && getIsActiveBit(indAbsolute)}
                data-muted={!isInstrumentActive(track.name)}
                style={{
                  left: `${left}%`,
                  width: `calc(${Math.min(100 - left, width)}% - 1px)`,
                }}
              ></span>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default BitsPreview
