import { useEffect } from 'react'
import SmartLook from 'smartlook-client'

import { useCurrentUser } from '../../../context/CurrentUserContext'

// dynamic import only
const SmartLookIdentify = () => {
  const { currentUser } = useCurrentUser()
  useEffect(() => {
    if (currentUser) {
      SmartLook?.identify(currentUser.id, {
        email: currentUser.email,
        name: currentUser.name,
        subscriptionPlan: currentUser.subscriptionPlan,
      })
    } else {
      SmartLook?.anonymize()
    }
  }, [currentUser])
  return null
}

export default SmartLookIdentify
