import classNames from 'classnames'
import React, { useRef } from 'react'

import DeleteIcon from '../../../../assets/icons/delete-drums.svg'
import DrumsInstrumentIcon from '../../../../assets/icons/drums-instrument.svg'
import InstrumentCategoryBassesIcon from '../../../../assets/icons/instrument-category-basses.svg'
import InstrumentCategoryGuitarsIcon from '../../../../assets/icons/instrument-category-guitars.svg'
import InstrumentCategoryKeysIcon from '../../../../assets/icons/instrument-category-keys.svg'
import InstrumentCategoryOtherIcon from '../../../../assets/icons/instrument-category-other.svg'
import InstrumentCategoryPadsIcon from '../../../../assets/icons/instrument-category-pads.svg'
import InstrumentCategoryStringsIcon from '../../../../assets/icons/instrument-category-strings.svg'
import InstrumentCategorySynthsIcon from '../../../../assets/icons/instrument-category-synths.svg'
import LoaderIcon from '../../../../assets/icons/loader.svg'
import DropdownTriggerIcon from '../../../../assets/icons/menu-trigger-points.svg'
import { useInstrumentsState } from '../../../../context/InstrumentsStateContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import useSizes from '../../../../hooks/useSizes'
import { updateProgPartDataById } from '../../../../utils/progUtils'
import { InstrumentLayer } from '../../../../utils/types'
import DropdownMenu from '../../../common/DropdownMenu/DropdownMenu'
import NewVolumeControls from '../../Footer/NewVolumeControls'
import { InstrumentType, TabType } from '../../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import { INSTRUMENT_CATEGORY } from '../InstrumentMenu/constants'
import GenerateTrackButton from './GenerateTrackButton/GenerateTrackButton'
import styles from './TimelineConfig.module.scss'

type Props = object

const TimelineConfig: React.FC<Props> = () => {
  const configRef = useRef<HTMLDivElement>(null)
  const { text } = useInternationalization()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { isMobile } = useSizes()
  const {
    drumLayers,
    chordLayers,
    melodyLayers,
    chordsInstrumentName,
    melodyInstrumentName,
    drumsInstrumentName,
    setActiveLayerId,
  } = useInstrumentsState()

  const { currentPart, currentPartId, prog } = playerConfig
  const STRINGS = text.timeline

  if ((currentPart?.draft || !currentPart) && isMobile) return null

  const handleOpenEditor = (type: InstrumentType, layerId: number) => {
    setActiveLayerId(layerId)

    const isChordMode = type === 'CHORDS'
    const isMelodyMode = type === 'MELODY'
    const isDrumMode = type === 'DRUMS'

    playerConfigSetter.setView({
      drumsOpen: isDrumMode,
      drumsEditorOpen: isDrumMode,
      melodyEditorOpen: isMelodyMode,
      layersOpen: isChordMode ? type : null,
      pianoOpen: isChordMode || (isMelodyMode && !!isMobile),
    })
  }

  const getTabProps = (tabType: TabType, index: number, isMelodyMode = false, isDrumMode = false) => {
    const key = `icon_${isDrumMode ? 'drum' : tabType}_${index}`

    if (isMelodyMode)
      return <InstrumentCategoryKeysIcon key={key} className={classNames(styles.layerIcon, styles.blue)} />

    if (isDrumMode) return <DrumsInstrumentIcon key={key} className={classNames(styles.layerIcon, styles.green)} />

    switch (tabType) {
      case INSTRUMENT_CATEGORY.keys:
        return <InstrumentCategoryKeysIcon key={key} className={classNames(styles.layerIcon, styles.blue)} />
      case INSTRUMENT_CATEGORY.guitars:
        return <InstrumentCategoryGuitarsIcon key={key} className={classNames(styles.layerIcon, styles.yellow)} />
      case INSTRUMENT_CATEGORY.synths:
        return <InstrumentCategorySynthsIcon key={key} className={classNames(styles.layerIcon, styles.purple)} />
      case INSTRUMENT_CATEGORY.pads:
        return <InstrumentCategoryPadsIcon key={key} className={classNames(styles.layerIcon, styles.pink)} />
      case INSTRUMENT_CATEGORY.other:
        return <InstrumentCategoryOtherIcon key={key} className={classNames(styles.layerIcon, styles.cyan)} />
      case INSTRUMENT_CATEGORY.strings:
        return <InstrumentCategoryStringsIcon key={key} className={classNames(styles.layerIcon, styles.green)} />
      case INSTRUMENT_CATEGORY.basses:
        return <InstrumentCategoryBassesIcon key={key} className={classNames(styles.layerIcon, styles.lagoon)} />
      default:
        return <></>
    }
  }
  const renderIcon = (type: InstrumentType, layers: InstrumentLayer[]) => {
    const isMelodyMode = type === 'MELODY'
    const isDrumMode = type === 'DRUMS'

    if (isMobile) {
      const numOfLayers = layers.length - 1
      return (
        <div
          className={styles.iconContainer}
          onClick={(e) => {
            e.stopPropagation()
            handleOpenEditor(type, 0)
          }}
        >
          {getTabProps(layers[0]?.instrument.category as any, 0, isMelodyMode, isDrumMode)}
          {numOfLayers ? <div className={styles.numOfLayers}>+{numOfLayers}</div> : null}
        </div>
      )
    }

    return layers.map((layer, index) => (
      <div
        key={`layer_${index}`}
        className={styles.iconContainer}
        onClick={(e) => {
          e.stopPropagation()
          handleOpenEditor(type, index)
        }}
      >
        {getTabProps(layer.instrument.category as any, index, isMelodyMode, isDrumMode)}
      </div>
    ))
  }
  const renderDropdown = (type: InstrumentType) => {
    if (type === 'CHORDS') return null

    return (
      <DropdownMenu
        options={[
          [
            {
              name: 'Delete',
              key: 1,
              icon: <DeleteIcon />,
              onClick: (e) => {
                e.preventDefault()
                e.stopPropagation()

                switch (type) {
                  case 'MELODY':
                    playerConfigSetter.handleShowHideMelody()
                    break
                  case 'DRUMS':
                    playerConfigSetter.handleShowHideDrums()
                    break
                }

                playerConfigSetter.setView()
              },
            },
          ],
        ]}
        className={styles.hideDrumsDropdown}
        renderTrigger={(onClick) => (
          <div
            className={styles.hideDrumsTrigger}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              onClick()
            }}
          >
            <DropdownTriggerIcon />
          </div>
        )}
      />
    )
  }

  const VALUES = {
    CHORDS: {
      loaded: playerConfig.instrumentLoaded,
      icon: (type: any) => renderIcon(type, chordLayers),
      name: chordsInstrumentName,
      color: 'blue',
      volume: currentPart.chordsVolume,
      onVolumeChange: (volume: number) => {
        playerConfig.player.setChordPartVolume(currentPart.chordsMuted ? 0 : volume, currentPartId)
      },
      onAfterVolumeChange: (volume: number) => {
        if (!prog) return
        const newProg = updateProgPartDataById(prog, currentPartId, { chordsVolume: volume })
        playerConfigSetter.setProg(newProg)
      },
    },
    MELODY: {
      loaded: playerConfig.melodyInstrumentLoaded,
      icon: (type: any) => renderIcon(type, melodyLayers),
      name: melodyInstrumentName,
      color: 'blue',
      volume: currentPart.melodyVolume,
      onVolumeChange: (volume: number) => {
        playerConfig.player.setMelodyPartVolume(currentPart.melodyMuted ? 0 : volume, currentPartId)
      },
      onAfterVolumeChange: (volume: number) => {
        if (!prog) return
        const newProg = updateProgPartDataById(prog, currentPartId, { melodyVolume: volume })
        playerConfigSetter.setProg(newProg)
      },
    },
    DRUMS: {
      loaded: playerConfig.drumsInstrumentLoaded,
      icon: (type: any) => renderIcon(type, drumLayers),
      name: drumsInstrumentName,
      color: 'green',
      volume: currentPart.drumsVolume,
      onVolumeChange: (volume: number) => {
        playerConfig.player.setDrumsPartVolume(currentPart.drumsMuted ? 0 : volume, currentPartId)
      },
      onAfterVolumeChange: (volume: number) => {
        if (!prog) return
        const newProg = updateProgPartDataById(prog, currentPartId, { drumsVolume: volume })
        playerConfigSetter.setProg(newProg)
      },
    },
  }

  const renderContainer = (type: InstrumentType) => {
    if (isMobile) {
      return (
        <div className={classNames(styles.wrapper)}>
          <div className={styles.container}>
            {!VALUES[type].loaded ? <LoaderIcon className={styles.loader} /> : VALUES[type].icon(type)}
            {renderDropdown(type)}
          </div>
        </div>
      )
    }

    return (
      <div className={styles.wrapper}>
        <div className={styles.container} onClick={() => handleOpenEditor(type, 0)}>
          <div className={styles.header}>
            <div className={styles.instrumentIconsContainer}>
              {!VALUES[type].loaded ? <LoaderIcon className={styles.loader} /> : VALUES[type].icon(type)}
            </div>

            {renderDropdown(type)}
          </div>

          <div className={styles.footer}>
            {!VALUES[type].loaded ? (
              <p className={styles.title}>{STRINGS.loading}</p>
            ) : (
              <p className={styles.title}>{VALUES[type].name}</p>
            )}

            <NewVolumeControls
              color={VALUES[type].color as any}
              volume={VALUES[type].volume as any}
              onVolumeChange={VALUES[type].onVolumeChange as any}
              onAfterVolumeChange={VALUES[type].onAfterVolumeChange as any}
              mode='thin'
              hideControls
              className={styles.volumeControl}
            />
          </div>
        </div>

        <GenerateTrackButton type={type} />
      </div>
    )
  }

  return (
    <div
      className={classNames(styles.outerWrapper)}
      data-mobile-timeline-hidden={playerConfig.view.mobileTab !== 'chords'}
      ref={configRef}
      data-unselect-chord={true}
      onClick={playerConfigSetter.handleUnselectChord}
    >
      {renderContainer('CHORDS')}

      {playerConfig.currentPart.melodyShown ? renderContainer('MELODY') : null}

      {playerConfig.currentPart.drumsShown ? renderContainer('DRUMS') : null}
    </div>
  )
}

export default TimelineConfig
