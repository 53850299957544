import classNames from 'classnames'
import React, { FC, useMemo } from 'react'

import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import { Chord } from '../../../../utils/types'
import LinearLoading from '../../../common/LinearLoader/LinearLoading'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './ChordEditorComponents.module.scss'
import { getLevelByProbability, getScaleFromDegree, removeScaleFromDegree } from '../../../../utils/chord-editor'

type Props = {
  chord: Chord

  chordKey?: string
  chordScale?: string
  showPrediction?: boolean

  scaleClassName?: string
  nameClassName?: string
  degreeClassName?: string
}

const ChordContent: FC<Props> = ({
  chord,
  chordKey: keyProps,
  chordScale: scaleProps,
  showPrediction,
  scaleClassName,
  nameClassName,
  degreeClassName,
}) => {
  const {
    playerConfig: { activeChord, tonalityKey, tonalityScale },
  } = usePlayerConfigState()
  const { showRomanNumerals } = useGeneratorModals()

  const scale = useMemo(() => {
    const chordScaleData = getScaleFromDegree(chord.degree)
    if (!chordScaleData) return ''

    const [chordKey, chordScale] = chordScaleData.split('-')

    const progKey = keyProps || tonalityKey
    const progScale = scaleProps || tonalityScale

    if (chordKey === progKey && chordScale === progScale) return ''
    return chordScaleData.split('-').join(' ')
  }, [chord, keyProps, scaleProps, tonalityKey, tonalityScale])
  const degree = useMemo(() => removeScaleFromDegree(chord.degree), [chord])

  return (
    <>
      {showPrediction && (
        <div
          className={styles.matchLevel}
          data-selected={activeChord?.name === chord.name}
          data-level={getLevelByProbability(chord.prediction || 100)}
        >
          <LinearLoading size={48} customPercentage={chord.prediction} vertical />
        </div>
      )}

      {scale && <div className={classNames(styles.chordContentScale, scaleClassName)}>{scale}</div>}

      <div className={classNames(styles.chordContentName, nameClassName)}>{chord.name}</div>

      {showRomanNumerals && <div className={classNames(styles.chordContentDegree, degreeClassName)}>{degree}</div>}
    </>
  )
}

export default ChordContent
