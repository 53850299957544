import classNames from 'classnames'
import React from 'react'

import IconButton from '../IconButton'
import styles from './GenerateButton.module.scss'

interface GenerateButtonProps {
  onClick?: any
  disabled?: boolean
  className?: string
  iconClassName?: string
  dataLoading?: boolean
  icon?: 'ai-suggestion-white' | 'ai-regenerate' | 'portal-button-DRUMS' | 'portal-button-CHORDS'
  text?: string
  color?: 'green' | 'blue'
  size: 'small' | 'big'
  animate?: boolean
}

const GenerateButton: React.FC<GenerateButtonProps> = ({
  onClick,
  disabled,
  className,
  iconClassName,
  dataLoading,
  icon,
  text,
  color,
  size,
  animate = true,
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      data-animate={animate}
      className={classNames(
        className,
        styles.generateButton,
        { [styles.blue]: color === 'blue' },
        { [styles.green]: color === 'green' },
        { [styles.small]: size === 'small' },
        { [styles.big]: size === 'big' },
        { [styles.inactive]: disabled },
      )}
      data-loading={dataLoading}
    >
      <IconButton icon={icon} className={classNames(iconClassName, styles.icon)} />
      <span>{text}</span>
    </button>
  )
}

export default GenerateButton
