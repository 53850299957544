import React, { useEffect } from 'react'

import { useCurrentUser } from '../../../context/CurrentUserContext'
import Script from 'next/script'

type Props = {
  distinctId?: string
}
const HotjarScript: React.FC<Props> = ({ distinctId }) => {
  const { currentUser } = useCurrentUser()
  useEffect(() => {
    if (distinctId) {
      console.info(hj)
      console.info(distinctId)
      hj('identify', distinctId, {
        plan: currentUser?.subscriptionPlan || '',
        email: currentUser?.email || '',
        name: currentUser?.name || '',
        id: currentUser?.id || '',
      })
    }
  }, [currentUser])

  return (
    <Script
      defer
      dangerouslySetInnerHTML={{
        __html: `
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:1033907,hjsv:6};
            a=o.getElementsByTagName('head')[0];
            r=o.createElement('script');r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
      }}
    />
  )
}

export default HotjarScript
