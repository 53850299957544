import classNames from 'classnames'
import React, { FC } from 'react'

import { FRONTEND_LIMIT_TYPES, useLimits } from '../../../../context/LimitsContext'
import styles from './ChordEditorComponents.module.scss'

export const palettes = [
  [
    {
      key: 'triad',
      name: 'Triads',
      premium: false,
    },
    {
      key: '7',
      name: '7th',
      premium: true,
    },
    {
      key: '9',
      name: '9th',
      premium: true,
    },
    {
      key: '11',
      name: '11th',
      premium: true,
    },
  ],
  // [
  //   {
  //     key: 'sus2',
  //     name: 'sus2',
  //     premium: true,
  //   },
  // ],
  // [
  //   {
  //     key: 'sus4',
  //     name: 'sus4',
  //     premium: true,
  //   },
  // ],
]
export const allChordPaletteOptions = palettes.flat()
export const paletteModifications: { [key: string]: string } = {
  triads: '',
  '7th': '7',
  '9th': '9',
  '11th': '11',
  sus2: 'sus2',
  sus4: 'sus4',
}

type Props = {
  activeOptions: string[]
  setActiveOptions: (v: string[] | ((v: string[]) => string[])) => void
}

const ChordPalette: FC<Props> = ({ activeOptions, setActiveOptions }) => {
  const { isChordEditorLimited, renderLimitIcon, triggerLimitCallback } = useLimits()

  return (
    <div className={styles.chordPaletteContainer}>
      {palettes.map((palette, index) => (
        <div key={`palette_${index}`} className={styles.chordPalette}>
          {palette.map((option) => {
            const isLimited = option.premium && isChordEditorLimited()
            const isActive = activeOptions.includes(option.key)

            return (
              <div
                key={option.key}
                className={classNames(styles.chordPaletteOption, {
                  [styles.chordPaletteOptionDisabled]: isLimited,
                  [styles.chordPaletteOptionActive]: isActive,
                })}
                onClick={() => {
                  triggerLimitCallback(isLimited, FRONTEND_LIMIT_TYPES.chord_palette)

                  if (isLimited) return

                  setActiveOptions([option.key])
                }}
              >
                <span>{option.name}</span>
                {renderLimitIcon(isLimited, styles.chordPaletteLimitIcon)}
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

export default ChordPalette
