import { UniqueIdentifier } from '@dnd-kit/core'
import { defaultAnimateLayoutChanges, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import ExtenderBottom from '../../../../assets/icons/extender-bottom.svg'
import ExtenderTop from '../../../../assets/icons/extender-top.svg'
import HandleIcon from '../../../../assets/icons/handle.svg'
import PlusIcon from '../../../../assets/icons/plus.svg'
import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import { getChordByIdFromProg, getNextChordIdFromProg } from '../../../../utils/progUtils'
import EditMenuMobile from '../../../common/EditMenu/EditMenuMobile'
import IconMenuButton from '../../../common/IconMenuButton'
import Tooltip from '../../../common/Tooltip/Tooltip'
import ChordContent from '../../NewChordEditor/components/ChordContent'
import { useEditMenu } from '../../hooks/useEditMenu'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './TimelineTile.module.scss'

type Props = {
  className?: string
  setWidth: (width: number) => void
  getWidth: (v?: number) => number
  id: UniqueIdentifier
  index: number
  onEdit?: (e: React.MouseEvent, id: number, magicChord: boolean) => void
  onClick?: (id: number) => void
  setExtending?: (a: boolean) => void
  onDoubleClick?: (id: number) => void
  draggable?: boolean
  active?: boolean
  moving?: boolean
  skeletonMode?: boolean
  widthChanges: { [key: UniqueIdentifier]: number | null }
  setWidthChanges: (curr: number | null, resizeSide: 'left' | 'right' | null) => void
  getSnappPosition?: (delta: number | null, resizeSide: 'left' | 'right' | null) => number
  isLast?: boolean
  draggingChordId?: UniqueIdentifier | null
  getWidthOfSelectedZone?: () => number
  held?: (number | UniqueIdentifier)[]
  onAddHook?: (e: React.MouseEvent) => Promise<void>
  resizingChordId?: UniqueIdentifier | null
  setResizingChordId?: (v: UniqueIdentifier | null) => void
}

const TimelineTile = ({
  id,
  className = '',
  getWidth,
  active = false,
  draggable = false,
  setExtending = () => void 0,
  onEdit = () => void 0,
  onDoubleClick = () => void 0,
  moving = false,
  skeletonMode = false,
  setWidth,
  index,
  widthChanges,
  setWidthChanges,
  isLast,
  draggingChordId = null,
  getWidthOfSelectedZone = () => 0,
  held = [],
  onAddHook,
  getSnappPosition = () => 0,
  resizingChordId,
  setResizingChordId = () => {},
}: Props) => {
  const animateLayoutChanges = (args: any) => {
    const { isSorting, wasSorting } = args

    if (isSorting || wasSorting) return defaultAnimateLayoutChanges(args)
    return true
  }

  const { text } = useInternationalization()
  const {
    transitionChordAdderPosition,
    handleShowTransitionChordAdder,
    handleHideTransitionChordAdder,
    handleShowMobileMenu,
    handleLockChord,
    handlePasteChords,
    handleDeleteChords,
  } = useEditMenu()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { activeChordIds, prog, player, editChordId, currentPartId, currentPart } = playerConfig
  const { setActiveChordIds } = playerConfigSetter
  const { attributes, listeners, transform, transition, setNodeRef, isDragging, items } = useSortable({
    id,
    animateLayoutChanges,
  })
  const { showRomanNumerals } = useGeneratorModals()

  const tileRef = useRef<any>(null)

  const [localWidthChange, setLocalWidthChange] = useState(0)
  const { isMobile, isTablet } = useSizes()
  const isSmallView = isMobile ? getWidth() < 80 : getWidth() < 120
  const [mousePos, setMousePos] = useState<null | number>(null)
  const [touchPos, setTouchPos] = useState<null | number>(null)
  const [resizeSide, setResizeSide] = useState<'left' | 'right' | null>(null)

  const [mouseDownConfig, setMouseDownConfig] = useState<{
    x: number | null
    y: number | null
  }>({ x: null, y: null })

  const shoulTilesBeUpdated = activeChordIds.includes(+id) && activeChordIds.length > 1
  const widthChange = localWidthChange || widthChanges[id]
  const chord = getChordByIdFromProg(+id, prog)
  const isDraft = chord?.draft

  useEffect(() => {
    if (!isDraft) return
    if (!activeChordIds.includes(+id)) handleDeleteChords([+id])
  }, [activeChordIds])

  useEffect(() => {
    if (mousePos) {
      document.body.addEventListener('mousemove', resizeMouse)
    }
    if (touchPos) {
      document.body.addEventListener('touchmove', resizeTouch)
    }
    if (mousePos || touchPos) {
      setExtending(true)
    } else {
      setExtending(false)
    }
    return () => {
      document.body.removeEventListener('mousemove', resizeMouse)
      document.body.removeEventListener('touchmove', resizeTouch)
    }
  }, [mousePos, touchPos])
  useEffect(() => {
    if (widthChange && active) {
      document.body.addEventListener('touchend', endTouch, { once: true })
      document.body.addEventListener('mouseup', endMouse, { once: true })
    }

    return () => {
      document.body.removeEventListener('touchend', endTouch)
      document.body.removeEventListener('mouseup', endMouse)
    }
  }, [widthChange])
  useEffect(() => {
    if (!tileRef.current) return

    const handleShowTransitionAdd = (e: MouseEvent) => {
      const target = e.currentTarget as HTMLElement
      const rect = target.getBoundingClientRect()

      const { clientWidth: tileWidth } = e.currentTarget as HTMLElement
      const cursorXInsideTile = e.clientX - rect?.left

      const isLeftSideHovered = cursorXInsideTile < tileWidth / 2

      handleShowTransitionChordAdder(isLeftSideHovered ? index : index + 1)
    }
    const handleHideTransitionAdd = () => {
      handleHideTransitionChordAdder()
    }

    tileRef.current.addEventListener('mousemove', handleShowTransitionAdd)
    tileRef.current.addEventListener('mouseleave', handleHideTransitionAdd)

    return () => {
      if (!tileRef.current) return

      tileRef.current.removeEventListener('mousemove', handleShowTransitionAdd)
      tileRef.current.removeEventListener('mouseleave', handleHideTransitionAdd)
    }
  }, [tileRef.current, transitionChordAdderPosition])

  const updateWidthChanges = (increase: number | null, resizeSide: 'left' | 'right' | null) => {
    const snappIncrease = getSnappPosition(increase || 0, resizeSide)

    if (resizeSide) {
      setWidthChanges(snappIncrease, resizeSide)
    } else {
      setLocalWidthChange(snappIncrease)
    }
  }

  const interruptMouse = () => {
    setMousePos(null)

    updateWidthChanges(null, null)
    setResizeSide(null)
  }

  const endMouse = () => {
    if (!mousePos) return

    setWidth(widthChange! + getWidth())

    interruptMouse()
    setResizingChordId(null)
  }

  const resizeMouse = (e: MouseEvent) => {
    if (!mousePos) return
    e.stopPropagation()
    const increase = Math.round(e.screenX - mousePos) * (resizeSide === 'left' ? -1 : 1)

    updateWidthChanges(increase, resizeSide)
  }

  const startResizeMouse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, localResizeSide?: 'left' | 'right') => {
    e.stopPropagation()
    updateWidthChanges(null, null)
    setMousePos(e.screenX)
    if (localResizeSide) {
      setResizeSide(localResizeSide)
    } else {
      setResizingChordId(id)
    }
  }
  const startResizeTouch = (e: React.TouchEvent<HTMLDivElement>, localResizeSide?: 'left' | 'right') => {
    if (e.touches.length !== 1 || !active) {
      return
    }
    e.stopPropagation()
    updateWidthChanges(null, null)
    setTouchPos(e.touches[0].clientX)
    if (localResizeSide) setResizeSide(localResizeSide)
  }
  const resizeTouch = (e: TouchEvent) => {
    if (!touchPos || e.touches.length !== 1) {
      return
    }
    e.preventDefault()
    e.stopPropagation()
    const increase = Math.round(e.touches[0].clientX - touchPos) * (resizeSide === 'left' ? -1 : 1)
    updateWidthChanges(increase, resizeSide)
  }
  const endTouch = () => {
    setWidth(widthChange! + getWidth())
    updateWidthChanges(null, null)
    setTouchPos(null)
    setResizeSide(null)
  }

  const rememberMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setMouseDownConfig({
      x: e.clientX,
      y: e.clientY,
    })
  }

  const handleSelectTileWithCtrl = () => {
    setActiveChordIds((v) => (v.includes(+id) ? v.filter((el) => el !== +id) : [...v, +id]))
  }

  const handleSelectTileWithShift = () => {
    const thisChordIndex = items.indexOf(id)

    const activeChordIndexes = activeChordIds.map((el) => items.indexOf(el))

    const minActiveChordIndex = Math.min(...activeChordIndexes)
    const maxActiveChordIndex = Math.max(...activeChordIndexes)

    let startChordIndex = Math.min(minActiveChordIndex, thisChordIndex)
    let endChordIndex = Math.max(maxActiveChordIndex, thisChordIndex)

    if (thisChordIndex > minActiveChordIndex && thisChordIndex < maxActiveChordIndex) {
      const lastChordIndex = items.indexOf(activeChordIds[activeChordIds.length - 1])

      startChordIndex = Math.min(lastChordIndex, thisChordIndex)
      endChordIndex = Math.max(lastChordIndex, thisChordIndex)
    }

    setActiveChordIds(items.slice(startChordIndex, endChordIndex + 1).map((el) => +el))
  }

  const handleTileClick = async (e: React.MouseEvent<HTMLDivElement>) => {
    handleShowMobileMenu()

    if (!mouseDownConfig.x || !mouseDownConfig.y || e.button === 2) {
      return
    }
    const xDiff = Math.abs(mouseDownConfig.x - e.clientX)
    const yDiff = Math.abs(mouseDownConfig.y - e.clientY)
    if (xDiff > 15 || yDiff > 15) {
      return
    }
    playerConfigSetter.setAddChordMode(null)
    if (isDraft) {
      onAddHook && (await onAddHook(e as React.MouseEvent))
      setActiveChordIds([+id])
      return
    }
    if (e.detail === 2) {
      onDoubleClick(id as number)
      return
    }
    const chord = getChordByIdFromProg(+id, prog)
    if (chord) player.playChord(prog, chord.id)
    if (e.ctrlKey || e.metaKey) {
      handleSelectTileWithCtrl()
      return
    }
    if (e.shiftKey) {
      handleSelectTileWithShift()
      return
    }
    setActiveChordIds([+id])
  }

  const draggingMode =
    (isDragging || [id, draggingChordId].every((el) => el && activeChordIds.includes(+el))) && !moving

  const dragHandle = () => (
    <div
      className={classNames(styles.dragHandle, { [styles.small]: isSmallView }, { [styles.dragging]: moving })}
      data-small={isSmallView}
      {...(isTablet ? attributes : {})}
      {...(isTablet ? listeners : {})}
      id={moving ? 'overlay-drag' : ''}
    >
      <HandleIcon />
    </div>
  )

  attributes['aria-describedby'] = 'DndDescribedBy-0'

  const isTileReplacingMany = draggingChordId === id && shoulTilesBeUpdated && !moving
  const getTileWidth = () => {
    if (isTileReplacingMany) return getWidthOfSelectedZone()

    if (widthChange) return widthChange + getWidth()

    return getWidth()
  }
  const tileWidth = getTileWidth() - 2

  const renderControl = (className: string, rule: 'left' | 'right' | undefined = undefined) => (
    <div
      className={classNames(styles.extender, className)}
      onMouseDown={(e) => startResizeMouse(e, rule)}
      onClick={interruptMouse}
      onPointerDown={(e) => e.stopPropagation()}
      onTouchStart={(e) => startResizeTouch(e, rule)}
      onTouchEnd={() => setTouchPos(null)}
    >
      {rule ? <ExtenderTop /> : <ExtenderBottom />}
    </div>
  )

  // rounded general

  const isSingleChordSelected = activeChordIds.length === 1
  const isThisChordFirst = index === 0

  const renderPreviewIndicator = () => {
    return (
      <div
        className={classNames(styles.previewIndicator, {
          [styles.previewIndicatorHidden]: chord?.id !== playerConfig.previewChordId,
        })}
      />
    )
  }
  const renderHeldTile = (h: number | UniqueIdentifier) => {
    return (
      <div
        className={classNames(className, styles.tile, styles.dragging, { [styles.selected]: active })}
        style={{ width: getWidth(+h) - 2 }}
      >
        <div className={styles.container}>{renderTileContent(+h)}</div>
      </div>
    )
  }
  const renderTransitionAddBtn = () => {
    if (isDragging || isMobile || !prog) return null

    const isThisChord = transitionChordAdderPosition === index
    const isAnyChordDragging = draggingChordId
    const isAnyChordResizing = Object.values(widthChanges).length || resizingChordId
    const isAnyChordDraft = currentPart.chords.some((c) => c.draft)

    const isShown = isThisChord && !isAnyChordDragging && !isAnyChordResizing && !isAnyChordDraft
    const tooltipId = 'transition-add'

    const handleClick = async (e: React.MouseEvent) => {
      if (!chord) return

      const position = index
      const newChord = {
        ...chord,
        draft: true,
        duration: 1,
      }

      const newId = getNextChordIdFromProg(prog, currentPartId)
      handlePasteChords(position, [newChord])

      onAddHook && (await onAddHook(e as React.MouseEvent))
      playerConfigSetter.setAddChordMode(null)
      setActiveChordIds([newId])
    }

    return (
      <>
        <div
          className={classNames(styles.transitionAdd, { [styles.isFirst]: index === 0 }, { [styles.shown]: isShown })}
          onClick={handleClick}
          data-tooltip-id={tooltipId}
        >
          <PlusIcon />
        </div>

        <Tooltip id={tooltipId} text={text.timeline?.addTransitionChordTooltip} />
      </>
    )
  }
  const renderEditBtn = () => {
    if (isTablet) {
      if (active) {
        return <EditMenuMobile />
      }
    } else {
      if (isDraft) return null
      if (isSingleChordSelected && ((isSmallView && !chord?.locked) || !isSmallView)) {
        return (
          <IconMenuButton
            className={classNames(styles.magicChord, styles.hoverShow, {
              [styles.small]: isSmallView,
            })}
            icon={editChordId.id === id ? 'close' : 'chord-edit'}
            onMouseDown={(e) => {
              e.stopPropagation()
            }}
            onClick={(e) => {
              onEdit(e, id as number, true)
            }}
          />
        )
      }
    }

    return null
  }
  const renderLockBtn = () => {
    if ((isSmallView || isTablet || playerConfig.isProgLoading) && !chord?.locked) return null
    if (isDraft) return null

    return (
      <IconMenuButton
        className={classNames(styles.lockButton, chord?.locked && styles.locked, styles.hoverShow, {
          [styles.small]: isSmallView,
        })}
        icon={chord?.locked ? 'lock' : 'unlock'}
        data-enabled={!isTablet}
        onMouseDown={(e) => {
          e.stopPropagation()
        }}
        onClick={() => handleLockChord([id as number], !chord?.locked)}
      />
    )
  }
  const renderTileContent = (id: number) => {
    if (id === playerConfig.loadingTile || (skeletonMode && !getChordByIdFromProg(id, prog)?.locked)) {
      return (
        <div className={styles.tileSkeleton}>
          <div data-skeleton={true} />
          <div data-skeleton={true} />
        </div>
      )
    }

    const chord = playerConfig.previewChord?.id === id ? playerConfig.previewChord : getChordByIdFromProg(id, prog)
    if (!chord) return null

    return (
      <ChordContent
        chord={chord}
        scaleClassName={styles.tileScale}
        nameClassName={styles.tileName}
        degreeClassName={styles.tileDegree}
      />
    )
  }
  const renderTileContainer = () => {
    if (draggingMode && shoulTilesBeUpdated) return null
    if (isDraft)
      return (
        <>
          <PlusIcon className={styles.draftIcon} />
        </>
      )
    return (
      <div
        style={{ maxWidth: getWidth() - 30 }}
        className={classNames(styles.container, { [styles.showRomanNumerals]: showRomanNumerals })}
      >
        {renderTileContent(+id)}
      </div>
    )
  }

  const renderTile = () => {
    if (isTileReplacingMany) return <div className={styles.row}>{held.map((h) => renderHeldTile(h))}</div>

    return (
      <>
        {renderPreviewIndicator()}
        {renderTransitionAddBtn()}
        {!isDragging && !isThisChordFirst && isSingleChordSelected && !isDraft && (
          <div className={classNames(styles.extenderContainer, styles.left)}>{renderControl(styles.top, 'left')}</div>
        )}
        {renderEditBtn()}
        {/* {renderLockBtn()} */}
        {(!isMobile || active) && dragHandle()}
        {renderTileContainer()}
        {!isDragging && isSingleChordSelected && !isDraft && (
          <div className={classNames(styles.extenderContainer, styles.right)}>
            {!isLast ? renderControl(styles.top, 'right') : null}
            {renderControl(styles.bottom)}
          </div>
        )}
      </>
    )
  }

  return (
    <div
      className={classNames(className, styles.tile, {
        [styles.dragging]: draggingMode,
        [styles.selected]: active,
        [styles.draggable]: draggable,
        [styles.expanding]: mousePos,
        [styles.active]: !mousePos,
        [styles.moving]: moving,
        [styles.skeletonMode]: skeletonMode,
        [styles.small]: isSmallView,
        [styles.replaced]: isTileReplacingMany,
      })}
      data-active={active}
      style={{ transform: CSS.Transform.toString(transform), transition, width: tileWidth }}
      ref={(node) => {
        setNodeRef(node)
        tileRef.current = node
      }}
      data-tile={true}
      data-tile-id={id}
      data-disable-ids-click-outside-hook={[clickOutsideHookIds.footer]}
      onMouseDown={rememberMouseDown}
      onMouseUp={handleTileClick}
      {...(!isTablet ? attributes : {})}
      {...(!isTablet ? listeners : {})}
    >
      {renderTile()}
    </div>
  )
}

export default TimelineTile
