import React, { useEffect, useMemo } from 'react'

import PlayheadIcon from '../../../../assets/icons/playhead.svg'
import useSizes from '../../../../hooks/useSizes'
import { useEditMenu } from '../../hooks/useEditMenu'
import { usePlaybackTimePosition } from '../../hooks/usePlaybackTimePosition'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from '../Timeline.module.scss'

type Props = {
  getOffsetByBars: (bars: number) => number
  playheadRef: any
  barsSum: number
  scale: number
  draggingPosition?: number
  requestPosition?: () => number
  scrollContent: (val: number) => void
}

const Playhead: React.FC<Props> = ({
  draggingPosition,
  getOffsetByBars,
  scrollContent,
  scale,
  playheadRef,
  barsSum,
}) => {
  const { time: val } = usePlaybackTimePosition()
  const { isMobile } = useSizes()
  const { playheadOnChordId, setPlayheadOnChordId } = useEditMenu()
  const { playerConfig } = usePlayerConfigState()
  const length = useMemo(() => getOffsetByBars(barsSum), [barsSum, scale])
  useEffect(() => {
    if (isMobile) {
      scrollContent(getOffsetByBars(val))
    }

    const chords = playerConfig.currentPart.chords
    const currentProgress = val - 1
    const currentChord = chords.find((chord, index) => {
      const prevDuration = chords.slice(0, index).reduce((res, el) => (res += el.duration || 0), 0)

      return prevDuration <= currentProgress && prevDuration + chord.duration! > currentProgress
    })

    if (currentProgress === 0 && currentProgress !== playheadOnChordId) {
      setPlayheadOnChordId(-1)
      return
    }
    if (currentChord && currentChord.id !== playheadOnChordId) setPlayheadOnChordId(currentChord.id)
  }, [val, playerConfig.currentPart])

  return (
    <div
      style={{
        transform: isMobile
          ? undefined
          : `translateX(${draggingPosition || Math.max(0, Math.min(getOffsetByBars(val), length))}px)`,
        left: isMobile ? 'calc(50% - 1px)' : 8,
      }}
      ref={playheadRef}
      className={styles.playhead}
    >
      <PlayheadIcon className={styles.crown} />
    </div>
  )
}

export default Playhead
