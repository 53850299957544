import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import { useInternationalization } from '../../../../context/InternationalizationContext'
import useSizes from '../../../../hooks/useSizes'
import CenterModal from '../../../common/CenterModal'
import MobileSwipeableModal from '../../../common/MobileSwipeableModal/MobileSwipeableModal'
import styles from './PartsMenuEditNameModal.module.scss'

type Props = {
  onSave: (
    a: string,
    generate?: boolean,
    params?: {
      sameTempo?: boolean
      tempo?: string
      genre?: string
      scale?: string
    },
  ) => Promise<void>
  defaultName: string
  open: boolean
  onClose: () => void
}

const PartsMenuEditNameModal: React.FC<Props> = ({ onClose, open, defaultName, onSave }) => {
  const { text } = useInternationalization()

  const mobileSwipeableModalContentRef = useRef<HTMLDivElement>(null)

  const [name, setName] = useState('')
  const { isMobile } = useSizes()

  const STRINGS = text.partsMenu.renameModal

  useEffect(() => {
    if (open) {
      setName(defaultName || STRINGS.hotSuggestions[0])
      return
    }
    setName('')
  }, [open])
  const validateName = (name: string) => !!name && name !== 'null' && name !== 'undefined'
  const renderBody = () => {
    return (
      <div className={styles.wrapper} ref={mobileSwipeableModalContentRef}>
        <div className={styles.header}>
          <h2>{STRINGS.title}</h2>
        </div>
        <div
          className={styles.container}
          onKeyDown={(e) => {
            if (!validateName(name)) {
              return
            }
            if (e.key === 'Enter') {
              onSave(name)
            }
          }}
        >
          <label htmlFor='part-name-input'>
            {<p>{STRINGS.inputLabel}</p>}
            <input
              name='part-name'
              type='name'
              id='part-name-input'
              placeholder={STRINGS.placeholder}
              value={name}
              onChange={(e) => setName((e.target.value || '').slice(0, 40))}
            />
          </label>
          <div className={styles.suggestions}>
            {STRINGS.hotSuggestions.map((s: string) => (
              <span key={s} onClick={() => setName(s)} data-active={name === s}>
                {s}
              </span>
            ))}
          </div>
          <button className={styles.continueButton} disabled={!validateName(name)} onClick={() => onSave(name)}>
            {STRINGS.save}
          </button>
        </div>
      </div>
    )
  }
  if (isMobile) {
    return (
      <MobileSwipeableModal
        onClose={onClose}
        open={open}
        getHeight={() => mobileSwipeableModalContentRef.current?.clientHeight}
      >
        {renderBody()}
      </MobileSwipeableModal>
    )
  }
  return (
    <CenterModal visible={open} onCancel={onClose} size='tiny' closeIconClassName={classNames(styles.closeIconName)}>
      {renderBody()}
    </CenterModal>
  )
}

export default PartsMenuEditNameModal
