import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

import CloseIcon from '../../../assets/icons/close.svg'
import CheckIcon from '../../../assets/icons/green-check.svg'
import styles from './Popup.module.scss'

const Level = ['success'] as const

type Props = {
  open: boolean
  onClose: () => void
  level: typeof Level[number]
  text: string
}

const Popup: React.FC<Props> = ({ open, onClose, level, text }) => {
  const [init, setInit] = useState(false)
  useEffect(() => {
    open && setTimeout(onClose, 3000)
  }, [open])
  useEffect(() => {
    setInit(true)
  }, [])

  const getIconByType = () => {
    switch (level) {
      case 'success':
        return <CheckIcon />
      default:
        return null
    }
  }
  if (!init) {
    return null
  }
  return createPortal(
    <div
      className={styles.container}
      style={{
        left: open ? 0 : -400,
      }}
    >
      {getIconByType()} {text}
      <span onClick={onClose}>
        <CloseIcon />
      </span>
    </div>,
    document.getElementById('__next') as HTMLElement,
  )
}

export default Popup
