import { useDroppable } from '@dnd-kit/core'
import { usePinch } from '@use-gesture/react'
import dynamic from 'next/dynamic'
import { useRouter } from 'next/router'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'

import { getPreviewByIdKeyOrHashInnerRoute } from '../../../api/previews'
import { getDraftProjectInnerRoute, getProjectByIdInnerRoute, progressionJsonToProg } from '../../../api/projects'
import DrumsIcon from '../../../assets/icons/drums-add.svg'
import MelodyIcon from '../../../assets/icons/melody.svg'
import { useInternationalization } from '../../../context/InternationalizationContext'
import { LyricsProvider } from '../../../context/LyricsContext'
import { useProjectState } from '../../../context/ProjectStateContext'
import useSizes from '../../../hooks/useSizes'
import useWindowDimensions from '../../../hooks/useWIndowDimentions'
import styles from '../../../styles/Editor.module.scss'
import IOSAudioContextFix from '../../common/IOSAudioContextFix/IOSAudioContextFix'
import Footer from '../Footer/Footer'
import FooterMenu from '../Footer/FooterMenu'
import Header from '../Header/Header'
import LyricsEditor from '../LyricsEditor/LyricsEditor'
import PartsMenu from '../PartsMenu/PartsMenu'
import TimelineWrapper from '../TimelineWrapper/TimelineWrapper'
import { validateShortcut } from '../hooks/useEditMenu'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import MobileTabs from './MobileTabs/MobileTabs'
import { improveProgChords } from '../../../utils/chord-editor'

const Toastify = dynamic(() => import('../../common/Toastify/Toastify'))
const OnboardingSidebar = dynamic(() => import('../OnboardingSidebar/OnboardingSidebar'))

type Props = { setPageLoading: (a: boolean) => void; pageLoading: boolean }

const maxScale = 3
const minScale = 0.4

const EditorContainer: FC<Props> = ({ pageLoading, setPageLoading }) => {
  const router = useRouter()
  const { text } = useInternationalization()
  const { isMobile } = useSizes()
  const { width: windowWidth, height } = useWindowDimensions()
  const { isProjectSetuped, getDefaultProject, setIsProjectSetuped } = useProjectState()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { setNodeRef } = useDroppable({
    id: 'droppable',
  })

  const editorContainerRef = useRef<HTMLDivElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const timelineRef = useRef<HTMLDivElement>(null)
  const timelineGridRef = useRef<{
    performRedrawFrame: ({ scroll, scale }: { scroll: number; scale: number }) => void
  }>()

  const [hoverChords, setHoverChords] = useState(false)
  const [localPosition, setLocalPosition] = useState<number | null>(null)

  const [scroll, setScroll] = useState(0)
  const [scale, setScale] = useState(0.6)

  const urlParams = new URLSearchParams(router.asPath.split('?')[1])
  const id = urlParams.get('id')
  const previewId = urlParams.get('previewId')
  const paramsProg = urlParams.get('prog')
  const testInstrument = playerConfig.player.getTestInstrument

  useEffect(() => {
    ;(async () => {
      if (isProjectSetuped) return

      if (!id) {
        const query = {} as any
        const defaultProject = getDefaultProject()

        if (previewId) {
          const preview = await getPreviewByIdKeyOrHashInnerRoute(previewId)

          if (preview) playerConfigSetter.initProject({ ...defaultProject, ...preview }, true)
        } else if (paramsProg) {
          const progRaw = progressionJsonToProg(JSON.parse(paramsProg))
          const prog = await improveProgChords(progRaw)

          playerConfigSetter.initProject({ ...defaultProject, prog })
        } else {
          const { project } = await getDraftProjectInnerRoute()

          if (project) playerConfigSetter.initProject({ ...defaultProject, ...project })
          if (project?.id) query.id = project.id
        }

        await router.push(
          {
            pathname: router.pathname,
            query,
          },
          undefined,
          { shallow: true },
        )

        setPageLoading(false)
        setIsProjectSetuped(true)
        playerConfigSetter.setIsProgLoading(false)
        return
      }

      const project = await getProjectByIdInnerRoute(String(id))
      if (!project) {
        await router.push(
          {
            pathname: router.pathname,
            query: {},
          },
          undefined,
          { shallow: true },
        )
        return
      }
      playerConfigSetter.initProject(project)
      setPageLoading(false)
      playerConfigSetter.setIsProgLoading(false)
    })()
  }, [id, previewId, paramsProg])
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (validateShortcut('s', e)) e.preventDefault()

      const isModalOpen = document.getElementsByClassName('center-modal').length > 0
      const isTargetInput = e.target instanceof HTMLInputElement

      if (isModalOpen || isTargetInput) return

      if (e.key === ' ' && ((isMobile && playerConfig.view.mobileTab === 'chords') || !isMobile)) {
        testInstrument?.stop()
        playerConfigSetter.playControlsOnPlay()
      }
    }
    const onTouchMove = (e: TouchEvent) => e.preventDefault()
    document.addEventListener('keydown', onKeyDown)
    document.addEventListener('touchmove', onTouchMove)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
      document.removeEventListener('touchmove', onTouchMove)
    }
  }, [
    playerConfig.prog,
    playerConfig.isPlaying,
    JSON.stringify(playerConfig.activeChordIds),
    playerConfig.view.mobileTab,
    playerConfig.instrumentLoaded,
    playerConfig.melodyInstrumentLoaded,
    playerConfig.drumsInstrumentLoaded,
  ])
  useEffect(() => {
    const timelineWrap: HTMLElement = editorContainerRef.current!

    function listener(e: MouseEvent) {
      if (e.ctrlKey && !e.shiftKey) e.preventDefault()
    }

    timelineWrap.addEventListener('wheel', listener)
    return () => timelineWrap.removeEventListener('wheel', listener)
  }, [])
  useEffect(() => {
    redrawGrid({ scroll, scale })
  }, [windowWidth, playerConfig.prog, playerConfig.currentPartId])

  const redrawGrid = useCallback(
    ({ scroll, scale }: { scroll: number; scale: number }) =>
      timelineGridRef.current?.performRedrawFrame({ scroll, scale }),
    [scroll, scale],
  )
  const handleUpdateScaleAndScroll = ({
    newScale,
    newScroll,
    behavior,
  }: {
    newScale?: number
    newScroll?: number
    behavior?: 'smooth'
  }) => {
    let gridScroll = scroll,
      gridScale = scale
    if (newScale !== undefined) {
      setScale(newScale)
      gridScale = newScale
    }
    if (newScroll !== undefined) {
      if (isMobile) {
        timelineRef.current?.scrollTo({ left: newScroll, behavior })
      }
      const maxScroll = (timelineRef.current?.scrollWidth || 0) - (containerRef.current?.clientWidth || 0)
      const scrollBounded = Math.min(maxScroll, Math.max(0, newScroll))
      setScroll(scrollBounded)
      gridScroll = scrollBounded
    }
    redrawGrid({ scroll: gridScroll, scale: gridScale })
  }

  usePinch(
    ({ offset: [newScale], event }) => {
      if (!containerRef.current) return

      let newScroll = undefined

      if (event instanceof WheelEvent) {
        // 🖥️ Desktop Scroll
        const currWidthBetweenStartAndMouse = event.pageX - containerRef.current.offsetLeft + scroll
        const currWidthBetweenStartAndMouseScaled = (currWidthBetweenStartAndMouse / scale) * newScale
        const diffToMove = currWidthBetweenStartAndMouse - currWidthBetweenStartAndMouseScaled

        newScroll = scroll - diffToMove
      }

      handleUpdateScaleAndScroll({ newScale, newScroll })
    },
    {
      target: editorContainerRef,
      initialScale: 0.6,
      scaleBounds: { min: minScale, max: maxScale },
      from: () => [scale, 0],
    },
  )

  return (
    <div ref={setNodeRef} className={styles.container} tabIndex={0} onKeyUp={(e) => e.preventDefault()}>
      <IOSAudioContextFix audioRef={playerConfig.iOSAudioContextFixRef} />
      <Toastify />
      <Header progName={playerConfig.prog?.name || 'New Draft'} pageLoading={pageLoading} />
      <PartsMenu highlightPart={hoverChords} />

      <div
        ref={editorContainerRef}
        className={styles.center}
        /* need this attribute to catch double clicks */
        data-clickable-bg={true}
        style={{ maxHeight: (height || 0) - 72 }}
      >
        <TimelineWrapper
          hoverChords={hoverChords}
          setHoverChords={setHoverChords}
          onOpenCustomize={() => playerConfigSetter.setView({ editorOpen: true })}
          timelineData={{
            scroll,
            setScroll,
            scale,
            minScale,
            maxScale,
            handleUpdateScaleAndScroll,
            containerRef,
            timelineRef,
            timelineGridRef,
            redrawGrid,
          }}
        />
        {!playerConfig.currentPart.melodyShown && (
          <div className={styles.addDrums} onClick={playerConfigSetter.handleShowHideMelody}>
            <MelodyIcon />
            <span>{text.timeline.addMelody}</span>
          </div>
        )}
        {!playerConfig.currentPart.drumsShown && (
          <div className={styles.addDrums} onClick={playerConfigSetter.handleShowHideDrums}>
            <DrumsIcon />
            <span>{text.timeline.addDrums}</span>
          </div>
        )}
        {!playerConfig.currentPart?.draft && <FooterMenu />}
      </div>

      {isMobile ? (
        <MobileTabs />
      ) : (
        <>
          <LyricsProvider>
            <LyricsEditor />
          </LyricsProvider>
          <OnboardingSidebar />
        </>
      )}

      <Footer localPosition={localPosition} setLocalPosition={setLocalPosition} />
    </div>
  )
}

export default EditorContainer
