import Image from 'next/image'
import React from 'react'

import { useInternationalization } from '../../../../context/InternationalizationContext'
import styles from './EmptyChords.module.scss'

const EmptyChords = () => {
  const { text } = useInternationalization()

  const STRINGS = text.footer.guitarChords

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        <Image src='/static/images/generator/empty-guitar-chords.png' width={380} height={150} />
      </div>
      <div className={styles.title}>
        {STRINGS.emptyChordsTitle[0]}
        <br />
        <span>{STRINGS.emptyChordsTitle[1]}</span>
      </div>
    </div>
  )
}

export default EmptyChords
