import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import NotificationIcon from '../../../assets/icons/notification.svg'
import { useCommonModals } from '../../../context/CommonModalsContext'
import { useCurrentUser } from '../../../context/CurrentUserContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import { useProjectState } from '../../../context/ProjectStateContext'
import usePrevious from '../../../hooks/usePrevious'
import useSizes from '../../../hooks/useSizes'
import IconMenuButton from '../../common/IconMenuButton'
import Tooltip from '../../common/Tooltip/Tooltip'
import { INSTRUMENT_TYPES } from '../LayersOfInstruments/LayersOfInstruments'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import styles from './Footer.module.scss'

const STRINGS = {
  chordDetails: 'Chord Editor',
  insturments: 'Instruments',
  piano: 'Piano Roll',
  guitar: 'Guitar Fret',
  drumsEditor: 'Drums Editor',
  launchpadDesc: {
    use: 'Use',
    launchpad: ' Tools ',
    add: 'to add Drums, use Piano Roll or Chord Editor',
  },
}

const FooterMenu = () => {
  const { isMobile } = useSizes()
  const projectStateConfig = useProjectState()
  const modalsConfig = useCommonModals()
  const { currentUser } = useCurrentUser()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { text } = useInternationalization()

  const [isOnboardingNotificationOpen, setIsOnboardingNotificationOpen] = useState(false)
  const [wasLaunchpadOpened, setWasLaunchpadOpened] = useState(false)
  const prevOnboardingOpen = usePrevious(playerConfig.view.onboardingOpen)

  useEffect(() => {
    if (
      prevOnboardingOpen &&
      !playerConfig.view.onboardingOpen &&
      !isOnboardingNotificationOpen &&
      projectStateConfig.isProjectSetuped
    ) {
      setIsOnboardingNotificationOpen(true)

      setTimeout(() => {
        setIsOnboardingNotificationOpen(false)
      }, 5000)
    }
  }, [playerConfig.view.onboardingOpen, projectStateConfig.isProjectSetuped])

  const generatorOpen = playerConfig.view.generatorOpen
  const customizeOpen = playerConfig.view.editorOpen
  const instrumentOpen = playerConfig.view.notationOpen || playerConfig.view.guitarOpen || playerConfig.view.pianoOpen
  const drumsOpen = playerConfig.currPartDrumsOpen || !playerConfig.currentPart?.chords.length
  const simpleMobileLaunchpad =
    generatorOpen || drumsOpen || customizeOpen || instrumentOpen || currentUser?.prefs.quizTaken || wasLaunchpadOpened
  const renderMenuContainer = () => {
    return (
      <div className={styles.menuContainer}>
        {!playerConfig.view.layersOpen && (
          <IconMenuButton
            className={styles.pianoButton}
            icon='instruments'
            text={STRINGS.insturments}
            onClick={() => {
              playerConfigSetter.setView({ layersOpen: INSTRUMENT_TYPES.CHORDS, pianoOpen: true })
            }}
          />
        )}
        {!isMobile && !customizeOpen && (
          <IconMenuButton
            className={classNames(styles.customizeButton, {
              [styles.disabled]: projectStateConfig.projectEmpty,
            })}
            icon='chord-edit'
            text={STRINGS.chordDetails}
            onClick={() => {
              playerConfigSetter.setView({ editorOpen: true })
            }}
          />
        )}
        {!playerConfig.view.pianoOpen && (
          <IconMenuButton
            className={styles.pianoButton}
            icon='piano-roll'
            text={STRINGS.piano}
            onClick={() => {
              playerConfigSetter.setView({ pianoOpen: true })
            }}
          />
        )}
        {!playerConfig.view.guitarOpen && (
          <IconMenuButton
            className={styles.pianoButton}
            icon='guitar-fret'
            text={STRINGS.guitar}
            onClick={() => {
              playerConfigSetter.setView({ guitarOpen: true })
            }}
          />
        )}
        {!playerConfig.view.drumsEditorOpen && !isMobile && (
          <IconMenuButton
            className={styles.drumsEditorButton}
            icon='drums2'
            text={STRINGS.drumsEditor}
            onClick={() => {
              playerConfigSetter.setView({ drumsEditorOpen: true, drumsOpen: true })
            }}
          />
        )}
      </div>
    )
  }
  const renderBody = () => {
    if (isMobile) {
      return (
        <div className={styles.launchpadContainerMobile}>
          {!simpleMobileLaunchpad && (
            <p>
              {STRINGS.launchpadDesc.use} <span>{STRINGS.launchpadDesc.launchpad}</span> {STRINGS.launchpadDesc.add}
            </p>
          )}
          <div>
            <IconMenuButton
              onClick={() => {
                modalsConfig.setLaunchpadOpen(true)
                setWasLaunchpadOpened(true)
              }}
              icon='launchpad'
              className={styles.launchpadButtonMobile}
            />
            {!simpleMobileLaunchpad && (
              <>
                <div className={styles.ripple} />
                <div className={styles.ripple2} />
                <div className={styles.ripple3} />
              </>
            )}
          </div>
        </div>
      )
    }
    return (
      <>
        <div className={styles.stickyButtonsContainer} onClick={() => setIsOnboardingNotificationOpen(false)}>
          {!playerConfig.view.onboardingOpen && !isMobile && (
            <>
              <IconMenuButton
                className={classNames(styles.stickyButton, styles.onboarding)}
                icon='sidebar-onboarding'
                dataTooltipId='sidebar-onboarding'
                onClick={() => {
                  playerConfigSetter.setView({ onboardingOpen: true })
                }}
              />
              <Tooltip id='sidebar-onboarding' place='left'>
                <>{text.sidebars.tutorials.tooltip}</>
              </Tooltip>
              <Tooltip
                className={styles.onboardingFollowup}
                id='sidebar-onboarding'
                place='left'
                isOpen={isOnboardingNotificationOpen}
                noArrow
              >
                <>
                  <NotificationIcon />
                  <div className={styles.text}>{text.sidebars.tutorials.notification}</div>
                </>
              </Tooltip>
            </>
          )}
          {!playerConfig.view.lyricsOpen && !isMobile && (
            <>
              <IconMenuButton
                className={classNames(styles.stickyButton, styles.lyrics)}
                icon='sidebar-lyrics'
                dataTooltipId='sidebar-lyrics'
                onClick={() => {
                  playerConfigSetter.setView({ lyricsOpen: true })
                }}
              />
              <Tooltip id='sidebar-lyrics' place='left'>
                <>{text.sidebars.lyrics.tooltip}</>
              </Tooltip>
            </>
          )}
        </div>
        {/* {renderMenuContainer()} */}
      </>
    )
  }
  return (
    <div className={styles.bgTabMenu} data-unselect-chord={true} onClick={playerConfigSetter.handleUnselectChord}>
      <div className={styles.wrapper}>{renderBody()}</div>
    </div>
  )
}

export default FooterMenu
