import classNames from 'classnames'
import React from 'react'

import styles from './container.module.scss'

type Props = {
  className?: string
  children: React.ReactNode
}

const Container: React.FC<Props> = ({ className, children }) => {
  const classes = classNames(styles.container, className)

  return <div className={classes}>{children}</div>
}

export default Container
