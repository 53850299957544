import React, { ReactNode, useRef, useState } from 'react'

import useSizes from '../../../../../hooks/useSizes'
import CircleButton from '../../../../common/CircleButton'
import MobileSwipeableModal from '../../../../common/MobileSwipeableModal/MobileSwipeableModal'
import ChordKeyMenu from '../../../NewChordEditor/components/ChordKeyMenu'
import { usePlayerConfigState } from '../../../hooks/usePlayerConfigState'
import BpmSelect from '../BpmSelect'
import styles from './MobileSettingsMenu.module.scss'

type Props = {
  setTonalityKeyScale: (key: string, scale: string) => void
  renderMetronomeButton: () => ReactNode
}

const MobileSettingsMenu = ({ setTonalityKeyScale, renderMetronomeButton }: Props) => {
  const [mobileSettingsOpen, setMobileSettingsOpen] = useState(false)
  const { isMobile } = useSizes()
  const {
    playerConfig: { prog, bpm, isMetronome },
    playerConfigSetter: { setMetronome, setBpm },
  } = usePlayerConfigState()

  if (!isMobile) return null

  const mobileSwipeableModalContentRef = useRef<HTMLDivElement>(null)
  const renderMetronomeBlock = () => (
    <div className={styles.metronomeBlock}>
      <p>Metronome:</p>
      {renderMetronomeButton()}
    </div>
  )
  return (
    <>
      <CircleButton
        className={`${styles.metronomeButton} ${isMetronome ? styles.active : ''}`}
        size='medium'
        onClick={() => setMetronome(!isMetronome)}
        icon='metronome'
      />
      <MobileSwipeableModal
        onClose={() => setMobileSettingsOpen(false)}
        open={mobileSettingsOpen}
        getHeight={() => mobileSwipeableModalContentRef.current?.clientHeight}
      >
        <div className={styles.container} ref={mobileSwipeableModalContentRef}>
          {renderMetronomeBlock()}
          <BpmSelect bpm={bpm || 0} setBpm={setBpm} />
          <ChordKeyMenu
            chordKey={prog?.key || ''}
            chordScale={prog?.scale || ''}
            setKeyScale={setTonalityKeyScale}
            footerMode
          />
        </div>
      </MobileSwipeableModal>
    </>
  )
}

export default MobileSettingsMenu
