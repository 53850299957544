import classNames from 'classnames'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import * as Tone from 'tone'

import { useProjectState } from '../../../context/ProjectStateContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../hooks/useSizes'
import { barsBeatsSixteenthsToBars } from '../../../utils/audio/audioUtils'
import { getChordByIdFromProg, getPartLengthInBars, getPartOffsetInBars, isProgEmpty } from '../../../utils/progUtils'
import { getElementFromTree } from '../../../utils/timelineUtils'
import { ADDING_TILE_ID } from '../../../utils/types'
import Timeline from '../Timeline/Timeline'
import { useEditMenu } from '../hooks/useEditMenu'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import TimelineConfig from './TimelineConfig/TimelineConfig'
import styles from './TimelineWrapper.module.scss'

type Props = {
  hoverChords: boolean
  setHoverChords: (b: boolean) => void
  onOpenCustomize: () => void
  timelineData: {
    scroll: number
    setScroll: (v: number | ((v: number) => number)) => void
    scale: number
    minScale: number
    maxScale: number
    handleUpdateScaleAndScroll: ({
      newScale,
      newScroll,
      behavior,
    }: {
      newScale?: number
      newScroll?: number
      behavior?: 'smooth'
    }) => void
    containerRef: React.RefObject<HTMLDivElement>
    timelineRef: React.RefObject<HTMLDivElement>
    timelineGridRef: any
    redrawGrid: ({ scroll, scale }: { scroll: number; scale: number }) => void | undefined
  }
}

export type PlayheadControls = {
  setPlayheadKey: (key: number | null) => void
}

const TimelineWrapper: FC<Props> = ({ hoverChords, setHoverChords, onOpenCustomize, timelineData }) => {
  const { isMobile } = useSizes()
  const { handleShowMenu } = useEditMenu()
  const projectConfigState = useProjectState()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()

  const {
    prog,
    player,
    view,
    loadingTile,
    currentPartId,
    currentPart,
    editChordId,
    isPartLooped,
    isProgLoading,
    addChordMode,
    instrumentLoaded,
    drumsInstrumentLoaded,
    chordGenre,
    requestPosition,
    previewChord,
  } = playerConfig

  const { setActiveChordIds, setAddChordMode, setEditChordId, setView, onPlay } = playerConfigSetter

  const deactivateChordRef = useRef<HTMLDivElement>(null)

  const [renderTimeline, setRenderTimeline] = useState(false)
  // const [scale, setScale] = useState(0.6)

  const progEmpty = isProgEmpty(prog)
  const skeletonMode = isProgLoading || progEmpty
  const skeletonConfig = skeletonMode ? [1, 2, 3, 4] : []

  useClickOutsideAlerter(deactivateChordRef, () => setActiveChordIds([]), clickOutsideHookIds.timelineWrapper)

  useEffect(() => {
    setRenderTimeline(true)
  }, [])
  useEffect(() => {
    if (!view.editorOpen) {
      setAddChordMode(null)
    }
  }, [view.editorOpen])
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (view.lyricsOpen) {
        return
      }
      if (document.getElementsByClassName('center-modal').length > 0) {
        return
      }
      // @ts-ignore
      if (e.target?.nodeName === 'INPUT') {
        return
      }
      if (e.key === 'ArrowRight') {
        handleMovePlayhead(barsBeatsSixteenthsToBars(Tone.Transport.position.toString()) + 1.25)
      }
      if (e.key === 'ArrowLeft') {
        handleMovePlayhead(barsBeatsSixteenthsToBars(Tone.Transport.position.toString()) + 0.75)
      }
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [prog, currentPartId, view])

  const handleMovePlayhead = useCallback(
    (rawTime: number) => {
      const partOffset = getPartOffsetInBars(prog, currentPartId)
      const maxTime = partOffset + getPartLengthInBars(prog, currentPartId) + 1
      let time = Math.min(maxTime, Math.max(partOffset + 1, rawTime + partOffset))
      if (time === maxTime) {
        // need it to keep playhead at the end of timeline
        time = time - 0.01
      }
      time -= partOffset

      if (isPartLooped) {
        time -= 1
      } else {
        const { loop } = requestPosition()
        time = getPartOffsetInBars(prog, currentPartId, loop) + time - 1
      }

      const playChordFromNewPosition = player.setTime(time)
      playChordFromNewPosition()
    },
    [prog, currentPartId, isPartLooped],
  )

  return (
    <>
      <div className={styles.timelineContainer} id={clickOutsideHookIds.timelineWrapper}>
        <div id={'edit-menu-trigger'} className={styles.editMenuTrigger} onContextMenu={handleShowMenu} />
        <TimelineConfig />
        <div
          className={classNames(styles.timelineWrapper)}
          ref={deactivateChordRef}
          data-empty={projectConfigState.projectEmpty || currentPart?.draft}
        >
          {renderTimeline && (
            <Timeline
              {...timelineData}
              hoverChords={hoverChords}
              setHoverChords={setHoverChords}
              key={(prog?.componentKey || '') + currentPartId?.toString() + progEmpty.toString()}
              activeTile={addChordMode}
              playheadDisabled={skeletonMode}
              onPlayheadDoubleClick={() => {
                if (!instrumentLoaded || !drumsInstrumentLoaded) {
                  return
                }
                onPlay()
              }}
              tileClassName={(id) =>
                [
                  hoverChords ? styles.hoveredChord : styles.chord,
                  (isProgLoading && !getChordByIdFromProg(id, prog)?.locked) || id === loadingTile
                    ? styles.loadingChord
                    : '',
                ]
                  .filter(Boolean)
                  .join(' ')
              }
              onPlayheadMoved={(time) => {
                handleMovePlayhead(time)
              }}
              onEditTile={(e, id, magicChord) => {
                if (!magicChord) {
                  setActiveChordIds([id])
                  onOpenCustomize()
                  return
                }
                const tileElement = getElementFromTree(e, (a) => !!a?.getAttribute('data-tile'))
                if (!tileElement) return

                if (id === editChordId.id) {
                  setEditChordId({ id: null, center: null })
                  return
                }
                setActiveChordIds([id])
                setView({
                  editorOpen: true,
                  chordEditMode: view.chordEditMode || 'ai-suggestions',
                })
              }}
              onDoubleClickTile={() => {
                if (isMobile) return

                onOpenCustomize()
              }}
              onAddTile={async (e) => {
                if (!e.currentTarget || !chordGenre) return

                player.pause()
                setAddChordMode(ADDING_TILE_ID)

                if (view.editorOpen) return
                setView({
                  editorOpen: true,
                  chordEditMode: view.chordEditMode || 'ai-suggestions',
                })
              }}
              skeletonMode={loadingTile ? [loadingTile] : skeletonConfig}
            />
          )}
        </div>
      </div>
    </>
  )
}

export default TimelineWrapper
