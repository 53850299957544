import * as Sentry from '@sentry/nextjs'
import getConfig from 'next/config'
import React, { FC, useEffect } from 'react'

import { useCurrentUser } from '../../../context/CurrentUserContext'

const { publicRuntimeConfig } = getConfig() || {}

type Props = {
  distinctId?: string
}
const SentryScript: FC<Props> = ({ distinctId }) => {
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (currentUser || distinctId) {
      Sentry.setUser({
        plan: currentUser?.subscriptionPlan || '',
        email: currentUser?.email || '',
        name: currentUser?.name || '',
        id: currentUser?.id || distinctId || '',
      })
    }
  }, [currentUser, distinctId])
  useEffect(() => {
    Sentry.setTag('server', publicRuntimeConfig.SERVER_TAG)
  }, [])

  return <></>
}

export default SentryScript
