import classNames from 'classnames'
import dynamic from 'next/dynamic'
import React, { useEffect, useRef, useState } from 'react'
import { useLocalStorage } from 'usehooks-ts'

import Tab_4_icon from '../../../assets/icons/chord-editor/ai-suggestions-tab.svg'
import Tab_1_icon from '../../../assets/icons/chord-editor/chords-in-key-tab.svg'
// import Tab_3_icon from '../../../assets/icons/chord-editor/from-mic-tab.svg'
import Tab_2_icon from '../../../assets/icons/chord-editor/search-tab.svg'
import { useGeneratorModals } from '../../../context/GeneratorModalsContext'
import { useInternationalization } from '../../../context/InternationalizationContext'
import EmptyChords from '../../common/GuitarChords/EmptyChords/EmptyChords'
import IconMenuButton from '../../common/IconMenuButton'
import Switch from '../../common/Switch'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import styles from './ChordEditor.module.scss'
import Voicings from './Voicings/Voicings'
import ChordQuerySetup, { TChordEditorQueryConfig } from './components/ChordQuerySetup'
import { chordEditorLocalStorageKeys } from './data'

const ChordEditorChordsInKey = dynamic(() => import('./editors/ChordEditorChordsInKey'))
const ChordEditorSearch = dynamic(() => import('./editors/ChordEditorSearch'))
const ChordEditorAISuggestions = dynamic(() => import('./editors/ChordEditorAISuggestions'))

const tabs = [
  {
    icon: <Tab_1_icon />,
    text: 'Chords In-Key',
    value: 'chords-in-key',
  },
  {
    icon: <Tab_2_icon />,
    text: 'Search',
    value: 'search',
  },
  // TODO: uncomment once algo is ready
  // {
  //   icon: <Tab_3_icon />,
  //   text: 'From Mic',
  //   value: 'from-mic',
  // },
  {
    icon: <Tab_4_icon />,
    text: 'AI Suggestions',
    value: 'ai-suggestions',
  },
]

const ChordEditor = () => {
  const { text } = useInternationalization()
  const {
    playerConfig: { view, addChordMode, activeChord },
    playerConfigSetter: { setView },
  } = usePlayerConfigState()
  const { showRomanNumerals, setShowRomanNumerals } = useGeneratorModals()

  const tabsRef = useRef<HTMLDivElement>(null)

  const [activeTabMarkerStyle, setActiveTabMarkerStyle] = useState({ left: 0, width: 0 })
  const [selectedChordMenu, setSelectedChordMenu] = useLocalStorage(
    chordEditorLocalStorageKeys.selectedTab,
    'chords-in-key',
  )

  const [inKeyChordsConfig, setInKeyChordsConfig] = useState<TChordEditorQueryConfig | null>(null)
  const [searchChordsConfig, setSearchChordsConfig] = useState<TChordEditorQueryConfig | null>(null)
  const [aiSuggestionChordsConfig, setAiSuggestionChordsConfig] = useState<TChordEditorQueryConfig | null>(null)

  const STRINGS = text.footer.chordEditor

  useEffect(() => {
    const getActiveTabMarkerStyle = () => {
      const tabsContainer = tabsRef.current
      const activeTab = tabsRef.current?.querySelectorAll('[class*="activeTab"]')[0]

      if (!tabsContainer || !activeTab) return

      const { left: tabsContainerLeft } = tabsContainer.getBoundingClientRect()
      const { left: activeTabLeft } = activeTab.getBoundingClientRect()

      const left = activeTabLeft - tabsContainerLeft
      const width = activeTab.clientWidth

      setActiveTabMarkerStyle({ left, width })
    }

    getActiveTabMarkerStyle()

    window.addEventListener('resize', getActiveTabMarkerStyle)
    return () => window.removeEventListener('resize', getActiveTabMarkerStyle)
  }, [selectedChordMenu, activeChord])

  const renderLeftMenu = () => (
    <div className={styles.leftMenu}>
      <IconMenuButton
        icon='chords-palette'
        selected={view.chordEditMode !== 'voicings'}
        text={STRINGS.leftMenu.chordPalette}
        className={styles.modeButton}
        onClick={() => setView({ editorOpen: true, chordEditMode: selectedChordMenu as any })}
      />
      <IconMenuButton
        icon='voicings'
        text={STRINGS.leftMenu.voicings}
        selected={view.chordEditMode === 'voicings'}
        className={styles.modeButton}
        onClick={() => setView({ editorOpen: true, chordEditMode: 'voicings' })}
      />
    </div>
  )

  const renderContentHeader = () => {
    return (
      <div className={styles.contentHeader}>
        <div className={styles.tabs} ref={tabsRef}>
          {tabs.map((tab) => (
            <div
              key={tab.value}
              className={classNames(styles.tab, { [styles.activeTab]: selectedChordMenu === tab.value })}
              onClick={() => setSelectedChordMenu(tab.value)}
            >
              {tab.icon}
              <span>{tab.text}</span>
            </div>
          ))}
          <div style={activeTabMarkerStyle} className={styles.activeTabMarker} />
        </div>

        <div className={styles.showRomanNumerals}>
          <span className={styles.showRomanNumeralsText}>{STRINGS.showRomanNumerals}</span>

          <Switch
            className={styles.showRomanNumeralsSwitch}
            activeClassName={styles.showRomanNumeralsSwitchActive}
            value={showRomanNumerals}
            onChange={() => setShowRomanNumerals((v) => !v)}
          />
        </div>
      </div>
    )
  }
  const renderContentContent = () => {
    switch (selectedChordMenu) {
      case 'chords-in-key':
        if (!inKeyChordsConfig) return null
        return <ChordEditorChordsInKey {...inKeyChordsConfig} />
      case 'search':
        if (!searchChordsConfig) return null
        return <ChordEditorSearch {...searchChordsConfig} />
      case 'from-mic':
        return <></>
      case 'ai-suggestions':
        if (!aiSuggestionChordsConfig) return null
        return <ChordEditorAISuggestions {...aiSuggestionChordsConfig} />
      default:
        return <></>
    }
  }
  const renderContent = () => {
    if (!activeChord && !addChordMode) return <EmptyChords />

    if (view.chordEditMode === 'voicings') return <Voicings />

    return (
      <div className={styles.contentContainer}>
        {renderContentHeader()}

        <div className={styles.contentContent}>{renderContentContent()}</div>
      </div>
    )
  }

  return (
    <>
      <ChordQuerySetup
        selectedChordMenu={selectedChordMenu}
        inKeyChordsConfig={inKeyChordsConfig}
        setInKeyChordsConfig={setInKeyChordsConfig}
        searchChordsConfig={searchChordsConfig}
        setSearchChordsConfig={setSearchChordsConfig}
        aiSuggestionChordsConfig={aiSuggestionChordsConfig}
        setAiSuggestionChordsConfig={setAiSuggestionChordsConfig}
      />

      <div className={styles.container}>
        {renderLeftMenu()}

        <div className={styles.divider} />

        {renderContent()}
      </div>
    </>
  )
}

export default ChordEditor
