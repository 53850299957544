import classNames from 'classnames'
import React, { useEffect, useState } from 'react'

import CloneIcon from '../../../../assets/icons/clone.svg'
import MenuIcon from '../../../../assets/icons/dots-menu-vtwo.svg'
import RepeatIcon from '../../../../assets/icons/infinite-repeat.svg'
import MinusIcon from '../../../../assets/icons/minus.svg'
import PencilIcon from '../../../../assets/icons/pencil.svg'
import PlusIcon from '../../../../assets/icons/plus.svg'
import DeleteIcon from '../../../../assets/icons/trash.svg'
import { useGeneratorModals } from '../../../../context/GeneratorModalsContext'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import { FRONTEND_LIMIT_TYPES, useLimits } from '../../../../context/LimitsContext'
import { useProjectState } from '../../../../context/ProjectStateContext'
import useDebounce from '../../../../hooks/useDebounce'
import useSizes from '../../../../hooks/useSizes'
import { incrementPartLoop } from '../../../../utils/progUtils'
import { Prog, ProgPart } from '../../../../utils/types'
import Checkbox from '../../../common/Checkbox/Checkbox'
import DropdownMenu from '../../../common/DropdownMenu/DropdownMenu'
import SmallRangeSelector from '../../../common/SmallRangeSelector/SmallRangeSelector'
import Tooltip from '../../../common/Tooltip/Tooltip'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './PartsMenuItemDropdown.module.scss'

type Props = {
  item: ProgPart
  dropDownTriggerClassName: string
  duplicateDisabled?: boolean
  isShort?: boolean
  disabled?: boolean
  onEditName?: () => void
  setMenuOpened?: (a: boolean) => void
  menuOpened?: boolean
}

const PartsMenuItemDropdown: React.FC<Props> = ({
  dropDownTriggerClassName,
  item,
  disabled,
  onEditName,
  setMenuOpened,
  isShort,
  duplicateDisabled,
  menuOpened,
}) => {
  const { isPartsLimited, triggerLimitCallback } = useLimits()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { text } = useInternationalization()
  const { isMobile } = useSizes()
  const generatorModals = useGeneratorModals()
  const { duplicatePartToProg, removePartFromProg } = useProjectState()

  const [isLoading, setIsLoading] = useState(true)
  const [loops, setLoops] = useState(0)

  const debouncedLoops = useDebounce(loops)

  const isLimited = isPartsLimited(playerConfig.prog?.parts || [])
  const currentPartIdLooped = item.id === playerConfig.isOnRepeat
  const loopsDisabled = item.draft
  const STRINGS = text.partsMenu

  useEffect(() => {
    if (isLoading) setLoops(item.loops)
  }, [item, isLoading])

  useEffect(() => {
    if (isLoading || debouncedLoops !== loops) return
    const newProg = incrementPartLoop(playerConfig.prog, item.id, debouncedLoops - item.loops)
    playerConfigSetter.setProg(newProg)
    playerConfigSetter.setCurrentPartId(undefined, undefined, newProg as Prog)
  }, [debouncedLoops, isLoading])

  return (
    <>
      <div onClick={(e) => e.stopPropagation()} className={classNames(styles.dropDown, { [styles.short]: isShort })}>
        <DropdownMenu
          openSubOnHover
          disabled={disabled}
          mobileCollisionOffset={160}
          onMenuOpen={setMenuOpened}
          menuOpened={menuOpened}
          headerContent={
            loopsDisabled ? undefined : (
              <>
                <div className={styles.loopsMenu}>
                  <SmallRangeSelector
                    min={1}
                    max={20}
                    value={loops}
                    step={1}
                    leftIcon={<MinusIcon />}
                    rightIcon={<PlusIcon />}
                    formatValue={(val) => (
                      <span className={styles.loopsMenuText}>
                        {STRINGS.loops} <span>{val}</span>
                      </span>
                    )}
                    controlClassName={styles.loopsMenuControl}
                    onChange={(value) => {
                      if (isLoading) setIsLoading(false)
                      setLoops(value)
                    }}
                  />
                </div>
              </>
            )
          }
          options={[
            [
              ...(loopsDisabled
                ? []
                : [
                    {
                      name: STRINGS.loopSolo,
                      icon: <RepeatIcon />,
                      dontCloseOnSelect: true,
                      rightIcon: (
                        <Checkbox checked={currentPartIdLooped} secondary className={styles.loopOnlyCheckbox} />
                      ),
                      className: isShort ? undefined : styles.loopOnlyOption,
                      key: 10,
                      onClick: () => {
                        const isAlreadyLoopSolo = playerConfig.isOnRepeat === item.id

                        playerConfigSetter.setCurrentPartId(item.id, isAlreadyLoopSolo ? 'whole' : 'part')
                      },
                      disabled: false,
                    },
                  ]),
              ...(isShort
                ? []
                : [
                    {
                      name: STRINGS.rename,
                      icon: <PencilIcon />,
                      key: 1,
                      onClick: onEditName,
                      disabled: false,
                      dontCloseOnSelect: true,
                    },
                    ...(duplicateDisabled
                      ? []
                      : [
                          {
                            name: STRINGS.duplicate,
                            icon: <CloneIcon />,
                            key: 2,
                            onClick: () => {
                              triggerLimitCallback(isLimited, FRONTEND_LIMIT_TYPES.parts)
                              if (isLimited) return

                              generatorModals.portalsConfig.setRandomInstrument(false)
                              generatorModals.portalsConfig.setRandomDrumInstrument(false)

                              const newProg = duplicatePartToProg(playerConfig.prog, item.id)
                              if (!newProg) return

                              playerConfigSetter.setProg(newProg, true)
                            },
                          },
                        ]),
                    {
                      name: STRINGS.delete,
                      icon: <DeleteIcon />,
                      key: 4,
                      disabled: playerConfig.prog?.parts.length === 1 && playerConfig.currentPart?.draft,
                      onClick: () => {
                        playerConfigSetter.setProg(removePartFromProg(playerConfig.prog, item.id))
                      },
                    },
                  ]),
            ],
          ]}
          renderTrigger={(onClick, opened) => {
            const menuId = `${item.id}-part-menu-${disabled}`
            return (
              <>
                {!opened && !isMobile && <Tooltip id={menuId} text={STRINGS.tooltip} place='top' />}
                {isShort ? (
                  <div
                    className={dropDownTriggerClassName}
                    data-opened={opened}
                    onClick={(e) => {
                      e.stopPropagation()
                      if (currentPartIdLooped) {
                        playerConfigSetter.setCurrentPartId(playerConfig.currentPartId, 'whole')
                        return
                      }
                      onClick()
                    }}
                  >
                    {currentPartIdLooped ? <RepeatIcon /> : item.loops}
                  </div>
                ) : (
                  <div
                    className={styles.triggerWrapper}
                    onMouseEnter={(e) => e.stopPropagation()}
                    onClick={(e) => {
                      e.stopPropagation()
                      onClick()
                    }}
                  >
                    <span
                      data-tooltip-id={disabled ? undefined : menuId}
                      data-opened={opened}
                      className={dropDownTriggerClassName}
                    >
                      <MenuIcon />
                    </span>
                  </div>
                )}
              </>
            )
          }}
        />
      </div>
    </>
  )
}

export default PartsMenuItemDropdown
