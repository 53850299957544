import { useRef, useState } from 'react'

import { usePlayerConfigState } from '../../../hooks/usePlayerConfigState'

const useMobileScrollPlaying = () => {
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()

  const mobileScrollingRef = useRef()
  const [mobileScrolling, setMobileScrolling] = useState(false)
  const [mobileScrolling2, setMobileScrolling2] = useState(false)
  const [mobileScrolling3, setMobileScrolling3] = useState(false)
  const interruptMobileScroll = () => {
    setMobileScrolling(false)
    setMobileScrolling2(false)
    setMobileScrolling3(false)
    // @ts-ignore
    mobileScrollingRef.current?.forEach((cb) => clearTimeout(cb))
  }

  const handleStopManualScroll = () => {
    if (playerConfig.isPlayingBeforeManualScroll) {
      playerConfig.player.play()
      playerConfigSetter.setIsPlayingBeforeManualScroll(false)
    }
    setMobileScrolling3(false)
    // @ts-ignore
    mobileScrollingRef.current = [
      setTimeout(() => setMobileScrolling(false), 5000),
      setTimeout(() => setMobileScrolling2(false), 1500),
    ]
  }
  const handleStartManualScroll = () => {
    // @ts-ignore
    mobileScrollingRef.current?.forEach((cb) => clearTimeout(cb))
    setMobileScrolling(true)
    setMobileScrolling2(true)
    setMobileScrolling3(true)
    playerConfigSetter.setIsPlayingBeforeManualScroll(playerConfig.isPlaying)
    playerConfig.player.pause()
  }

  return {
    handleStartManualScroll,
    handleStopManualScroll,
    interruptMobileScroll,
    mobileScrolling,
    mobileScrollingFast: mobileScrolling2,
    mobileScrollingInstant: mobileScrolling3,
  }
}

export default useMobileScrollPlaying
