import {
  closestCenter,
  DndContext,
  DragOverlay,
  MeasuringStrategy,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { restrictToHorizontalAxis } from '@dnd-kit/modifiers'
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import classNames from 'classnames'
import React, { FC, useEffect, useRef, useState } from 'react'

import ArrowIcon from '../../../assets/icons/simple-arrow.svg'
import useSizes from '../../../hooks/useSizes'
import useWindowDimensions from '../../../hooks/useWIndowDimentions'
import { getTimelineWidthChanges } from '../../../utils/timelineUtils'
import {
  trackMixpanelEvent_addChordPlusClick,
  trackMixpanelEvent_addDrumsPlusClick,
  trackMixpanelEvent_addMelodyPlusClick,
} from '../../../utils/tracking'
import { ADDING_TILE_ID } from '../../../utils/types'
import PartsMenu from '../PartsMenu/PartsMenu'
import GenerateTrackButton from '../TimelineWrapper/TimelineConfig/GenerateTrackButton/GenerateTrackButton'
import TimelineControls from '../TimelineWrapper/TimelineControls/TimelineControls'
import TimelineScrollbar from '../TimelineWrapper/TimelineScrollbar/TimelineScrollbar'
import { useEditMenu } from '../hooks/useEditMenu'
import { usePlayerConfigState } from '../hooks/usePlayerConfigState'
import Playhead from './Playhead/Playhead'
import PlayheadZone from './PlayheadZone/PlayheadZone'
import TimelinePointer from './Sensors/TimelinePointer'
import TimelineToucher from './Sensors/TimelineToucher'
import styles from './Timeline.module.scss'
import TimelineGrid from './TimelineGrid/TimelineGrid'
import TimelineAdder from './TimelineTile/TimelineAdder'
import TimelineDrum from './TimelineTile/TimelineDrum'
import TimelineTile from './TimelineTile/TimelineTile'
import useMobileScrollPlaying from './hooks/useMobileScrollPlaying/useMobileScrollPlaying'

type Props = {
  hoverChords: boolean
  setHoverChords: (b: boolean) => void
  className?: string
  playheadDisabled?: boolean
  initialWidth?: number
  pixelsPerBeat?: number
  scaleStep?: number
  activeTile?: number | null
  skeletonMode?: Array<number>
  onAddTile?: (e: React.MouseEvent) => Promise<void>
  onPlayheadMoved?: (time: number) => void
  onPlayheadDoubleClick?: () => void
  onDoubleClickTile?: (id: number) => void
  onEditTile?: (e: React.MouseEvent, id: number, magicChord: boolean) => void
  tileClassName?: (id: number) => string
  // timelineData
  scroll: number
  setScroll: (v: number | ((v: number) => number)) => void
  scale: number
  minScale: number
  maxScale: number
  handleUpdateScaleAndScroll: ({
    newScale,
    newScroll,
    behavior,
  }: {
    newScale?: number
    newScroll?: number
    behavior?: 'smooth'
  }) => void
  containerRef: React.RefObject<HTMLDivElement>
  timelineRef: React.RefObject<HTMLDivElement>
  timelineGridRef: any
  redrawGrid: ({ scroll, scale }: { scroll: number; scale: number }) => void | undefined
}

const Timeline: FC<Props> = ({
  className = '',
  initialWidth = 10,
  pixelsPerBeat = 220,
  scaleStep = 0.2,
  activeTile = null,
  tileClassName = () => '',
  onPlayheadMoved = () => {},
  onDoubleClickTile = () => {},
  onEditTile = () => {},
  skeletonMode = [],
  onAddTile,
  playheadDisabled,
  onPlayheadDoubleClick,
  hoverChords,
  setHoverChords,
  // timelineData
  scroll,
  setScroll,
  scale,
  minScale,
  maxScale,
  handleUpdateScaleAndScroll,
  containerRef,
  timelineRef,
  timelineGridRef,
  redrawGrid,
}) => {
  const { isMobile } = useSizes()
  const { tiles, widths, setWidths, setTiles, handleShowMenu, handleHideMenu, addTileId } = useEditMenu()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const mobileScrollPlaying = useMobileScrollPlaying()
  const { width: windowWidth } = useWindowDimensions()

  const { currentPart } = playerConfig

  const melodyEmpty = !currentPart?.melody
  const melodyWidth = currentPart?.melody?.length
  const drumsEmpty = !currentPart?.drums
  const drumsWidth = currentPart?.drums?.length
  const initialScreen = currentPart?.draft || currentPart === undefined

  // refs
  const addButtonRef = useRef()
  const generateButtonRef = useRef<HTMLDivElement>(null)
  const chromeRef = useRef<HTMLDivElement>(null)
  const playheadRef = useRef(null)
  const scrollWhileDraggingTimeoutRef = useRef<number | null>(null)

  // timeline state
  const [held, setHeld] = useState<(number | UniqueIdentifier)[]>([])
  const [heldToPaste, setHeldToPaste] = useState<(number | UniqueIdentifier)[]>([])
  const [extending, setExtending] = useState(false)
  const [scalingConfig, setScalingConfig] = useState<{ scroll: number; scale: number; pinching?: number } | null>(null)
  const [draggingChordId, setDraggingChordId] = useState<UniqueIdentifier | null>(null)
  const [resizingChordId, setResizingChordId] = useState<UniqueIdentifier | null>(null)
  const [addTileScroll, setAddTileScroll] = useState(false)
  const [increment, setIncrement] = useState(1)
  const [widthChanges, setWidthChanges] = useState<{ [key: UniqueIdentifier]: number | null }>({})

  const [playheadDraggingPos, setPlayheadDraggingPos] = useState<number | null>(null)
  const [isPartsMenuOpen, setIsPartsMenuOpen] = useState(false)

  const scrollWhileDraggingRef = useRef(scroll)
  const barsSum = Math.max(drumsWidth || 0, melodyWidth || 0, Object.values(widths).reduce((a, b) => a + b, 0) + 1)

  const mobileSensors = useSensors(useSensor(TimelineToucher))
  const sensors = useSensors(
    useSensor(TimelinePointer, {
      activationConstraint: {
        distance: 15,
      },
    }),
    useSensor(TimelineToucher),
  )

  useEffect(() => {
    if (playerConfig.isPlaying) {
      mobileScrollPlaying.interruptMobileScroll()
      setAddTileScroll(false)
    }
  }, [playerConfig.isPlaying])

  const gestures = {
    onTouchStart: (e: React.TouchEvent<HTMLDivElement>) => {
      if (e.touches.length === 1) {
        mobileScrollPlaying.handleStartManualScroll()
      }

      if (e.touches.length === 2) {
        setScalingConfig({
          pinching: Math.abs(e.touches[0].clientX - e.touches[1].clientX),
          scale,
          scroll: timelineRef.current!.scrollLeft,
        })
      }
    },

    onTouchMove: ({ touches }: React.TouchEvent<HTMLDivElement>) => {
      if (touches.length !== 2) {
        setScalingConfig(null)
      }
    },

    onTouchEnd: () => {
      setScalingConfig(null)
      mobileScrollPlaying.handleStopManualScroll()
    },

    onWheel: (e: React.WheelEvent<HTMLDivElement>) => {
      if (isMobile) return

      if (!e.ctrlKey || e.shiftKey) {
        handleUpdateScaleAndScroll({ newScroll: scroll + (e.deltaX || e.deltaY / 2) })
      }
    },

    onScroll: (e: React.TouchEvent<HTMLDivElement>) => {
      if (!isMobile || scalingConfig?.pinching || playheadDisabled || !mobileScrollPlaying.mobileScrolling) return

      const offset = e.currentTarget.scrollLeft
      const playheadPosition = (1 / 16) * Math.round((offset / scale / pixelsPerBeat + 1) * 16)

      onPlayheadMoved(playheadPosition)
      handleHideMenu()
    },
  }

  const pxToBarsWidth = (px: number) => {
    return px / (pixelsPerBeat * scale)
  }

  const startAutoScroll = (diff: number) => {
    if (scrollWhileDraggingTimeoutRef.current) {
      return
    }
    scrollWhileDraggingTimeoutRef.current = setInterval(() => {
      setScroll((prev) => {
        const newScroll = Math.min(Math.max(0, diff + prev))
        redrawGrid({ scroll: newScroll, scale })
        return newScroll
      })
    }, 20) as unknown as number
  }
  const stopAutoScroll = () => {
    if (scrollWhileDraggingTimeoutRef.current) {
      clearInterval(scrollWhileDraggingTimeoutRef.current)
      scrollWhileDraggingTimeoutRef.current = null
    }
  }
  const handleDragPlayhead = (e: any, zoneRef: React.RefObject<HTMLDivElement>, click?: boolean) => {
    if (e.detail === 2) {
      onPlayheadDoubleClick && onPlayheadDoubleClick()
      return
    }
    setPlayheadDraggingPos(e.pageX - containerRef.current!.offsetLeft - 4)
    const offset = e.pageX - (timelineRef.current?.getBoundingClientRect().left || 0) - 4
    const playheadPosition = offset / scale / pixelsPerBeat + 1
    if (offset - 100 < scroll && !click) {
      startAutoScroll(-10)
    } else if (offset + 100 >= scroll + (containerRef.current?.clientWidth || 0) && !click) {
      startAutoScroll(10)
    } else {
      stopAutoScroll()
    }
    if (onPlayheadMoved) {
      onPlayheadMoved(playheadPosition)
    }
  }
  const handleZoomInOut = (delta: number) => {
    if (!containerRef.current) return

    const newScale = scaleCutoff(scale + delta)

    const currWidthBetweenStartAndMouse = containerRef.current.clientWidth / 2 + scroll
    const currWidthBetweenStartAndMouseScaled = (currWidthBetweenStartAndMouse / scale) * newScale
    const diffToMove = currWidthBetweenStartAndMouse - currWidthBetweenStartAndMouseScaled
    const newScroll = scroll - diffToMove

    handleUpdateScaleAndScroll({ newScale, newScroll })
  }

  const renderPlayhead = () => (
    <Playhead
      draggingPosition={
        mobileScrollPlaying.mobileScrollingInstant && playheadDraggingPos !== null
          ? Math.max(0, playheadDraggingPos!) + scroll || undefined
          : undefined
      }
      playheadRef={playheadRef}
      scale={scale}
      getOffsetByBars={(bars: number) => scale * pixelsPerBeat * ((bars ?? 1) - 1)}
      barsSum={Math.max((drumsWidth || 0) + 1, (melodyWidth || 0) + 1, barsSum)}
      scrollContent={(val: number) => {
        if (mobileScrollPlaying.mobileScrollingFast || addTileScroll || addTileId) {
          return
        }
        handleUpdateScaleAndScroll({ newScroll: val })
      }}
    />
  )

  const getSnapPosition = (width: number, increase: number | null) => {
    if (!increase) return 0

    const division = 1 / increment
    const resizePositionInPx = width + increase
    const distanceFromDivision = pxToBarsWidth(resizePositionInPx) % division

    const shouldSnapp = Math.min(distanceFromDivision, division - distanceFromDivision) / division < 0.2
    const snappPositionInPx = shouldSnapp
      ? Math.max(Math.round(pxToBarsWidth(resizePositionInPx) / division) * division, division) * pixelsPerBeat * scale
      : resizePositionInPx

    return Math.round(increase + (snappPositionInPx - resizePositionInPx))
  }
  const setRef = (el: any, ref: React.RefObject<HTMLDivElement>) => {
    // @ts-ignore
    ref.current = el
  }

  const chromeWidth = initialScreen ? '100%' : Math.max(initialWidth, barsSum + 5) * pixelsPerBeat * scale

  return (
    <div className={className} ref={(el) => setRef(el, containerRef)}>
      {containerRef.current && (
        <TimelineControls
          setScroll={(newScroll) => handleUpdateScaleAndScroll({ newScroll, behavior: 'smooth' })}
          containerRef={containerRef}
          scroll={scroll}
          scale={scale}
          onZoomOut={() => handleZoomInOut(scaleStep * -1)}
          onZoomIn={() => handleZoomInOut(scaleStep)}
          isMinScale={scale === minScale}
          isMaxScale={scale === maxScale}
        />
      )}
      <TimelineGrid
        setIncrement={setIncrement}
        isRenderOnboarding={false}
        chromeRef={chromeRef}
        containerRef={containerRef}
        pixelsPerBeat={pixelsPerBeat}
        ref={(el) => setRef(el, timelineGridRef)}
        timelineRef={timelineRef}
        scale={isMobile ? scale : undefined}
      />
      <div
        className={styles.overflow}
        {...(initialScreen ? {} : gestures)}
        style={
          isMobile
            ? undefined
            : {
                left: -scroll,
                width: `calc(100% + ${scroll}px)`,
              }
        }
        ref={(el) => setRef(el, timelineRef)}
        data-not-scrollable={!!initialScreen}
        data-pinching={!!scalingConfig?.pinching || extending}
        data-unselect-chord={true}
        onClick={playerConfigSetter.handleUnselectChord}
      >
        <DndContext
          sensors={window.matchMedia('(pointer: coarse)').matches ? mobileSensors : sensors}
          collisionDetection={(args) => {
            if (isMobile) {
              return closestCenter(args)
            }
            args.pointerCoordinates!.x = Math.min(args.pointerCoordinates!.x, containerRef.current!.clientWidth)
            const calcDiff = (id: UniqueIdentifier) => {
              if (id === ADDING_TILE_ID) return Infinity
              const tileCenter = args.droppableRects.get(id)!.left + args.droppableRects.get(id)!.width / 2
              const mousePos = args.pointerCoordinates!.x + (scroll - scrollWhileDraggingRef.current)
              return Math.abs(mousePos - tileCenter)
            }
            return args.droppableContainers
              .map((c) => ({
                data: { droppableContainer: c, value: calcDiff(c.id) },
                id: c.id,
              }))
              .sort((a, b) => a.data.value - b.data.value)
          }}
          cancelDrop={({ active }) => {
            setHeld([+active.id])

            return false
          }}
          onDragStart={({ active }) => {
            if (!active) return

            scrollWhileDraggingRef.current = scroll
            const id = +active.id
            const { activeChordIds } = playerConfig
            const isDraggingSelected = activeChordIds.includes(id)

            const tilesBeforeThis = tiles
              .slice(0, tiles.indexOf(id))
              .filter((el) => !activeChordIds.includes(+el) || !isDraggingSelected)
            const tilesAfterThis = tiles
              .slice(tiles.indexOf(id) + 1)
              .filter((el) => !activeChordIds.includes(+el) || !isDraggingSelected)

            const newTiles = [...tilesBeforeThis, id, ...tilesAfterThis]
            const newHeld = activeChordIds.includes(id) ? tiles.filter((t) => activeChordIds.includes(+t)) : [id]

            setTiles(newTiles)
            setHeld(newHeld)
            setHeldToPaste(newHeld)
            setDraggingChordId(id)
          }}
          onDragEnd={({ active, over }) => {
            setTimeout(() => {
              stopAutoScroll()
            }, 10)

            const st = tiles.indexOf(active.id as number)
            const nd = tiles.indexOf(over ? over.id : active.id)

            const newTilesRaw = arrayMove(tiles, st, nd)
            const newTiles = Array.from(
              new Set([...newTilesRaw.slice(0, nd), ...heldToPaste, ...newTilesRaw.slice(nd + 1)]),
            )

            setTiles(newTiles)
            setHeld([])
            setHeldToPaste([])
            setDraggingChordId(null)

            playerConfigSetter.handleTimelineChange({
              tiles: newTiles as number[],
              widths,
            })
          }}
          autoScroll={
            isMobile
              ? {
                  threshold: {
                    x: 0.2,
                    y: 0,
                  },
                }
              : undefined
          }
          onDragMove={(e) => {
            if (isMobile) {
              return
            }
            // @ts-ignore
            const pos = e.activatorEvent.x + e.delta.x

            if (pos + 150 > windowWidth!) {
              startAutoScroll(10)
            } else if (pos - 150 < containerRef.current!.offsetLeft) {
              startAutoScroll(-10)
            } else {
              stopAutoScroll()
            }
          }}
          onDragCancel={() => {
            stopAutoScroll()
            setHeld([])
            setDraggingChordId(null)
          }}
          measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
          modifiers={isMobile ? [restrictToHorizontalAxis] : []}
        >
          <PlayheadZone
            mobileScrollPlaying={{
              ...mobileScrollPlaying,
              handleStopManualScroll: () => {
                mobileScrollPlaying.handleStopManualScroll()
                stopAutoScroll()
                setPlayheadDraggingPos(null)
              },
            }}
            handleDragPlayhead={handleDragPlayhead}
            playheadDisabled={playheadDisabled}
          />

          <div
            className={styles.chrome}
            data-track={true}
            style={{ width: chromeWidth }}
            ref={chromeRef}
            data-unselect-chord={true}
            onClick={playerConfigSetter.handleUnselectChord}
            onContextMenu={handleShowMenu}
          >
            {isMobile ? (
              <>
                <div className={styles.partsMenuTrigger} onClick={() => setIsPartsMenuOpen(true)}>
                  <span>{playerConfig.currentPart.name}</span>
                  <ArrowIcon />
                </div>
                <PartsMenu highlightPart={false} open={isPartsMenuOpen} onClose={() => setIsPartsMenuOpen(false)} />
              </>
            ) : null}

            <SortableContext items={[...tiles, ADDING_TILE_ID]} strategy={horizontalListSortingStrategy}>
              {isMobile && <GenerateTrackButton type='CHORDS' ref={generateButtonRef} />}
              {tiles.map((tile, index) => (
                <TimelineTile
                  key={tile}
                  id={tile}
                  setExtending={setExtending}
                  isLast={tiles.length === index + 1}
                  className={tileClassName(tile as number)}
                  index={index}
                  widthChanges={widthChanges}
                  setWidthChanges={(delta: number | null, resizeSide: 'left' | 'right' | null) => {
                    setWidthChanges((v) => getTimelineWidthChanges(tiles, index, v, delta, resizeSide))
                  }}
                  active={activeTile == (tile as number) || playerConfig.activeChordIds.includes(+tile)}
                  setWidth={(width: number) => {
                    const wc = JSON.parse(JSON.stringify(widthChanges))

                    const prevTile = tiles[index - 1]
                    const nextTile = tiles[index + 1]

                    const prevDelta = wc[prevTile]
                    const nextDelta = wc[nextTile]

                    const prevWidth = widths[prevTile as number] * pixelsPerBeat * scale
                    const nextWidth = widths[nextTile as number] * pixelsPerBeat * scale

                    const currentTileBarsWidth = pxToBarsWidth(width)
                    const prevTileBarsWidth = pxToBarsWidth(prevWidth + prevDelta)
                    const nextTileBarsWidth = pxToBarsWidth(nextWidth + nextDelta)

                    const minBarsWidth = 1 / 16

                    const newWidts = {
                      ...widths,
                      [tile]: Math.max(currentTileBarsWidth, minBarsWidth),
                      ...(prevDelta ? { [prevTile]: Math.max(prevTileBarsWidth, minBarsWidth) } : {}),
                      ...(nextDelta ? { [nextTile]: Math.max(nextTileBarsWidth, minBarsWidth) } : {}),
                    }

                    setWidths(newWidts)
                    setWidthChanges({})
                    playerConfigSetter.handleTimelineChange({
                      tiles: tiles as number[],
                      widths: newWidts,
                    })
                  }}
                  getWidth={(t?: number) => widths[(t || tile) as number] * pixelsPerBeat * scale}
                  getWidthOfSelectedZone={() =>
                    playerConfig.activeChordIds.reduce((res, val) => (res += widths[val as number]), 0) *
                    pixelsPerBeat *
                    scale
                  }
                  onDoubleClick={onDoubleClickTile}
                  onEdit={onEditTile}
                  skeletonMode={skeletonMode.some((id) => id === tile)}
                  draggingChordId={draggingChordId}
                  held={held}
                  onAddHook={onAddTile}
                  getSnappPosition={(delta: number | null, resizeSide: 'left' | 'right' | null) => {
                    if (!delta) return 0

                    const prevTilesLength = tiles.slice(0, index).reduce((acc: number, t) => {
                      const tileWidth = widths[t as number] * pixelsPerBeat * scale
                      return acc + tileWidth
                    }, 0)

                    const isLeft = resizeSide === 'left'
                    const invertion = isLeft ? -1 : 1
                    const tileLength = isLeft ? 0 : widths[tile as number] * pixelsPerBeat * scale
                    const division = 1 / increment
                    const resizePositionInPx = prevTilesLength + tileLength + delta * invertion
                    const distanceFromDivision = pxToBarsWidth(resizePositionInPx) % division

                    const shouldSnapp = Math.min(distanceFromDivision, division - distanceFromDivision) / division < 0.2
                    const snappPositionInPx = shouldSnapp
                      ? Math.max(Math.round(pxToBarsWidth(resizePositionInPx) / division) * division, division) *
                        pixelsPerBeat *
                        scale
                      : resizePositionInPx

                    return Math.round(delta + (snappPositionInPx - resizePositionInPx) * invertion)
                  }}
                  resizingChordId={resizingChordId}
                  setResizingChordId={setResizingChordId}
                />
              ))}
              {onAddTile && (
                <TimelineAdder
                  componentRef={addButtonRef}
                  getWidth={() => pixelsPerBeat * scale}
                  onAddHook={async (e) => {
                    await onAddTile(e)

                    await trackMixpanelEvent_addChordPlusClick()
                  }}
                  active={activeTile === ADDING_TILE_ID && !playerConfig.activeChordIds.length}
                  hoverChords={hoverChords}
                  setHoverChords={setHoverChords}
                  generateMode={tiles.length === 0}
                  isChords
                />
              )}
              <DragOverlay>
                <div className={styles.row}>
                  {held.map((h) => (
                    <TimelineTile
                      key={h}
                      id={h}
                      setExtending={setExtending}
                      index={tiles.indexOf(h)}
                      active={activeTile == (h as number) || playerConfig.activeChordIds.includes(+h)}
                      className={tileClassName(h as number)}
                      skeletonMode={skeletonMode.some((id) => id === h)}
                      moving
                      setWidth={() => {}}
                      widthChanges={{}}
                      setWidthChanges={() => {}}
                      getWidth={() => widths[h as number] * pixelsPerBeat * scale}
                      draggingChordId={draggingChordId}
                    />
                  ))}
                </div>
              </DragOverlay>
            </SortableContext>
          </div>

          {playerConfig.currentPart.melodyShown && (
            <div
              className={classNames(styles.chrome, styles.melody)}
              data-track={true}
              style={{ width: chromeWidth }}
              data-unselect-chord={true}
              onClick={playerConfigSetter.handleUnselectChord}
            >
              {isMobile && <GenerateTrackButton type='MELODY' />}

              {melodyEmpty ? (
                <TimelineAdder
                  componentRef={addButtonRef}
                  getWidth={() => pixelsPerBeat * scale}
                  onAddHook={async () => {
                    playerConfigSetter.setMelodyPattern()
                    playerConfigSetter.setView({ melodyEditorOpen: true, pianoOpen: !!isMobile })

                    await trackMixpanelEvent_addMelodyPlusClick()
                  }}
                  hoverChords={hoverChords}
                  setHoverChords={setHoverChords}
                />
              ) : (
                <TimelineDrum
                  getWidth={() => pixelsPerBeat * scale * Math.max(1, melodyWidth || barsSum - 1 || 4)}
                  minWidth={pixelsPerBeat * scale}
                  formatWidth={pxToBarsWidth}
                  getSnappPosition={getSnapPosition}
                  type={'MELODY'}
                />
              )}
            </div>
          )}

          {playerConfig.currentPart.drumsShown && (
            <div
              className={classNames(styles.chrome, styles.drums)}
              data-track={true}
              style={{ width: chromeWidth }}
              data-unselect-chord={true}
              onClick={playerConfigSetter.handleUnselectChord}
            >
              {isMobile && <GenerateTrackButton type='DRUMS' />}

              {drumsEmpty ? (
                <TimelineAdder
                  componentRef={addButtonRef}
                  getWidth={() => pixelsPerBeat * scale}
                  onAddHook={async () => {
                    playerConfigSetter.setPattern()
                    playerConfigSetter.setView({ drumsEditorOpen: true, drumsOpen: true })

                    await trackMixpanelEvent_addDrumsPlusClick()
                  }}
                  hoverChords={hoverChords}
                  setHoverChords={setHoverChords}
                />
              ) : (
                <TimelineDrum
                  getWidth={() => pixelsPerBeat * scale * Math.max(1, drumsWidth || barsSum - 1 || 4)}
                  minWidth={pixelsPerBeat * scale}
                  formatWidth={pxToBarsWidth}
                  getSnappPosition={getSnapPosition}
                />
              )}
            </div>
          )}

          {renderPlayhead()}
        </DndContext>
      </div>
      {containerRef.current && !isMobile && (
        <TimelineScrollbar
          scale={scale}
          setScroll={(newScroll) => handleUpdateScaleAndScroll({ newScroll })}
          containerRef={containerRef}
          timelineRef={timelineRef}
          scroll={scroll}
          scaling={!!scalingConfig?.scale}
        />
      )}
    </div>
  )

  function scaleCutoff(proposedScale: number) {
    if (proposedScale > maxScale) return maxScale
    if (proposedScale < minScale) return minScale

    return proposedScale
  }
}

export default Timeline
