import classNames from 'classnames'
import React, { FC, useEffect, useMemo, useState } from 'react'

import Arrow from '../../../../assets/icons/arrow.svg'
import ZoomIn from '../../../../assets/icons/zoom-in.svg'
import ZoomOut from '../../../../assets/icons/zoom-out.svg'
import animateProperty from '../../../../hooks/animateProperty'
import { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import { useEditMenu } from '../../hooks/useEditMenu'
import { usePlayerConfigState } from '../../hooks/usePlayerConfigState'
import styles from './TimelineControls.module.scss'

interface Props {
  containerRef: React.RefObject<HTMLDivElement>
  scroll: number
  scale: number
  setScroll: (a: number) => void
  onZoomIn: () => void
  onZoomOut: () => void
  isMinScale: boolean
  isMaxScale: boolean
}

const TimelineControls: FC<Props> = ({
  setScroll,
  scale,
  scroll,
  containerRef,
  onZoomIn,
  onZoomOut,
  isMinScale,
  isMaxScale,
}) => {
  const { isMobile } = useSizes()
  const { playerConfig, playerConfigSetter } = usePlayerConfigState()
  const { addTileId, setAddTileId } = useEditMenu()

  const [lastChordPos, setLastChordPos] = useState(0)
  const tracksLastEl = useMemo(() => {
    const tracks = Array.from(document.querySelectorAll('[data-track="true"]')) as HTMLDivElement[]
    const [chordsTrack, drumsTrack] = tracks
    const drumsTrackLastTile = drumsTrack?.children[drumsTrack.children.length - 1] as HTMLDivElement
    const chordsTrackLastTile = chordsTrack?.children[chordsTrack.children.length - 1] as HTMLDivElement
    return { drumsTrackLastTile, chordsTrackLastTile }
  }, [])

  const handleChangeScroll = (newValue: number, oldValue: number) => {
    if (isMobile) {
      setScroll(newValue)
      return
    }
    animateProperty(oldValue, newValue, 250, setScroll)
  }

  useEffect(() => {
    if (containerRef.current) {
      const getScroll = () => {
        setLastChordPos(tracksLastEl.chordsTrackLastTile?.offsetLeft + tracksLastEl.chordsTrackLastTile?.clientWidth)
      }
      getScroll()
      document.addEventListener('resize', getScroll)
      return () => {
        document?.removeEventListener('resize', getScroll)
      }
    }
  }, [containerRef.current, playerConfig.prog, scale])

  useEffect(() => {
    if (!addTileId) return

    handleScrollToLastTile()
    playerConfigSetter.setAddChordMode(null)

    setTimeout(() => {
      setAddTileId(0)
    }, 500)
  }, [addTileId])

  const handleMoveBack = () => {
    const visibleAreaWidth = containerRef.current!.getBoundingClientRect().width
    handleChangeScroll(scroll - visibleAreaWidth, scroll)
  }

  const handleMoveForward = () => {
    const { chordsTrackLastTile, drumsTrackLastTile } = tracksLastEl
    const chordsLength = chordsTrackLastTile.offsetLeft + chordsTrackLastTile.clientWidth
    const drumsLength = drumsTrackLastTile.offsetLeft + drumsTrackLastTile.clientWidth
    const visibleAreaWidth = containerRef.current!.getBoundingClientRect().width

    let newScroll = chordsLength - visibleAreaWidth

    // scroll to drums if they are longer
    if (newScroll === scroll && drumsLength > chordsLength) {
      newScroll = drumsLength - visibleAreaWidth
    }

    // limit scroll to show next visible part only
    newScroll = Math.min(newScroll - scroll, visibleAreaWidth) + scroll

    if (newScroll === scroll) {
      newScroll = containerRef.current!.scrollWidth - visibleAreaWidth
    }
    handleChangeScroll(newScroll, scroll)
  }

  const handleScrollToLastTile = () => {
    const { chordsTrackLastTile } = tracksLastEl
    const chordsLength =
      chordsTrackLastTile.offsetLeft + chordsTrackLastTile.clientWidth + chordsTrackLastTile.clientWidth
    const visibleAreaWidth = containerRef.current!.getBoundingClientRect().width
    const newScroll = chordsLength - visibleAreaWidth
    if (scroll > newScroll) {
      return false
    }
    handleChangeScroll(newScroll, scroll)
    return true
  }

  const isMoveBackAvailable = scroll > 0
  const isMoveForwardAvailable =
    lastChordPos > scroll + containerRef.current!.getBoundingClientRect().width ||
    tracksLastEl.drumsTrackLastTile?.clientWidth > scroll + containerRef.current!.getBoundingClientRect().width

  if (isMobile) {
    return null
  }

  return (
    <div className={styles.timelineControls} data-disable-ids-click-outside-hook={[clickOutsideHookIds.footer]}>
      <div
        className={classNames(styles.button, styles.left, { [styles.active]: isMoveBackAvailable })}
        onClick={handleMoveBack}
      >
        <Arrow />
      </div>
      <div className={styles.divider} />
      <div
        className={classNames(styles.button, styles.right, { [styles.active]: isMoveForwardAvailable })}
        onClick={handleMoveForward}
      >
        <Arrow />
      </div>
      <div className={styles.divider} />
      <div
        className={classNames(styles.button, { [styles.active]: !isMaxScale && !playerConfig.currentPart.draft })}
        onClick={onZoomIn}
      >
        <ZoomIn />
      </div>
      <div className={styles.divider} />
      <div
        className={classNames(styles.button, { [styles.active]: !isMinScale && !playerConfig.currentPart.draft })}
        onClick={onZoomOut}
      >
        <ZoomOut />
      </div>
    </div>
  )
}

export default TimelineControls
