import classNames from 'classnames'
import React, { useEffect, useRef, useState } from 'react'

import Arrow from '../../../../assets/icons/arrow.svg'
import { useInternationalization } from '../../../../context/InternationalizationContext'
import useClickOutsideAlerter, { clickOutsideHookIds } from '../../../../hooks/clickOutsideAlerterHook'
import useSizes from '../../../../hooks/useSizes'
import Slider from '../../../common/Slider'
import styles from './BpmSelect.module.scss'
import SelectSetting from './SelectSetting'

type Props = {
  bpm: number
  setBpm: (bpm: number) => void
  mode?: 'default' | 'lite'
  className?: string
  removeMobileChanges?: boolean
}

const BpmSelect: React.FC<Props> = ({ bpm, setBpm, mode = 'default', className, removeMobileChanges }) => {
  const { isMobile: isMobileSize } = useSizes()
  const { getText } = useInternationalization()

  const ref = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)

  const [menuOpen, setMenuOpen] = useState(false)
  const [tempBpm, setTempBpm] = useState(bpm.toString())
  const [menuXPosition, setMenuXPosition] = useState<number | null>(null)

  const isMobile = isMobileSize && !removeMobileChanges
  const STRINGS = getText('playControls')

  useEffect(() => {
    setTempBpm(bpm.toString())
  }, [bpm])
  useEffect(() => {
    if (menuOpen) {
      inputRef.current?.focus()
    } else {
      inputRef.current?.blur()
    }
  }, [menuOpen])
  useEffect(() => {
    if (menuXPosition === null && ref.current) {
      setMenuXPosition(ref.current.getBoundingClientRect().x)
    }
  }, [menuOpen, ref, ref.current])
  useClickOutsideAlerter(ref, () => setMenuOpen(false), clickOutsideHookIds.bpmSelect)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      setMenuOpen(false)
    }
  }

  const renderMenu = () => (
    <div
      className={styles.menu}
      ref={ref}
      style={
        isMobileSize && menuXPosition && menuXPosition < 24
          ? { transform: `translateX(calc(-50% + ${24 - menuXPosition}px))` }
          : {}
      }
    >
      {isMobile ? (
        <span>
          {STRINGS.bpm}: <span>{bpm}</span>
        </span>
      ) : (
        <span>{STRINGS.bpmTempo}</span>
      )}
      <Slider
        className={styles.slider}
        size='small'
        onChange={(val) => {
          setBpm(+val)
        }}
        min={60}
        max={240}
        step={1}
        initialValue={bpm}
      />
    </div>
  )
  if (isMobile) {
    return renderMenu()
  }
  return (
    <div
      className={classNames(
        styles.bpmSelect,
        styles[mode],
        { [styles.removeMobileChanges]: removeMobileChanges },
        className,
      )}
      onKeyDown={(e) => handleKeyDown(e)}
    >
      <SelectSetting
        nameProps={{
          id: clickOutsideHookIds.bpmSelect,
        }}
        value={
          <div className={styles.inputCon}>
            <input
              ref={inputRef}
              className={styles.input}
              type='number'
              value={tempBpm}
              onChange={(e) => setTempBpm(e.target.value?.slice(0, 3) || '')}
              onBlur={() => {
                const finalValue = Math.max(60, Math.min(240, Math.round(Number(tempBpm))))
                setBpm(finalValue)
                setTempBpm(finalValue.toString())
              }}
              aria-label='Bpm'
            />
            {mode === 'lite' && <Arrow />}
          </div>
        }
        name={STRINGS.bpm}
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
        className={styles.trigger}
      />
      {menuOpen && renderMenu()}
    </div>
  )
}

export default BpmSelect
